const PrivacyPolicy = () => {
  return (
    <>
      <div className="h-[10%] w-full"></div>
      <div className="grid grid-cols-7  min-h-[50%] gap-12 px-32 bg-[#F8FAFC] py-6">
        <div className="col-start-1 col-end-12">
          <div className="text-3xl font-extrabold mb-3 dark:text-white">
            Privacy Policy
          </div>
          <div
            data-id="82be426"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <p>
                At connectnext.co, we are committed to maintaining the privacy
                and security of your personal information. This Privacy Policy
                outlines how we collect, use, disclose, and safeguard your
                information when you use our services or interact with us. By
                accessing our website, using our services, or providing us with
                your personal information, you consent to the practices
                described in this policy.
              </p>
            </div>
          </div>
          <div
            data-id="bf40fbb"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>Use of Personal Information:&nbsp;</strong>
              </div>
              <ul>
                <li>
                  We use your information for various purposes, including but
                  not limited to:&nbsp;
                </li>
                <li>
                  Providing Services: Jobseeker searching the jobs and employer
                  can post his job on this portal.&nbsp;
                </li>
                <li>
                  Communication: Responding to inquiries, sending newsletters,
                  and sharing relevant information.&nbsp;
                </li>
                <li>
                  Research and Analysis: Improving our services, conducting
                  surveys, and analyzing user behavior.&nbsp;
                </li>
                <li>
                  Legal and Safety: Complying with legal obligations, protecting
                  our rights, and ensuring security.
                </li>
              </ul>
            </div>
          </div>
          <div
            data-id="9acf089"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>Privacy of Email Lists:&nbsp;</strong>
              </div>
              <ul>
                <li>
                  Email addresses collected through connectnext.co website or
                  campaigns are added to its email database. These addresses are
                  not sold, rented, or shared with third parties.
                </li>
              </ul>
            </div>
          </div>
          <div
            data-id="18a102b"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>
                  Disclosure of Your Information: We may share your information
                  with:&nbsp;
                </strong>
                <strong>&nbsp;</strong>
              </div>
              <ul>
                <li>
                  Partners: Collaborators working on joint initiatives and
                  programs.&nbsp;
                </li>
                <li>
                  Legal Requirements: When required by law, court order, or
                  governmental authority.&nbsp;
                </li>
              </ul>
            </div>
          </div>
          <div
            data-id="46abe22"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>Cookie Policy:</strong>
              </div>
              <ul>
                <li>
                  Partners: Collaborators working on joint initiatives and
                  programs.&nbsp;
                </li>
                <li>
                  Legal Requirements: When required by law, court order, or
                  governmental authority.&nbsp;
                </li>
              </ul>
            </div>
          </div>
          <div
            data-id="287108f"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>Payment Gateway:&nbsp;</strong>
              </div>
              <ul>
                <li>
                  connectnext.co uses secure technology for payments, including
                  SSL connections for donor security.&nbsp;
                </li>
                <li>
                  Robust security measures protect payment information,
                  including encryption and advanced firewalls
                </li>
              </ul>
            </div>
          </div>
          <div
            data-id="723849d"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>
                  External Web Services: ( Might include in future )
                </strong>
                <br />
              </div>
              <ul>
                <li>
                  connectnext.co uses secure technology for payments, including
                  SSL connections for donor security.&nbsp;
                </li>
                <li>
                  Robust security measures protect payment information,
                  including encryption and advanced firewall
                </li>
              </ul>
            </div>
          </div>
          <div
            data-id="c9b5bf8"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>Changes to Privacy Policy:</strong>
              </div>
              <ul>
                <li>
                  connectnext.co may update its privacy policy to align with
                  technology and trends, notifying users of changes.&nbsp;
                </li>
                <li>
                  For questions about changes or the privacy policy, users can
                  contact connectnext.co at: contact@connectnext.co
                </li>
              </ul>
            </div>
          </div>
          <div
            data-id="20ee807"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          ></div>
          <div
            data-id="17238d0"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>Terms and Conditions:&nbsp;</strong>
              </div>
              <ul>
                <li>
                  Using the connectnext.co site signifies acceptance of the
                  following terms:&nbsp;
                </li>
                <li>
                  Use for lawful purposes, without infringing rights of
                  others.&nbsp;
                </li>
                <li>No liability for damages arising from site use.&nbsp;</li>
                <li>
                  No warranty of uninterrupted, error-free site function.&nbsp;
                </li>
                <li>Copyright restrictions apply to site content.&nbsp;</li>
                <li>
                  External site links are not endorsed or controlled.&nbsp;
                </li>
              </ul>
            </div>
          </div>
          <div
            data-id="fd16f12"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>Governing Law:</strong>
              </div>
              <p>
                By using our services, you acknowledge that you have read and
                understood this Privacy Policy and consent to the collection,
                use, and disclosure of your information as described herein.
                <br />
                <br />
                These terms and conditions are governed by the laws of India
              </p>
            </div>
          </div>
          <div
            data-id="b5eade6"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>Contact Information:</strong>
              </div>
              <p>
                connectnext.co Address:
                <br />
                <strong>LB Nagar</strong>
                2nd Floor, CNR Complex, Tirumala Colony,<br />
                Near Chintalkunta Checkpost,<br />
                L B Nagar,<br />
                Hyderabad,<br/>
                Telangana-500074
              </p>
            </div>
          </div>
          <div
            data-id="57a4247"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div>
              <div className="mt-4">
                <strong>Registered Address:&nbsp;</strong>
              </div>
              <p>
                Website URL : connectnext.co
                <br />
                Contact Email : contact@connectnext.co
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
