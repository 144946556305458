import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { MdOutlineAddReaction } from "react-icons/md";
import { getRecruiterAction, getSearchAppearance } from "../../utils/utils";

const ProfilePerformance = () => {
  const [searchAppearance, setSearchAppearance] = useState({ count: 0 });
  const [recruiterAction, setRecruiterAction] = useState({ count: 0 });
  const userId = Cookies.get("userId");

  useEffect(() => {
    (async () => {
      const searchApp = await getSearchAppearance(Number(userId));
      if (Object?.keys(searchApp)?.length) {
        setSearchAppearance({ count: searchApp.count });
      }

      const recruiterAct = await getRecruiterAction(Number(userId));
      if (Object?.keys(recruiterAct)?.length) {
        setRecruiterAction({ count: recruiterAct.count });
      }
    })();
  }, [userId]);
  return (
    <>
      <div className="w-full h-auto flex-col justify-start items-start gap-10 inline-flex">
        <div className="self-stretch justify-start items-center gap-20 inline-flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex">
            <div className="self-stretch  tracking-wide font-bold">
              <h1 className="text-xl 2xl:text-2xl">Actions on the Profile</h1>
            </div>
          </div>
        </div>
        <div className="self-stretch h-20 flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-5 inline-flex">
            <div className="grow shrink basis-0 p-5 bg-white rounded-xl shadow-sm justify-between items-center flex">
              <div className="justify-center items-center gap-2 flex">
                <div className="relative items-center">
                  <CiSearch />
                </div>
                <div className="text-base 2xl:text-lg font-semibold">
                  Search Appearances
                </div>
              </div>
              <div className="w-12 px-3 py-2 bg-slate-50 rounded-[40px] justify-center items-center flex">
                <div className="text-black text-xs font-normal leading-[14.40px] tracking-tight">
                  {searchAppearance?.count}
                </div>
              </div>
            </div>
            <div className="grow shrink basis-0 p-5 bg-white rounded-xl shadow-sm justify-between items-center flex">
              <div className="justify-center items-center gap-2 flex">
                <div className="relative">
                  <MdOutlineAddReaction />
                </div>
                <div className="text-base 2xl:text-lg font-semibold">
                  Recruiter Actions
                </div>
              </div>
              <div className="w-[39px] px-3 py-2 bg-slate-50 rounded-[40px] justify-center items-center flex">
                <div className="text-black text-xs font-normal leading-[14.40px] tracking-tight">
                  {recruiterAction?.count}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-10" />
    </>
  );
};

export default ProfilePerformance;
