import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import ReactPaginateItems from '../../../commonComponents/ReactPaginate';
import NoRecords from '../../../commonComponents/NoRecords';
import { BsThreeDotsVertical } from "react-icons/bs";
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import DeleteFolder from './DeleteFolder';
import RenameFolderName from './RenameFolderName';
import { AiOutlineDelete } from "react-icons/ai";
import { deleteMultipleFolders } from '../../../../store/reducers/recruiter/manageFolder/deleteMultipleFolders';
import { useAppDispatch } from '../../../..';
import { useNavigate } from 'react-router-dom';
import ShareFolder from './ShareFolder';
import ShareMultipleFolder from './ShareMultipleFolder';
import CopyFolder from './CopyFolder';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const ManageFoldersTable = ({ categories, setCategories }: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(Object.keys(categories)[0]);
    const [isOpen, setIsOpen] = useState(false);
    const [isSortAsc, setIsSortAsc] = useState(true)
    // paginate
    const [itemOffset, setItemOffset] = useState(0);
    // const itemsPerPage: number = 10;
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const endOffset = itemOffset + itemsPerPage;

    const handleTabChange = (index: number) => {
        const newCategory = Object.keys(categories)[index];
        setSelectedCategory(newCategory);
        setItemOffset(0); // Reset the item offset
    };

    const handleSelectAll = () => {
        setCategories((preValue: any) => {
            return {
                ...preValue,
                My_folder: categories?.My_folder?.map((item: any) => ({ ...item, isChecked: !item.isChecked })),
            }
        });
    };

    const handleSingleSelect = (id: number) => {
        setCategories((preValue: any) => {
            return {
                ...preValue,
                My_folder: categories?.My_folder?.map((item: any) =>
                    item?.id === id ? { ...item, isChecked: !item.isChecked } : item
                )
            }
        });
    }

    const handleMultidelete = () => {
        let folderIds = categories?.My_folder?.filter((item: any) => item.isChecked).map((item: any) => item.id);
        dispatch(deleteMultipleFolders({ folderIds: folderIds }));
    }

    const handleOpenCandidates = (post: any) => {
        if (post?.jobSeekers?.length) {
            navigate('/cvZone/manageFolders/Candidates', { state: post });
        }

    }

    const handleSort = () => {
        setIsSortAsc(!isSortAsc); // Toggle sorting order
        const sortedCategories = { ...categories };
        Object.keys(sortedCategories).forEach((category) => {
            sortedCategories[category] = [...sortedCategories[category]].sort((a: any, b: any) => {
                return isSortAsc
                    ? new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
                    : new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
            });
        });
        setCategories(sortedCategories);
    };

    const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setItemsPerPage(Number(e.target.value));
        setItemOffset(0); // Reset the item offset
    }

    return (
        <>
            <div className="bg-[#FFF] rounded-xl px-7 flex justify-between items-center h-12">
                <div className="text-[#475569] flex justify-start items-center">
                    <input type="checkbox" onChange={handleSelectAll} />
                    <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Select all</label>
                </div>
                <div className="flex justify-end items-center text-[#312E81]">
                    <span className="text-[#818CF8] text-base font-medium mr-3">Sort by:</span>
                    <div className="flex justify-center items-center mr-3 cursor-pointer" onClick={handleSort}>
                        <ChevronUpIcon className={`h-5 w-5 text-gray-600 ${isSortAsc ? 'rotate-180' : ''} transform mr-1`} />
                        <span>Created date</span>
                    </div>
                    <div className="flex justify-center items-center mr-3">
                        <span>Show</span>
                        <select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            className="ml-2 border border-gray-300 rounded p-1"
                        >
                            {[20, 40, 60, 80, 100].map(size => (
                                <option key={size} value={size}>{size}</option>
                            ))}
                        </select>
                    </div>
                    <span>{itemsPerPage}</span>
                    <button type="button" onClick={handleMultidelete} className="ml-3 flex justify-center items-center"><AiOutlineDelete /><span className="ml-1">Delete</span></button>
                    <ShareMultipleFolder categories={categories} />
                </div>
            </div>
            <div>
                <Tab.Group onChange={handleTabChange}>
                    <Tab.List className="flex">
                        {Object.keys(categories).map(category => {
                            return <Tab key={category} className={({
                                selected
                            }) => classNames('w-full outline-none leading-10 font-bold border-b', selected ? 'border-[#4F46E5] text-[#4F46E5]' : 'border-[#E0E7FF] text-[#64748B]')}>
                                {category === "My_folder" && `My folder(${categories?.My_folder && categories?.My_folder?.length})`}
                                {category === "Folder_shared_with_me" && `Folder shared with me(${categories?.Folder_shared_with_me && categories?.Folder_shared_with_me?.length})`}
                                {category === "Contacted_candidate" && `Contacted candidate(${categories?.Contacted_candidate && categories?.Contacted_candidate?.length})`}
                            </Tab>;
                        })}
                    </Tab.List>
                    <Tab.Panels className="w-full">
                        {Object.values(categories).map((posts: any, idx: number) => {
                            const currentItems = posts?.slice(itemOffset, endOffset);
                            return <Tab.Panel key={idx}>
                                {currentItems !== undefined && currentItems?.length !== 0 ? <>
                                    <div className="bg-white overflow-hidden w-full">
                                        <table className="w-full table-auto">
                                            <thead>
                                                <tr>
                                                    <th className="py-3 px-6 text-left"></th>
                                                    <th className="py-3 px-6 text-left">Folder Name</th>
                                                    <th className="py-3 px-6 text-left">Total Candidates</th>
                                                    {selectedCategory === "Contacted_candidate" ? <>
                                                        <th className="py-3 px-6 text-left">Response</th>
                                                        <th className="py-3 px-6 text-left">Last Sent</th>
                                                    </> : <th className="py-3 px-6">Actions</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems?.length !== 0 ? currentItems?.map((post: any, index: number) => {
                                                    return <>
                                                        <tr className={currentItems?.length - 1 !== index ? "border-b-2 border-[#F1F5F9]" : ""}>
                                                            <td className="py-3 px-6 text-left">
                                                                <input type="checkbox" checked={post?.isChecked} onChange={() => handleSingleSelect(post?.id)} />
                                                            </td>
                                                            <td className="py-3 px-6 text-left cursor-pointer" onClick={() => handleOpenCandidates(post)}>
                                                                {post?.name}
                                                            </td>
                                                            <td className="py-3 px-6 text-left cursor-pointer" onClick={() => handleOpenCandidates(post)}>
                                                                {post?.myCandidateCount && post?.myCandidateCount}{post?.sharedCandidateCount && post?.sharedCandidateCount}{post?.candidatesContactedCount && post?.candidatesContactedCount} Candidates
                                                            </td>
                                                            {selectedCategory === "Folder_shared_with_me" && <>
                                                                <td className="group relative flex justify-center items-center py-3 px-6">
                                                                    <button className="cursor-pointer group-hover:hidden">
                                                                        <BsThreeDotsVertical />
                                                                    </button>
                                                                    <div className="hidden group-hover:flex group-hover:justify-center group-hover:items-center">
                                                                        <CopyFolder post={post} />
                                                                    </div>
                                                                </td>
                                                            </>}
                                                            {selectedCategory === "My_folder" && <>
                                                                <td className="group relative flex justify-center items-center py-3 px-6">
                                                                    <button className="cursor-pointer group-hover:hidden">
                                                                        <BsThreeDotsVertical />
                                                                    </button>
                                                                    <div className="hidden group-hover:flex group-hover:justify-center group-hover:items-center space-x-2">
                                                                        <RenameFolderName post={post} />
                                                                        <DeleteFolder post={post} />
                                                                        <ShareFolder post={post} />
                                                                    </div>
                                                                </td>
                                                            </>}
                                                            {selectedCategory === "Contacted_candidate" && <>
                                                                <td className="py-3 px-6 text-left">
                                                                    {post?.response ? post?.response : "No response"}
                                                                </td>
                                                                <td className="py-3 px-6 text-left">
                                                                    {post?.lastSent && post?.lastSent}
                                                                </td>
                                                            </>}
                                                        </tr>
                                                    </>;
                                                }) : <tr>
                                                    <td colSpan={5} className="py-3 px-6">
                                                        <h1 className="text-center">No record</h1>
                                                    </td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ReactPaginateItems itemsPerPage={itemsPerPage} items={posts} itemOffset={itemOffset} setItemOffset={setItemOffset} />
                                </> : <NoRecords />}
                            </Tab.Panel>;
                        })}
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </>
    )
}

export default ManageFoldersTable;