import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState: any = {
    loading: false,
    error: false,
    success: false,
    createFolder: {},
    errorMessage: undefined,
}

export const createFolder = createAsyncThunk(
    "createFolder", async (data: any) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PATH}/folder/createFolder`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                }
            );
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
        } catch (error) {
            throw error;
        }
    });

const createFolderSlice = createSlice({
    name: 'createFolder',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(createFolder.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(createFolder.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.createFolder = action.payload;
        });
        builder.addCase(createFolder.rejected, (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = true;
            //   state.errorMessage = action.error.message;
        });
    },
    reducers: {
        clearCreateFolderSlice: (state) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            return state;
        },
    }
});

export default createFolderSlice.reducer;
export const { clearCreateFolderSlice } = createFolderSlice.actions;