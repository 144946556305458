import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../..';
import ApplicantCard from './ApplicantCard';
import { useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import ReactPaginateItems from '../../../commonComponents/ReactPaginate';
import NoRecords from '../../../commonComponents/NoRecords';
import ApplicantDetailCard from './ApplicantDetailCard';
import ApplicantLeftCard from './ApplicantLeftCard';
import moment from 'moment';
import {
  getFilterApplicant,
  clearGetFilterJobsSlice,
} from '../../../../store/reducers/applicant/GetFilterApplicant';
import { changeApplicantStatus, clearChangeApplicantStatusSlice } from '../../../../store/reducers/applicant/applicantStatus';
import { toast } from 'react-toastify';
import Toaster from '../../../commonComponents/Toaster';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const RecruiterInbox = () => {

  const dispatch = useAppDispatch();
  const { success, allApplicant } = useAppSelector((state) => state.getFilterApplicant);
  const { success: applicantStatusSuccess } = useAppSelector((state) => state.changeApplicantStatus);
  const { jobId } = useParams();
  const [jobTitle, setJobTitle] = useState<any>({ jobTitle: "", postedDate: "", applicantCount: "" });
  const [expand, setExpand] = useState<any>([]);
  const [itemOffset, setItemOffset] = useState(0);

  let [categories, setCategories] = useState({
    UnReviewed: [],
    ShortListed: [],
    Rejected: [],
  });

  // using for pagination
  const itemsPerPage: number = 10;
  const endOffset = itemOffset + itemsPerPage;

  useEffect(() => {
    dispatch(getFilterApplicant({ jobId }));
  }, [dispatch]);

  const updateCategory = (categoryName: any, newData: any) => {
    return setCategories(prevCategories => ({
      ...prevCategories,
      
      [categoryName]: [newData],
    }));
  };

  useEffect(() => {
      setCategories({
        UnReviewed: [],
        ShortListed: [],
        Rejected: [],
      });
      if (jobTitle?.jobTitle === "") {
        setJobTitle({ ...jobTitle, jobTitle: allApplicant?.filter((jobItem: any) => jobItem?.jobs?.id === Number(jobId))[0]?.jobs?.title, postedDate: moment(allApplicant?.filter((jobItem: any) => jobItem?.jobs?.id === Number(jobId))[0]?.jobs?.createdAt).fromNow() });
      }
      setJobTitle({ ...jobTitle, applicantCount: allApplicant?.length });
      allApplicant.map((item: any) => {
        item?.jobSeekerProfile?.applicantId?.map((applicantId: any) => {
          if (Number(applicantId?.JobId?.id) === Number(jobId)) {
            if (applicantId?.applicantStatus === 'ShortListed') {
              updateCategory('ShortListed', item);
            } else if (applicantId?.applicantStatus === 'Reject') {
              updateCategory('Rejected', item);
            } else if (applicantId?.applicantStatus === 'UnReviewed') {
              updateCategory('UnReviewed', item);
            }
          }
        });
      });
      dispatch(clearGetFilterJobsSlice());
    if (applicantStatusSuccess) {
      dispatch(clearChangeApplicantStatusSlice());
      toast.success("Applicant status changed successfully !!");
    }
  }, [applicantStatusSuccess, success]);

  const shortListReject = (status: string, userId: number) => {
    dispatch(changeApplicantStatus({
      applicantStatus: status,
      applicantStatusJobSeeker: Number(userId),
      applicantId: Number(userId),
      JobId: Number(jobId)
    }));
  }
  return (
    <>
      <div className="h-[10%] w-full"></div>
      <div className="grid grid-cols-12 gap-10 px-32 py-5 bg-[#F8FAFC]">
        <div className="col-start-1 col-end-4">
          <ApplicantLeftCard jobId={jobId} />
        </div>
        <div className="col-start-4 col-end-13">
          <div className="w-full h-auto justify-start items-center gap-5 inline-flex">
            <div className="w-10 h-10 bg-indigo-50 rounded-[60px] justify-center items-center flex">
              <img
                className="w-14 rounded-lg"
                src={allApplicant[0]?.jobs?.company?.companyImage ? `${process.env.REACT_APP_COMPANY_PICTURE_FILE_LOCATION}/${allApplicant[0]?.jobs?.company?.companyImage}`.replace(/"/g, '') as any : `${process.env.REACT_APP_IMAGE_BASE_URL}companyLogoDefault.svg`}
                alt="no img"
              />
            </div>
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
              <div className="justify-start items-center gap-1 inline-flex">
                <div className="text-black text-2xl font-bold leading-[28.80px] tracking-tight">{jobTitle?.jobTitle}</div>
              </div>
              <div className="self-stretch justify-start items-start gap-2 inline-flex">
                <div className="border-b border-slate-600 justify-start items-center gap-2.5 flex">
                  <div className="text-slate-600 text-sm font-medium leading-[16.80px] tracking-tight">Job Description</div>
                </div>
                <div className="w-[17px] self-stretch origin-top-left rotate-90"></div>
                <div className="text-slate-500 text-sm font-normal leading-[16.80px] tracking-tight">Posted {jobTitle?.postedDate}</div>
                <div className="w-[17px] self-stretch origin-top-left rotate-90 "></div>
                <div className="text-slate-500 text-sm font-normal leading-[16.80px] tracking-tight">{jobTitle?.applicantCount} Applicants</div>
              </div>
            </div>
          </div>

          <Tab.Group>
            <Tab.List className="flex">
              {Object.keys(categories).map((category) => (
                <Tab
                  key={category}
                  className={({ selected }) =>
                    classNames('w-full outline-none leading-10 font-bold border-b', selected ? 'border-[#4F46E5] text-[#4F46E5]' : 'border-[#E0E7FF] text-[#64748B]')
                  }
                >
                  {category}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="w-full">
              {Object.values(categories).map((posts, idx) => {
                const currentItems = posts?.slice(itemOffset, endOffset);
                return (
                  <Tab.Panel key={idx}>
                    {currentItems !== undefined && currentItems?.length !== 0 ?
                      <>
                        {posts.map((data, index: number) =>
                          <div className="flex-col justify-start items-center gap-7 flex" key={index}>
                            {!expand.includes(Number(index) + 1) &&
                              <ApplicantCard
                                data={data}
                                index={index + 1}
                                setExpand={setExpand}
                                expand={expand}
                                shortListReject={shortListReject}
                              />}

                            {expand.includes(Number(index) + 1) &&
                              <ApplicantDetailCard
                                data={data}
                                index={index + 1}
                                setExpand={setExpand}
                                expand={expand}
                                shortListReject={shortListReject}
                              />}
                          </div>
                        )}
                        <ReactPaginateItems itemsPerPage={itemsPerPage} items={posts} itemOffset={itemOffset} setItemOffset={setItemOffset} />
                      </>
                      : <NoRecords />
                    }
                  </Tab.Panel>
                )
              })}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      <Toaster />
    </>
  )
}

export default RecruiterInbox;