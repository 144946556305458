import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState:any = {
    jobList: [],
    loading: false,
    success: false,
    error: false,
    errorMessage:"",
}

export const getRecommendedJobs = createAsyncThunk(
    "getRecommendedJobs", async (data: any) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PATH}/notification/getRecommendedJobs`, { params: data });
            if (response.status === 200 ) {
                return response.data.data;
            }
        } catch (error) {
            throw error;
        }
    });

const getRecommendedJobsSlice = createSlice({
    name: "getRecommendedJobs",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getRecommendedJobs.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(getRecommendedJobs.fulfilled, (state, action: PayloadAction) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.jobList = action.payload;
        });
        builder.addCase(getRecommendedJobs.rejected, (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = true;
            state.errorMessage = action.error.message;
        });
    },
    reducers: {
        clearGetRecommendedJobsSlice: (state) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            return state;
        },
    }
});
export default getRecommendedJobsSlice.reducer;
export const { clearGetRecommendedJobsSlice } = getRecommendedJobsSlice.actions;