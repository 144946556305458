import { useEffect } from 'react';
import { getWorkModeList } from '../../utils/utils';
import { useAppSelector, useAppDispatch } from '../../../';
import { setWorkMode, setWorkModeIds, setCheckItems } from '../../../store/reducers/jobs/GetFilterJobs';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

export const WorkModeBasedFilter = ({ handleWorkModeCheckbox }: any) => {

    const dispatch = useAppDispatch();
    const { workMode } = useAppSelector((state) => state.getFilterJobs);

    useEffect(() => {
        (async () => {
            const workModeList = await getWorkModeList();
            if (Object.keys(workModeList)?.length) {
                dispatch(setWorkMode(workModeList));
            }
        })();
    }, []);

    return (
        <div className="w-full">
            <Disclosure>
                {({
                    open
                }) => <>
                        <Disclosure.Button className="flex w-full justify-between items-center">
                            <div className="flex items-center">
                                <svg
                                    className="h-5 w-5 mr-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                >
                                    <g clip-path="url(#clip0_5286_16649)">
                                        <path d="M15.8583 2.96667C14.4667 1.80833 12.7417 1.05 10.8333 0.875V2.55C12.275 2.70833 13.5917 3.28333 14.675 4.15L15.8583 2.96667ZM9.16667 2.55V0.875C7.25833 1.04167 5.53333 1.80833 4.14167 2.96667L5.325 4.15C6.42729 3.2621 7.75998 2.70705 9.16667 2.55ZM4.15 5.325L2.96667 4.14167C1.80833 5.53333 1.05 7.25833 0.875 9.16667H2.55C2.70833 7.725 3.28333 6.40833 4.15 5.325ZM17.45 9.16667H19.125C18.95 7.25833 18.1917 5.53333 17.0333 4.14167L15.85 5.325C16.7379 6.42729 17.293 7.75999 17.45 9.16667ZM5.83333 10L8.7 11.3L10 14.1667L11.3 11.3L14.1667 10L11.3 8.7L10 5.83333L8.7 8.7L5.83333 10Z" fill="#475569" />
                                        <path d="M10 17.5C8.76937 17.4998 7.55785 17.1953 6.47326 16.6138C5.38867 16.0322 4.46464 15.1916 3.78337 14.1667H5.83337V12.5H0.833374V17.5H2.50004V15.25C4.15837 17.6167 6.89171 19.1667 10 19.1667C14.0584 19.1667 17.5 16.525 18.7 12.8667L17.0667 12.4917C16.0417 15.4 13.2667 17.5 10 17.5Z" fill="#475569" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5286_16649">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>


                                </svg>
                                <label className="text-[#475569] font-semibold">Work Mode</label>
                            </div>
                            <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-5">
                            {workMode?.map((item: any, index: number) => <div className={workMode.length - 1 !== index ? "text-[#475569] flex justify-start items-center mb-2" : "text-[#475569] flex justify-start items-center"}>
                                <input type="checkbox" defaultChecked={false} checked={item?.isChecked !== undefined && item?.isChecked} onChange={() => handleWorkModeCheckbox(item)} />
                                <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">{item?.title}</label>
                            </div>)}
                        </Disclosure.Panel>
                    </>}
            </Disclosure>
        </div>
    )
};

const FiltersWorkMode = () => {
    const dispatch = useAppDispatch();
    const { checkItems } = useAppSelector((state) => state.getFilterJobs);

    const handleWorkModeCheckbox = (data: any) => {
        dispatch(setCheckItems({
            workMode: checkItems?.workMode?.map((item: any) =>
                item?.id === data?.id ? { ...item, isChecked: !item.isChecked } : item
            )
        }));
        if (data?.isChecked === undefined || data?.isChecked === false) {
            dispatch(setWorkModeIds(data?.id));
        } else {
            dispatch(setWorkModeIds({ filter: data?.id }));
        }
    };

    return (
        <div className="w-full h-full px-5">
            <h1 className="font-semibold leading-none mt-5 mb-2 text-lg">Work Mode</h1>
            <div className="flex flex-wrap items-center w-full">
                {checkItems?.workMode?.map((item: any, index: number) =>
                    <div className="text-[#475569] flex justify-start items-center mt-2 text-sm w-1/2" key={index}>
                        <input type="checkbox" defaultChecked={false} checked={item?.isChecked} onChange={() => handleWorkModeCheckbox(item)} />
                        <label className="ml-2 overflow-hidden inline-block whitespace-nowrap text-ellipsis">{item?.title}</label>
                    </div>
                )}
            </div>
        </div>
    )
};

export default FiltersWorkMode;