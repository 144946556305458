import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getKeySkillsList, getSalaryRangeList, getTotalYearsExpList } from "../../../utils/utils";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { useAppDispatch } from "../../../..";
import { searchResume } from "../../../../store/reducers/recruiter/searchResume";

const customStylesForSingle = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '8px',
    padding: '3px 0',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '5px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '5px',
  }),
};

interface SearchResumeInputs {
  keywords: string[] | undefined;
  minExperience: | any | undefined;
  maxExperience: any;
  minSalary: any;
  maxSalary: any;
}

const SearchResumeSchema = yup.object().shape({
  keywords: yup
    .array()
    .min(1, "Pick at least one keySkills")
    .max(10, "Pick at most ten keySkills")
    .optional(),
  minExperience: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }).optional(),
  maxExperience: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }).optional(),
  minSalary: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }).optional(),
  maxSalary: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }).optional(),
});

const SearchResumeResultLeftPanel = ({ initialData }: any) => {
  const dispatch = useAppDispatch();
  const [keySkills, setKeySkills] = useState<any>([]);
  const [totalYearsExp, setTotalYearsExp] = useState<any>([]);
  const [salaryRange, setSalaryRange] = useState<any>([]);
  const [filterCount, setFilterCount] = useState<number>(0);
  const {
    setValue,
    handleSubmit,
    getValues,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<SearchResumeInputs>({
    resolver: yupResolver(SearchResumeSchema),
  });

  useEffect(() => {
    initialData?.keywords && setValue("keywords", initialData?.keywords);
    Object.keys(initialData?.minExperience).length !== 0 && setValue("minExperience", initialData?.minExperience);
    Object.keys(initialData?.maxExperience).length !== 0 && setValue("maxExperience", initialData?.maxExperience);
    Object.keys(initialData?.minSalary).length !== 0 && setValue("minSalary", initialData?.minSalary);
    Object.keys(initialData?.maxSalary).length !== 0 && setValue("maxSalary", initialData?.maxSalary);
  }, [initialData]);

  useEffect(() => {
    (async () => {
      const keySkillsList = await getKeySkillsList();
      if (Object.keys(keySkillsList)?.length) {
        setKeySkills(keySkillsList as any);
      }
      const totalYearsExpList = await getTotalYearsExpList();
      if (Object.keys(totalYearsExpList)?.length) {
        setTotalYearsExp(totalYearsExpList as any);
      }
      const salaryRangeList = await getSalaryRangeList();
      if (Object.keys(salaryRangeList)?.length) {
        setSalaryRange(salaryRangeList as any);
      }
    })();
  }, []);

  useEffect(() => {
    let count = 0;
    const keywords = getValues().keywords;
    if (Array.isArray(keywords)) {
      count += keywords.length;
    }
    if (getValues().minExperience) {
      count = count + 1;
    }
    if (getValues().maxExperience) {
      count = count + 1;
    }
    if (getValues().minSalary) {
      count = count + 1;
    }
    if (getValues().maxSalary) {
      count = count + 1;
    }
    setFilterCount(count);
  }, [getValues()]);

  const onSubmit = (data: any) => {
    dispatch(searchResume({
      keywords: data?.keywords?.map((item: any) => item?.label),
      minExperience: data?.minExperience?.value,
      maxExperience: data?.maxExperience?.value,
      currentLocation: initialData?.currentLocation?.map((item: any) => item?.label),
      minSalary: data?.minSalary?.value,
      maxSalary: data?.maxSalary?.value,
      preferredLocation: initialData?.preferredLocation?.map((item: any) => item?.label),
      industry: initialData?.industry?.map((item: any) => item?.label),
      department: initialData?.department?.label,
      roleCategory: initialData?.roleCategory?.label,
      designation: initialData?.designation?.map((item: any) => item?.label),
      empType: initialData?.empType?.map((item: any) => item?.title),
      shiftType: initialData?.shiftType?.map((item: any) => item?.title),
      noticePeriod: initialData?.noticePeriod?.label,
      qualification: initialData?.qualification?.map((item: any) => item?.title),
      gender: initialData?.gender?.map((item: any) => item?.title),
      maritalStatus: initialData?.maritalStatus?.map((item: any) => item?.title),
      candidateMinAge: initialData?.noticePeriod?.value,
      candidateMaxAge: initialData?.noticePeriod?.value,
      isMobileNumberVerified: initialData?.isMobileNumberVerified,
      isEmailVerified: initialData?.isEmailVerified,
      verifyBGV: initialData?.verifyBGV,
      resumeAttach: initialData?.resumeAttach,
      activeIn: initialData?.activeIn,
    }));
  };

  return (
    <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full h-auto pr-5 border-r border-indigo-100 grid grid-cols-1 gap-5">
        <div className="grid grid-cols-1 gap-5">
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-center">
              <div className="text-[#475569] font-bold text-base">Filters</div>
              <div className="text-black text-xs font-normal ml-3">{filterCount}</div>
            </div>
            <div className="text-right text-slate-600 text-sm font-medium cursor-pointer border-b border-slate-600 " onClick={()=>reset()}>
              Clear all
            </div>
          </div>
          <div className="self-stretch justify-start items-center flex flex-wrap gap-2">
            {watch("keywords") && watch("keywords")?.map((item: any, ind: number) => (
              <button className="bg-[#F1F5F9] px-2 py-1 text-xs rounded-sm" key={ind}>{item?.label}</button>
            ))}
            {watch("minExperience") && <button className="bg-[#F1F5F9] px-2 py-1 text-xs rounded-sm">{watch("minExperience").label}</button>}
            {watch("maxExperience") && <button className="bg-[#F1F5F9] px-2 py-1 text-xs rounded-sm">{watch("maxExperience").label}</button>}
            {watch("minSalary") && <button className="bg-[#F1F5F9] px-2 py-1 text-xs rounded-sm"> {watch("minSalary").label} Lakhs</button>}
            {watch("maxSalary") && <button className="bg-[#F1F5F9] px-2 py-1 text-xs rounded-sm">{watch("maxSalary").label} Lakhs</button>}
          </div>
        </div>
        <div className="self-stretch h-[0px] border border-indigo-100"></div>
        <div>
          <Disclosure>
            {({
              open
            }) => <>
                <Disclosure.Button className="flex w-full justify-between items-center">
                  <label className="text-[#475569] font-bold text-base inline-block">Keywords</label>
                  <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                </Disclosure.Button>
                <Disclosure.Panel className="mt-5">
                  <Controller
                    name="keywords"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isClearable
                        isSearchable={true}
                        isMulti
                        className='text-sm w-full'
                        styles={customStylesForSingle}
                        options={keySkills?.map(
                          ({ id, title }: any) =>
                            ({ value: id, label: title } as any)
                        )}
                      />
                    )}
                  />
                </Disclosure.Panel>
              </>}
          </Disclosure>
        </div>
        <div className="self-stretch h-[0px] border border-indigo-100"></div>
        <div>
          <Disclosure>
            {({
              open
            }) => <>
                <Disclosure.Button className="flex w-full justify-between items-center">
                  <label className="text-[#475569] font-bold text-base inline-block">Experience</label>
                  <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                </Disclosure.Button>
                <Disclosure.Panel className="mt-5">
                  <div className="flex justify-between items-center gap-2">
                    <div className="w-full">
                      <label className="text-sm">Min experience</label>
                      <Controller
                        name="minExperience"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            isSearchable={true}
                            className='text-sm w-full'
                            styles={customStylesForSingle}
                            options={totalYearsExp?.map(({ id, title }: any) => ({ value: id, label: title }))}
                          />
                        )}
                      />
                    </div>
                    <div className="w-full">
                      <label className="text-sm">Max experience</label>
                      <Controller
                        name="maxExperience"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            isSearchable={true}
                            className='text-sm w-full'
                            styles={customStylesForSingle}
                            options={totalYearsExp?.map(({ id, title }: any) => ({ value: id, label: title }))}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Disclosure.Panel>
              </>}
          </Disclosure>
        </div>
        <div className="self-stretch h-[0px] border border-indigo-100"></div>
        <div>
          <Disclosure>
            {({
              open
            }) => <>
                <Disclosure.Button className="flex w-full justify-between items-center">
                  <label className="text-[#475569] font-bold text-base inline-block">Annual Salary</label>
                  <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                </Disclosure.Button>
                <Disclosure.Panel className="mt-5">
                  <div className="flex justify-between items-center gap-2">
                    <div className="w-full">
                      <label className="text-sm">Min salary (Lacs)</label>
                      <Controller
                        name="minSalary"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            isSearchable={true}
                            className='text-sm w-full'
                            styles={customStylesForSingle}
                            options={salaryRange?.map(({ id, title }: any) => ({ value: id, label: title }))}
                          />
                        )}
                      />
                    </div>
                    <div className="w-full">
                      <label className="text-sm">Max salary (Lacs)</label>
                      <Controller
                        name="maxSalary"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            isSearchable={true}
                            className='text-sm w-full'
                            styles={customStylesForSingle}
                            options={salaryRange?.map(({ id, title }: any) => ({ value: id, label: title }))}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Disclosure.Panel>
              </>}
          </Disclosure>
        </div>
        <div>
          <button className="bg-[#EEF2FF] w-full text-[#312E81] font-semibold text-lg rounded-lg py-3">Save search</button>
        </div>
      </div>
    </form>
  );
};

export default SearchResumeResultLeftPanel;
