import React, { useEffect, useState } from 'react'
import Modal from '../../../commonComponents/Modal'
import { useAppDispatch, useAppSelector } from '../../../..';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { createFolder } from '../../../../store/reducers/recruiter/manageFolder/createFolder';

interface IFormInputs {
    folderName: string
}

const SignUpSchema = yup
    .object({
        folderName: yup.string().label("Folder Name").required()
    })
    .required();

const CreateFolder = ({isOpen, setIsOpen}:any) => {
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<IFormInputs>({
        resolver: yupResolver(SignUpSchema)
    });

    const openModal = () => {
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const onSubmit = (data: IFormInputs) => {
        dispatch(createFolder(data));
        reset();
    }

    return (
        <>
            <Modal
                title={"Create Folder"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                modalBody={
                    <div>
                        <hr className="my-4" />
                        <div className="flex flex-col gap-4">
                            <span className="text-sm text-gray-500 mb-3">
                                It will help you emphasize the aspects of your skills that are most relevant and enhances your reach to employers
                            </span>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <input
                                        className="w-1/2 border border-[#E0E7FF] outline-none rounded px-3 py-3"
                                        placeholder="Please enter folder name"
                                        {...register("folderName")}
                                    />
                                    {
                                        errors.folderName
                                        &&
                                        <p className="font-normal text-xs text-red-500 absolute">
                                            {errors.folderName.message}
                                        </p>
                                    }
                                </div>
                                <hr className="my-5" />
                                <div className="flex justify-start items-center">
                                    <div>
                                        <button
                                            type="button"
                                            className="mr-3 bg-[#EEF2FF] px-12 py-3 rounded-lg text-[#312E81]"
                                            onClick={closeDialog}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-[#4F46E5] px-12 py-3 rounded-lg text-[#FFF]"
                                        >
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default CreateFolder;