import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState: any = {
    loading: false,
    error: false,
    success: false,
    jobSeekersList: {},
    errorMessage: undefined,
}

export const getSelctedJobSeekersList = createAsyncThunk(
    "getSelctedJobSeekersList", async (data: any) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PATH}/folder/getSelctedJobSeekersList`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                }
            );
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
        } catch (error) {
            throw error;
        }
    });

const getSelctedJobSeekersListSlice = createSlice({
    name: 'createFolder',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getSelctedJobSeekersList.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(getSelctedJobSeekersList.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.jobSeekersList = action.payload;
        });
        builder.addCase(getSelctedJobSeekersList.rejected, (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = true;
            //   state.errorMessage = action.error.message;
        });
    },
    reducers: {
        clearGetSelctedJobSeekersListSlice: (state) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            return state;
        },
    }
});

export default getSelctedJobSeekersListSlice.reducer;
export const { clearGetSelctedJobSeekersListSlice } = getSelctedJobSeekersListSlice.actions;