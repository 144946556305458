import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState: any = {
  loading: false,
  error: false,
  success: false,
  allApplicant: [],
}

export const getFilterApplicant = createAsyncThunk(
  "applicantList", async (data: any) => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/applyJob/applicantList`, { params: data });
      if (response.status >= 200 && response.status < 300) {
        return response.data.data;
      }
    } catch (error) {
      throw error;
    }
  });

const getFilterApplicantSlice = createSlice({
  name: "applicantList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFilterApplicant.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(getFilterApplicant.fulfilled, (state, action: PayloadAction<Array<any>>) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.allApplicant = action.payload;
    });
    builder.addCase(getFilterApplicant.rejected, (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = true;
      state.errorMessage = action.error.message;
    });
  },
  reducers: {
    clearGetFilterJobsSlice: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      return state;
    },
  }
});

export default getFilterApplicantSlice.reducer;
export const { clearGetFilterJobsSlice } = getFilterApplicantSlice.actions;