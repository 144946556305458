import React, { useEffect, useState } from 'react'
import Modal from '../../../commonComponents/Modal'
import { useAppDispatch, useAppSelector } from '../../../..';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { clearGetMyFoldersSlice, getMyFolders } from '../../../../store/reducers/recruiter/manageFolder/getMyFolders';
import { BiSearch } from 'react-icons/bi';
import { MdOutlineAdd } from "react-icons/md";
import CreateFolder from '../ManageFolders/CreateFolder';
import { clearCreateFolderSlice } from '../../../../store/reducers/recruiter/manageFolder/createFolder';
import { clearSaveJobSeekerToFolderSlice, saveJobSeekerToFolder } from '../../../../store/reducers/recruiter/manageFolder/saveJobSeekerToFolder';


const customStylesForSingle = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '8px',
        padding: '3px 0 3px 20px',
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '5px',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '5px',
    }),
};

interface IFormInputs {
    folder: any
}

const SignUpSchema = yup
    .object({
        folder: yup.object().shape({
            value: yup.number().optional(),
            label: yup.string().optional(),
        }),
    }).required();

const AddCandidates = ({ isOpen, setIsOpen, jobSeekerId }: any) => {
    const dispatch = useAppDispatch();
    const { myFolders, success, error, loading } = useAppSelector((state) => state.getMyFolders);
    const { success: createFolderSuccess, error: createFolderError, loading: createFolderLoading } = useAppSelector((state) => state.createFolder);
    const { success: saveJobSeekerToFolderSuccess, error: saveJobSeekerToFolderError, loading: saveJobSeekerToFolderLoading } = useAppSelector((state) => state.saveJobSeekerToFolder);
    const [isOpenCreateFolder, setIsOpenCreateFolder] = useState(false);
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm<IFormInputs>({
        resolver: yupResolver(SignUpSchema)
    });

    useEffect(() => {
        dispatch(getMyFolders());
    }, []);

    useEffect(() => {
        if (success) {
            dispatch(clearGetMyFoldersSlice());
        }
        if (createFolderSuccess) {
            dispatch(getMyFolders());
            setIsOpenCreateFolder(false);
            setIsOpen(true);
            dispatch(clearCreateFolderSlice());
        }
        if (saveJobSeekerToFolderSuccess) {
            setIsOpen(false);
            dispatch(clearSaveJobSeekerToFolderSlice());
            reset();
        }
    }, [success, createFolderSuccess, saveJobSeekerToFolderSuccess]);

    const closeDialog = () => {
        setIsOpen(false);
        reset();
    };

    const onSubmit = (data: IFormInputs) => {
        dispatch(saveJobSeekerToFolder({ folderId: data?.folder?.value, jobSeekerId: jobSeekerId }));
    }

    return (
        <>
            <Modal
                title={"Add Candidates"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                modalBody={
                    <div>
                        <hr className="my-4" />
                        <div className="flex flex-col gap-4">
                            <span className="text-sm text-gray-500 mb-3">
                                It will help you emphasize the aspects of your skills that are most relevant and enhances your reach to employers
                            </span>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="relative flex justify-start items-center">
                                    <BiSearch className="h-5 w-5 absolute z-10 top-1/2 left-2 -translate-y-1/2" />
                                    <Controller
                                        name="folder"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isClearable
                                                isSearchable={true}
                                                className='text-sm w-1/2'
                                                styles={customStylesForSingle}
                                                placeholder="Search location"
                                                options={myFolders?.map(({ id, name }: any) => ({ value: id, label: name }))}
                                            />
                                        )}
                                    />
                                    <div className="ml-2 flex justify-center items-center">
                                        <span className="cursor-pointer bg-[#EEF2FF]" onClick={() => setIsOpenCreateFolder(true)}><MdOutlineAdd size={30} /></span>
                                    </div>
                                </div>
                                <hr className="mb-5 mt-10" />
                                <div className="flex justify-start items-center">
                                    <div>
                                        <button
                                            type="button"
                                            className="mr-3 bg-[#EEF2FF] px-12 py-3 rounded-lg text-[#312E81]"
                                            onClick={closeDialog}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-[#4F46E5] px-12 py-3 rounded-lg text-[#FFF]"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            />
            <CreateFolder isOpen={isOpenCreateFolder} setIsOpen={setIsOpenCreateFolder} />
        </>
    )
}

export default AddCandidates;