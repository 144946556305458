import { useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { getRoleCategoryList } from '../../utils/utils';
import { useAppSelector, useAppDispatch } from '../../../';
import { setRoleCategory, setNavigateFilterOption, setCheckItems, setRoleCategoryIds } from '../../../store/reducers/jobs/GetFilterJobs';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { BiSearch } from 'react-icons/bi';

export const RoleCategoryBasedFilter = ({ handleRoleCategoryCheckbox, setIsOpen }: any) => {

    const dispatch = useAppDispatch();
    const { roleCategory } = useAppSelector((state) => state.getFilterJobs);

    useEffect(() => {
        (async () => {
            const roleCategoryList = await getRoleCategoryList();
            if (Object.keys(roleCategoryList)?.length) {
                dispatch(setRoleCategory(roleCategoryList));
            }
        })();
    }, []);

    const handleViewAll = () => {
        setIsOpen(true)
        dispatch(setNavigateFilterOption("Role Category"));
    }

    return (
        <div className="w-full">
            <Disclosure>
                {({
                    open
                }) => <>
                        <Disclosure.Button className="flex w-full justify-between items-center">
                            <div className="flex items-center">
                                <svg
                                    className="h-6 w-6 mr-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path d="M21.0525 15.75L16.5 11.25L21.0525 6.75L22.125 7.8075L18.645 11.25L22.125 14.6925L21.0525 15.75ZM16.5 22.5H15V18.75C15 17.7554 14.6049 16.8016 13.9017 16.0983C13.1984 15.3951 12.2446 15 11.25 15H6.75C5.75544 15 4.80161 15.3951 4.09835 16.0983C3.39509 16.8016 3 17.7554 3 18.75V22.5H1.5V18.75C1.5 17.3576 2.05312 16.0223 3.03769 15.0377C4.02226 14.0531 5.35761 13.5 6.75 13.5H11.25C12.6424 13.5 13.9777 14.0531 14.9623 15.0377C15.9469 16.0223 16.5 17.3576 16.5 18.75V22.5ZM9 3C9.74168 3 10.4667 3.21993 11.0834 3.63199C11.7001 4.04404 12.1807 4.62971 12.4645 5.31494C12.7484 6.00016 12.8226 6.75416 12.6779 7.48159C12.5333 8.20902 12.1761 8.8772 11.6517 9.40165C11.1272 9.9261 10.459 10.2833 9.73159 10.4279C9.00416 10.5726 8.25016 10.4984 7.56494 10.2145C6.87971 9.93072 6.29404 9.45007 5.88199 8.83339C5.46993 8.2167 5.25 7.49168 5.25 6.75C5.25 5.75544 5.64509 4.80161 6.34835 4.09835C7.05161 3.39509 8.00544 3 9 3ZM9 1.5C7.96165 1.5 6.94661 1.80791 6.08326 2.38478C5.2199 2.96166 4.54699 3.7816 4.14963 4.74091C3.75227 5.70022 3.6483 6.75582 3.85088 7.77422C4.05345 8.79262 4.55346 9.72808 5.28769 10.4623C6.02191 11.1965 6.95738 11.6966 7.97578 11.8991C8.99418 12.1017 10.0498 11.9977 11.0091 11.6004C11.9684 11.203 12.7883 10.5301 13.3652 9.66674C13.9421 8.80339 14.25 7.78835 14.25 6.75C14.25 5.35761 13.6969 4.02226 12.7123 3.03769C11.7277 2.05312 10.3924 1.5 9 1.5Z" fill="#475569" />

                                </svg>
                                <label className="text-[#475569] font-semibold">Role Category</label>
                            </div>
                            <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-5">
                            {roleCategory?.slice(0, 5)?.map((item: any) => <div className="text-[#475569] mb-2 flex justify-start items-center">
                                <input type="checkbox" defaultChecked={false} checked={item?.isChecked !== undefined && item?.isChecked} onChange={() => handleRoleCategoryCheckbox(item)} />
                                <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">{item?.title}</label>
                            </div>)}
                            <button className="text-[#4F46E5] text-sm" onClick={handleViewAll}>
                                View all...
                            </button>
                        </Disclosure.Panel>
                    </>}
            </Disclosure>
        </div>
    );
}

const FiltersRoleCategory = () => {
    const dispatch = useAppDispatch();
    const { checkItems } = useAppSelector((state) => state.getFilterJobs);
    const [searchQuery, setSearchQuery] = useState("");

    const handleDepartmentCheckbox = (data: any) => {
        dispatch(setCheckItems({
            roleCategory: checkItems?.roleCategory?.map((item: any) =>
                item?.id === data?.id ? { ...item, isChecked: !item.isChecked } : item
            )
        }));
        if (data?.isChecked === undefined || data?.isChecked === false) {
            dispatch(setRoleCategoryIds(data?.id));
        } else {
            dispatch(setRoleCategoryIds({ filter: data?.id }));
        }
    };

    const handleSearch = (event: any) => {
        setSearchQuery(event.target.value);
    };

    const filteredItems = checkItems?.roleCategory?.filter((item: any) =>
        item?.title?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="w-full h-full">
            <div className="flex flex-col justify-between h-full px-5">
                <h1 className="font-semibold leading-none my-5 text-lg">Select Role</h1>
                <div className="relative flex items-center w-full py-2 mb-3 border border-[#E0E7FF] rounded-lg overflow-hidden">
                    <div className="grid place-items-center h-full w-12 text-gray-300">
                        <BiSearch className="h-5 w-5" />
                    </div>
                    <input
                        className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                        type="text"
                        id="search"
                        onChange={handleSearch}
                        placeholder="Search role.." />
                </div>
                <div className="h-96 overflow-x-auto overflow-y-hidden flex flex-col flex-wrap">
                    {filteredItems?.map((item: any) =>
                        <div className="text-[#475569] flex justify-start items-center mt-1 text-sm w-1/2">
                            <input type="checkbox" defaultChecked={false} checked={item?.isChecked !== undefined && item?.isChecked} onChange={() => handleDepartmentCheckbox(item)} />
                            <label className="ml-2 overflow-hidden inline-block whitespace-nowrap text-ellipsis">{item?.title}</label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FiltersRoleCategory;