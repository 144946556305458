import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState: any = {
    loading: false,
    error: false,
    success: false,
    deleteFolder: {},
    errorMessage: undefined,
}

export const deleteFolder = createAsyncThunk(
    "deleteFolder", async (data: number) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_PATH}/folder/deleteFolder/${data}`,
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                }
            );
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
        } catch (error) {
            throw error;
        }
    });

const deleteFolderSlice = createSlice({
    name: 'deleteFolder',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(deleteFolder.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(deleteFolder.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.deleteFolder = action.payload;
        });
        builder.addCase(deleteFolder.rejected, (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = true;
            //   state.errorMessage = action.error.message;
        });
    },
    reducers: {
        clearDeleteFolderSlice: (state) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            return state;
        },
    }
});

export default deleteFolderSlice.reducer;
export const { clearDeleteFolderSlice } = deleteFolderSlice.actions;