import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState: any = {
    loading: false,
    error: false,
    success: false,
    saveJobSeekerToFolder: {},
    errorMessage: undefined,
}

export const saveJobSeekerToFolder = createAsyncThunk(
    "saveJobSeekerToFolder", async (data: any) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PATH}/folder/saveJobSeekerToFolder`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                }
            );
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
        } catch (error) {
            throw error;
        }
    });

const saveJobSeekerToFolderSlice = createSlice({
    name: 'saveJobSeekerToFolder',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(saveJobSeekerToFolder.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(saveJobSeekerToFolder.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.saveJobSeekerToFolder = action.payload;
        });
        builder.addCase(saveJobSeekerToFolder.rejected, (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = true;
            //   state.errorMessage = action.error.message;
        });
    },
    reducers: {
        clearSaveJobSeekerToFolderSlice: (state) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            return state;
        },
    }
});

export default saveJobSeekerToFolderSlice.reducer;
export const { clearSaveJobSeekerToFolderSlice } = saveJobSeekerToFolderSlice.actions;