import { useEffect, useState } from 'react';
import { getLocationList } from '../../utils/utils';
import { useAppSelector, useAppDispatch } from '../../..';
import { setLocation, setNavigateFilterOption, setLocationIds, setCheckItems } from '../../../store/reducers/companies/getAllCompanies';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { BiSearch } from 'react-icons/bi';

export const CompanyLocationFilter = ({ handleLocationCheckbox, setIsOpen }: any) => {
    const dispatch = useAppDispatch();
    const { location } = useAppSelector((state) => state.getAllCompanies);

    useEffect(() => {
        (async () => {
            const locationList = await getLocationList();
            if (Object.keys(locationList)?.length) {
                dispatch(setLocation(locationList))
            }
        })();
    }, []);

    const handleViewAll = () => {
        setIsOpen(true)
        dispatch(setNavigateFilterOption("Location"));
    }

    return (
        <div className="w-full">
            <Disclosure>
                {({
                    open
                }) => <>
                        <Disclosure.Button className="flex w-full justify-between items-center">
                            <div className="flex items-center">
                                <svg
                                    className="h-6 w-6 mr-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path d="M13.75 13.5C12.8105 13.5 11.8922 13.2801 11.111 12.868C10.3299 12.456 9.72109 11.8703 9.36157 11.1851C9.00206 10.4998 8.90799 9.74584 9.09127 9.01841C9.27455 8.29098 9.72695 7.6228 10.3912 7.09835C11.0555 6.5739 11.9019 6.21675 12.8233 6.07206C13.7447 5.92736 14.6998 6.00162 15.5677 6.28545C16.4357 6.56928 17.1775 7.04993 17.6995 7.66661C18.2214 8.2833 18.5 9.00832 18.5 9.75C18.4985 10.7442 17.9976 11.6973 17.1071 12.4003C16.2166 13.1033 15.0093 13.4988 13.75 13.5ZM13.75 7.5C13.1863 7.5 12.6353 7.63196 12.1666 7.87919C11.6979 8.12643 11.3327 8.47783 11.1169 8.88896C10.9012 9.3001 10.8448 9.7525 10.9548 10.189C11.0647 10.6254 11.3362 11.0263 11.7347 11.341C12.1333 11.6557 12.6411 11.87 13.194 11.9568C13.7468 12.0436 14.3199 11.999 14.8406 11.8287C15.3614 11.6584 15.8065 11.37 16.1197 11C16.4329 10.63 16.6 10.195 16.6 9.75C16.5992 9.15345 16.2987 8.5815 15.7644 8.15967C15.2301 7.73784 14.5056 7.5006 13.75 7.5Z" fill="#565151" />
                                    <path d="M13.75 21.5L6.08091 14.3936C5.97435 14.2869 5.86889 14.1795 5.76455 14.0714C4.45519 12.7153 3.74757 11.0594 3.75001 9.35714C3.75001 7.2733 4.80357 5.2748 6.67894 3.8013C8.5543 2.3278 11.0978 1.5 13.75 1.5C16.4022 1.5 18.9457 2.3278 20.8211 3.8013C22.6964 5.2748 23.75 7.2733 23.75 9.35714C23.7521 11.0585 23.0448 12.7136 21.7364 14.0693L21.7355 14.0714C21.7355 14.0714 21.4627 14.3529 21.4218 14.3907L13.75 21.5ZM7.21637 13.2107C7.21637 13.2107 7.42819 13.4307 7.47637 13.4779L13.75 19.2914L20.0318 13.47C20.0718 13.4307 20.2845 13.2093 20.2855 13.2086C21.3556 12.1008 21.934 10.7479 21.9318 9.35714C21.9318 7.65218 21.0698 6.01705 19.5354 4.81146C18.001 3.60587 15.92 2.92857 13.75 2.92857C11.58 2.92857 9.49897 3.60587 7.96458 4.81146C6.4302 6.01705 5.56819 7.65218 5.56819 9.35714C5.56594 10.7488 6.14496 12.1026 7.21637 13.2107Z" fill="#565151" />

                                </svg>
                                <label className="text-[#475569] font-semibold">Location</label>
                            </div>
                            <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-5">
                            {location?.slice(0, 5)?.map((item: any) => <div className="text-[#475569] mb-2 flex justify-start items-center">
                                <input type="checkbox" defaultChecked={false} checked={item?.isChecked} onChange={() => handleLocationCheckbox(item)} />
                                <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">{item?.title}</label>
                            </div>)}
                            <button className="text-[#4F46E5] text-sm" onClick={handleViewAll}>View all...</button>
                        </Disclosure.Panel>
                    </>}
            </Disclosure>
        </div>
    )
};

const FiltersLocation = () => {
    const dispatch = useAppDispatch();
    const { checkItems } = useAppSelector((state) => state.getAllCompanies);
    const [searchQuery, setSearchQuery] = useState("");

    const handleLocationCheckbox = (data: any) => {
        dispatch(setCheckItems({
            location: checkItems?.location?.map((item: any) =>
                item?.id === data?.id ? { ...item, isChecked: !item.isChecked } : item
            )
        }));
        if (data?.isChecked === undefined || data?.isChecked === false) {
            dispatch(setLocationIds(data?.id));
        } else {
            dispatch(setLocationIds({ filter: data?.id }));
        }
    };

    const handleSearch = (event: any) => {
        setSearchQuery(event.target.value);
    };

    const filteredItems = checkItems?.location?.filter((item: any) =>
        item?.title?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="w-full h-full">
            <div className="flex flex-col justify-between h-full px-5">
                <h1 className="font-semibold leading-none my-5 text-lg">Select location/ city</h1>
                <div className="relative flex items-center w-full py-2 mb-3 border border-[#E0E7FF] rounded-lg overflow-hidden">
                    <div className="grid place-items-center h-full w-12 text-gray-300">
                        <BiSearch className="h-5 w-5" />
                    </div>
                    <input
                        className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                        type="text"
                        id="search"
                        onChange={handleSearch}
                        placeholder="Search location.." />
                </div>
                <div className="h-96 overflow-x-auto overflow-y-hidden flex flex-col flex-wrap">
                    {filteredItems?.map((item: any) =>
                        <div className="text-[#475569] flex justify-start items-center mt-1 text-sm w-1/4">
                            <input type="checkbox" defaultChecked={false} checked={item?.isChecked} onChange={() => handleLocationCheckbox(item)} />
                            <label className="ml-2 overflow-hidden inline-block whitespace-nowrap text-ellipsis">{item?.title}</label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default FiltersLocation;