import React, { useEffect, useState } from 'react';
import CategoryItem from '../../commonComponents/CategoryItem';
import { getAllDemandingKeySkills } from '../../utils/utils';
import { Link } from 'react-router-dom';

const MostDemandingCategory = () => {
  const [keySkills, setKeySkills] = useState([]);
  useEffect(() => {
    (async () => {
      const allCityJobsCount = await getAllDemandingKeySkills();
      if (Object.keys(allCityJobsCount)?.length) {
        setKeySkills(allCityJobsCount);
      }
    })();
  }, []);
  return (
    <>
      <div className="mb-10">
        <div className="flex justify-between items-center mb-10 font-bold">
          <h1 className="text-xl 2xl:text-2xl">Most demanding categories</h1>
          <Link to="/allJobs" className="flex justify-center items-center text-[#312E81] text-base 2xl:text-lg"><span className="mr-2">All Categories</span><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}ArrowRight.svg`} alt="ArrowRight" /></Link>
        </div>
        <div className="grid grid-cols-3 gap-4 mb-4">
          {keySkills?.slice(0, 3)?.map(item => <CategoryItem item={item} />)}
        </div>
        <div className="grid grid-cols-4 gap-4 mb-4">
          {keySkills?.slice(3, 7)?.map(item => <CategoryItem item={item} />)}
        </div>
      </div>
    </>
  )
}

export default MostDemandingCategory