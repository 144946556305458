import SearchResumesForm from './SearchResumesForm';

const SearchResumes = () => {
  return (
    <>
      <div className="h-[10%] w-full"></div>
      <SearchResumesForm />
    </>
  )
}

export default SearchResumes;