import { useAppDispatch, useAppSelector } from '../../../..';
import { useEffect, useState } from 'react'
import { clearSearchResumeSlice, searchResume } from '../../../../store/reducers/recruiter/searchResume';
import SearchResumeResultLeftPanel from './SearchResumeResultLeftPanel'
import ResumeResultCard from './ResumeResultCard'
import ResumeResultCardDetail from './ResumeResultCardDetail'
import NoRecords from '../../../commonComponents/NoRecords'
import Modal from '../../../commonComponents/Modal';
import ComposeMail from '../MailCenter/ComposeMail';
import Toaster from '../../../commonComponents/Toaster';
import { toast } from 'react-toastify';
import DownloadResumeAsZip from '../../../commonComponents/DownloadResumeAsZip';
import { listBlockedApplicant } from '../../../utils/utils';
import { useLocation } from 'react-router-dom';
import { getEmployerCompanyList } from "../../../../store/reducers/companies/employerCompanyList";
import Cookies from 'js-cookie';
import { getSaveResume } from '../../../../store/reducers/recruiter/getSaveResume';

const SearchResumeResult = ({
  handleSingleSaveSearch }: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userId = Cookies.get("userId");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [composePreviewTitle, setComposePreviewTitle] = useState('Compose Mail');
  const [expand, setExpand] = useState<any>([]);
  const [applicantEmailId, setApplicantEmailId] = useState<any>([])
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any>([]);
  const [blockCompanyList, setBlockCompanyList] = useState<any>([]);
  const [jobCard, setJobCard] = useState<any>([]);
  const [companyId, setCompanyId] = useState<any>([]);
  const [viewAllSaveSearch, setViewAllSaveSearch] = useState(false);
  const { searchResumeResult, success, error } = useAppSelector((state) => state.searchResume);
  const { companyDetails } = useAppSelector((state) => state.getEmployerCompanyList);
  const { searchResumeResult: saveResume } = useAppSelector((state) => state.getSaveResume);

  useEffect(() => {
    dispatch(searchResume({
      keywords: location?.state?.keywords?.map((item: any) => item?.label),
      minExperience: location?.state?.minExperience?.value,
      maxExperience: location?.state?.maxExperience?.value,
      currentLocation: location?.state?.currentLocation?.map((item: any) => item?.label),
      minSalary: location?.state?.minSalary?.value,
      maxSalary: location?.state?.maxSalary?.value,
      preferredLocation: location?.state?.preferredLocation?.map((item: any) => item?.label),
      industry: location?.state?.industry?.map((item: any) => item?.label),
      department: location?.state?.department?.label,
      roleCategory: location?.state?.roleCategory?.label,
      designation: location?.state?.designation?.map((item: any) => item?.label),
      empType: location?.state?.empType?.map((item: any) => item?.title),
      shiftType: location?.state?.shiftType?.map((item: any) => item?.title),
      noticePeriod: location?.state?.noticePeriod?.label,
      qualification: location?.state?.qualification?.map((item: any) => item?.title),
      gender: location?.state?.gender?.map((item: any) => item?.title),
      maritalStatus: location?.state?.maritalStatus?.map((item: any) => item?.title),
      candidateMinAge: location?.state?.noticePeriod?.value,
      candidateMaxAge: location?.state?.noticePeriod?.value,
      isMobileNumberVerified: location?.state?.isMobileNumberVerified,
      isEmailVerified: location?.state?.isEmailVerified,
      verifyBGV: location?.state?.verifyBGV,
      resumeAttach: location?.state?.resumeAttach,
      activeIn: location?.state?.activeIn,
    }));
    dispatch(getSaveResume(userId));
    dispatch(getEmployerCompanyList({ data: { user: { id: userId } } }));
  }, [dispatch, location, userId]);

  useEffect(() => {
    if (success) {
      setJobCard(searchResumeResult);
      dispatch(clearSearchResumeSlice());
    } else if (error) {
      setJobCard([]);
      dispatch(clearSearchResumeSlice());
    }
  }, [success, error])

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(jobCard && jobCard?.map((li: any) => li.user.id));
    setApplicantEmailId(jobCard && jobCard?.map((li: any) => li.user.userProfile?.email))
    if (isCheckAll) {
      setIsCheck([]);
      setApplicantEmailId([]);
    }
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setApplicantEmailId([...applicantEmailId, jobCard?.filter((li: any) => li.user.id === Number(id))[0].user?.userProfile?.email])
    setIsCheck([...isCheck, Number(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== Number(id)));
      setApplicantEmailId(applicantEmailId.filter((item: any) => item !== jobCard?.filter((li: any) => li.user.id === Number(id))[0].user?.userProfile?.email))
    }
  };

  const closeDialog = () => {
    setIsOpen(false);
  }

  const openModal = () => {
    if (isCheck?.length > 0) {
      setIsOpen(true);
    } else {
      toast.success("Please check at least one ")
    }
  }

  useEffect(() => {
    if (companyId) {
      (async () => {
        const saveBlockList = await listBlockedApplicant(Number(companyId))
        if (Object?.keys(saveBlockList)?.length) {
          let jobSeekerId = [] as any
          saveBlockList.map((item: any) => { jobSeekerId?.push(item?.jobSeeker?.id) })
          setBlockCompanyList(jobSeekerId);
        }
      })();
    }
  }, [companyId]);

  useEffect(() => {
    if (blockCompanyList) {
      setJobCard(jobCard.filter((item: any) => !blockCompanyList.find((twoElement: any) => {
        return Number(item.id) === Number(twoElement)
      })))
    }
    if (companyDetails) {
      setCompanyId(companyDetails?.id);
    }
  }, [blockCompanyList, companyDetails]);

  return (
    <>
      <div className="h-[10%]  w-full"></div>
      <div className="grid grid-cols-12 gap-10 px-32 bg-[#F8FAFC] py-6">
        <div className="col-start-1 col-end-4">
          <SearchResumeResultLeftPanel initialData={location?.state} />
        </div>
        <div className="col-start-4 col-end-13">
          <form>
            <div className="w-full h-auto flex-col justify-start items-start gap-10 inline-flex">
              <div className="w-full justify-start items-center gap-5 inline-flex">
                <div className="w-10 h-10 bg-rose-50 rounded-[60px] shadow justify-center items-center flex">
                  <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}search-candidate-resume.svg`} alt='search-candidate-resume' />
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                  <div className="justify-start items-center gap-1 inline-flex">
                    <div className="text-black text-2xl font-bold leading-[28.80px] tracking-tight">Search Candidate Resumes</div>
                  </div>
                  <div className="self-stretch justify-start items-start gap-2 inline-flex">
                    <div className="text-slate-500 text-sm font-normal leading-[16.80px] tracking-tight">Use the filters to shortlist resumes</div>
                    <div className="text-slate-500 text-sm font-normal leading-[16.80px] tracking-tight">|</div>
                    <div className="text-slate-500 text-sm font-normal leading-[16.80px] tracking-tight">Showing {jobCard?.length} results</div>
                  </div>
                </div>
              </div>
              <div className="w-full flex-col justify-start items-center gap-7 flex">
                <div className="flex-col justify-start items-start gap-2 flex">
                  <div className="text-black text-base font-bold leading-snug tracking-tight">Saved Searches</div>
                  <div className="self-stretch justify-start items-center gap-5 grid grid-cols-3">
                    {!viewAllSaveSearch ?
                      saveResume?.slice(-2)?.reverse()?.map((item: any, index: any) =>
                        <div className="w-[285px] px-3 py-2 bg-indigo-50 rounded-[40px] justify-center items-center flex cursor-pointer" key={index} onClick={() => handleSingleSaveSearch(item.id)}>
                          <div className="text-black text-xs font-normal">{item?.keywords?.map((itemKeywords: any, index: any) => <span key={index}>{(index ? ', ' : '') + itemKeywords?.title}</span>)} | {item?.minExperience}-{item?.maxExperience} yrs exp | {item?.minSalary}-{item?.maxSalary}Lacs</div>
                        </div>)
                      :
                      saveResume?.toReversed()?.map((item: any, index: any) =>
                        <div className="w-[285px] px-3 py-2 bg-indigo-50 rounded-[40px] justify-center items-center flex cursor-pointer" key={index} onClick={() => handleSingleSaveSearch(item.id)}>
                          <div className="text-black text-xs font-normal">{item?.keywords?.map((itemKeywords: any, index: any) => <span key={index}>{(index ? ', ' : '') + itemKeywords?.title}</span>)} | {item?.minExperience}-{item?.maxExperience} yrs exp | {item?.minSalary}-{item?.maxSalary}Lacs</div>
                        </div>)
                    }
                    <div className="justify-start items-center">
                      {!viewAllSaveSearch ?
                        <span className="text-slate-600 text-sm font-medium cursor-pointer border-b border-slate-600" onClick={() => setViewAllSaveSearch(true)}>View all</span>
                        :
                        <span className="text-slate-600 text-sm font-medium cursor-pointer border-b border-slate-600" onClick={() => setViewAllSaveSearch(false)}>Close</span>
                      }
                    </div>
                  </div>
                </div>
                <div className="w-full h-12 px-7 bg-white rounded-xl justify-between items-center inline-flex">
                  <div className="justify-start items-center gap-1 flex">
                    <div className="w-6 h-6 relative">
                      <input
                        name="selectAll"
                        id="selectAll"
                        onChange={handleSelectAll}
                        checked={isCheckAll}
                        type="checkbox"
                      />
                    </div>
                    <div className="text-slate-600 text-base font-normal leading-snug tracking-tight">Select all</div>
                  </div>
                  <div className="justify-start items-center gap-5 flex">
                    <div className="w-[178px] rounded-lg justify-start items-center gap-2 flex">
                      <div className="w-6 h-6 flex-col justify-center items-center inline-flex cursor-pointer">
                        <DownloadResumeAsZip
                          searchResumeResult={jobCard}
                          isCheck={isCheck} />
                      </div>
                      <div className="text-indigo-900 text-base font-medium leading-snug tracking-tight">Download Resume</div>
                    </div>
                    <div className="w-[74px] rounded-lg justify-start items-center gap-2 flex cursor-pointer" onClick={openModal}>
                      <div className="w-6 h-6 justify-center items-center flex"><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}Email.svg`} alt='Email' /></div>
                      <div className="text-indigo-900 text-base font-medium leading-snug tracking-tight">Email</div>
                    </div>
                  </div>
                </div>

                {jobCard?.length !== 0 ?
                  <>
                    {jobCard && jobCard?.map((data: any, index: number) =>
                      <div className="w-full flex-col justify-start items-center gap-7 flex" key={index}>
                        <ResumeResultCard
                          data={data}
                          handleClick={handleClick}
                          setIsCheck={setIsCheck}
                          isCheck={isCheck}
                          applicantEmailId={applicantEmailId}
                          setApplicantEmailId={setApplicantEmailId}
                        />
                      </div>
                    )}
                  </>
                  : <NoRecords />
                }
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        title={composePreviewTitle}
        setIsOpen={setIsOpen}
        modalBody={
          <ComposeMail
            closeDialog={closeDialog}
            profileDashboard={{ jobCard }}
            applicantMailId={isCheck}
            setComposePreviewTitle={setComposePreviewTitle}
            composePreviewTitle={composePreviewTitle}
            mulitUser={true}
            applicantEmailId={applicantEmailId}

          />
        }
      />
      <Toaster />
    </>
  )
}

export default SearchResumeResult;