import React, { useEffect, useState } from 'react';
import JobCard from "../JobCard";
import { useAppDispatch, useAppSelector } from '../../../..';
import { getCheckJobToSave } from '../../../utils/utils';
import Cookies from 'js-cookie';
import { saveJobs } from '../../../../store/reducers/applyJobs/saveJob';
import { ToastContainer, toast } from 'react-toastify';
import TopCompanies from '../../Companies/TopCompanies';

const RecommendedJob = () => {
    const userId = Cookies.get("userId");
    const dispatch = useAppDispatch();
    const { jobList, loading } = useAppSelector((state) => state.getRecommendedJobs);
    const [saveJobCount, setSaveJobCount] = useState<{ [key: string]: boolean }>({});
    const [saveButton, setSaveButton] = useState<string>('');
    const [jobId, setJobId] = useState<any>(null);

    useEffect(() => {
        jobList?.map((job: { id: any; }) => {
            getCheckJobToSave(Number(userId), Number(job?.id)).then((count: any) => {
                if (count > 0) {
                    setSaveJobCount((prevSavedJobs) => ({
                        ...prevSavedJobs,
                        [job?.id]: true,
                    }));
                } else {
                    setSaveJobCount((prevSavedJobs) => ({
                        ...prevSavedJobs,
                        [job?.id]: false,
                    }));
                }
            });
        })
    }, [jobList, userId]);

    useEffect(() => {
        if (saveButton === 'save') {
            setSaveJobCount((prevSavedJobs) => ({
                ...prevSavedJobs,
                [jobId]: true,
            }));
            dispatch(
                saveJobs({
                    jobSeekerProfile: userId && parseInt(userId),
                    jobs: jobId && parseInt(jobId),
                })
            ).then((data: any) => {
                if (data?.payload?.count > 0) {
                    toast.info("job already saved !!");
                } else {
                    toast.success("job save successfully !!");
                }
            })
        }
    }, [userId, jobId, saveButton]);

    const onClickJobCard = (jobId: any) => {
        window.open(`/allJobs/jobDescription/${jobId}`, '_blank');
    }
    return (
        <>
            <div className="h-[10%] w-full"></div>
            <div className="grid grid-cols-12 gap-10 px-32 bg-[#F8FAFC] py-6">
                <div className="col-start-1 col-end-11">
                    <JobCard
                        onClickJobCard={onClickJobCard}
                        jobCard={jobList}
                        saveJobCount={saveJobCount}
                        setJobId={setJobId}
                        setSaveButton={setSaveButton}
                        loading={loading}
                        userId={userId}
                    />
                </div>
                <div className="col-start-11 col-end-13">
                    <div className="bg-[#F1F5F9] rounded-xl w-full p-5 sticky top-[13%]">
                        <h1 className="leading-none mb-3">Top Companies</h1>
                        <TopCompanies />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default RecommendedJob;