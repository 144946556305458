import { useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { getTotalYearsExpList } from '../../utils/utils';
import { useAppDispatch, useAppSelector } from '../../../';
import { setExpYears, setMaxExpYearId } from '../../../store/reducers/jobs/GetFilterJobs';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import Select from 'react-select';

export const ExperienceBasedFilter = ({ handleTotalExpYearChange, isOpen }: any) => {
    const dispatch = useAppDispatch();
    const { filtersData, expYear } = useAppSelector((state) => state.getFilterJobs);
    const [totalExpYear, setTotalExpYear] = useState<number>(0);

    useEffect(() => {
        (async () => {
            const experienceYearsList = await getTotalYearsExpList();
            if (Object.keys(experienceYearsList)?.length) {
                dispatch(setExpYears(experienceYearsList));
            }
        })();
    }, []);

    useEffect(() => {
        if (filtersData?.expYear !== null && !isOpen) {
            const experienceYearsData = expYear?.filter((item: any) => parseInt(item?.id) === filtersData?.expYear);
            let data = experienceYearsData[0]?.title?.split('');
            if (experienceYearsData?.length !== 0) {
                setTotalExpYear(parseInt(data?.slice(0, data.length - 5).join('')));
            } else {
                setTotalExpYear(31);
            }
        }
        else {
            setTotalExpYear(0);
        }
    }, [!isOpen, filtersData]);

    return (
        <div className="w-full">
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex w-full justify-between items-center">
                            <div className="flex items-center">
                                <svg
                                    className="h-6 w-6 mr-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path d="M6.5 9H5.5M10.5 9H9.5M6.5 6H5.5M10.5 6H9.5M18.5 15H17.5M18.5 11H17.5M14 8V22H18C19.886 22 20.828 22 21.414 21.414C22 20.828 22 19.886 22 18V12C22 10.114 22 9.172 21.414 8.586C20.828 8 19.886 8 18 8H14ZM14 8C14 5.172 14 3.757 13.121 2.879C12.243 2 10.828 2 8 2C5.172 2 3.757 2 2.879 2.879C2 3.757 2 5.172 2 8V10M8.025 13.955C8.03082 14.2213 7.98337 14.4862 7.88544 14.7339C7.78751 14.9817 7.64107 15.2074 7.45471 15.3977C7.26836 15.5881 7.04584 15.7393 6.80024 15.8425C6.55463 15.9457 6.29088 15.9988 6.02448 15.9987C5.75807 15.9986 5.49438 15.9452 5.24887 15.8418C5.00337 15.7383 4.78101 15.5869 4.59485 15.3963C4.40868 15.2057 4.26246 14.9799 4.16478 14.7321C4.06709 14.4842 4.01991 14.2193 4.026 13.953C4.03795 13.4304 4.25399 12.9333 4.62788 12.5681C5.00176 12.2028 5.50379 11.9984 6.02648 11.9987C6.54917 11.999 7.05099 12.2038 7.42451 12.5695C7.79803 12.9351 8.01358 13.4324 8.025 13.955ZM2.07 20.21C3.128 18.582 4.809 17.972 6.025 17.973C7.241 17.974 8.872 18.582 9.931 20.21C9.999 20.315 10.018 20.445 9.956 20.554C9.709 20.993 8.94 21.864 8.386 21.922C7.747 21.99 6.079 22 6.026 22C5.973 22 4.253 21.99 3.616 21.922C3.06 21.863 2.292 20.993 2.044 20.554C2.01618 20.4998 2.00388 20.439 2.00847 20.3783C2.01306 20.3176 2.03435 20.2594 2.07 20.21Z" stroke="#1B1A1A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <label className="text-[#475569] font-semibold">Experience</label>
                            </div>
                            <svg
                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-12">
                            <div className="relative mb-3">
                                <span
                                    id="inputRangeSelector"
                                    className="bg-[#C7D2FE] w-10 text-xs h-10 rounded-full text-[#312E81] absolute -top-1 -translate-y-full -translate-x-1/2 leading-none cursor-pointer after:content-normal after:border-t-[18px] after:border-t-[#C7D2FE] after:border-l-[17px] after:border-l-white after:border-r-[17px] after:border-r-white after:absolute after:top-[80%] after:left-1/2 after:-translate-x-1/2 flex justify-center items-center"
                                    style={{
                                        left: `${totalExpYear * 3.225}%`
                                    }}
                                >
                                    {totalExpYear !== 31 ? totalExpYear : "30+"}
                                </span>
                                <input
                                    className="w-full h-1 rounded-lg cursor-pointer overflow-hidden appearance-none bg-[#C7D2FE]"
                                    type="range"
                                    min="0"
                                    max="31"
                                    value={totalExpYear}
                                    onMouseUp={() => handleTotalExpYearChange(totalExpYear)}
                                    onChange={(event: React.FormEvent<HTMLInputElement> | any) => {
                                        setTotalExpYear(parseInt(event.target.value));
                                    }}
                                />
                            </div>
                            <div className="flex justify-between items-center text-[#64748B] text-xs">
                                <span>0 Yrs</span>
                                <span>30+ Yrs</span>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
};

const FiltersExperience = () => {
    const dispatch = useAppDispatch();
    const { expYear, maxExpYearId } = useAppSelector((state) => state.getFilterJobs);
    const [expYearMaster, setExpYearMaster] = useState([]);
    const [totalExpYear, setTotalExpYear] = useState<number>(0);

    useEffect(() => {
        setExpYearMaster(expYear?.map(({ id, title }: any) => ({ value: id, label: title })));
        if (maxExpYearId) {
            setTotalExpYear(maxExpYearId);
        } else {
            setTotalExpYear(0);
        }
    }, [maxExpYearId]);

    const handleTotalExpYearChange = (totalExpYear: number) => {
        dispatch(setMaxExpYearId(totalExpYear));
    };

    return (
        <div className="w-full px-5">
            <h1 className="font-semibold leading-none mt-5 mb-12 text-lg">Experience required</h1>
            <div className="relative mb-3">
                <span
                    id="inputRangeSelector"
                    className={`w-10 text-xs h-10 rounded-full text-white absolute -top-1 -translate-y-full -translate-x-1/2 leading-none cursor-pointer after:content-normal after:border-t-[18px] after:border-t-current after:border-l-[17px] after:border-l-white after:border-r-[17px] after:border-r-white after:absolute after:top-[80%] after:left-1/2 after:-translate-x-1/2 flex justify-center items-center ${totalExpYear !== 0 ? 'bg-blue-500' : 'bg-[#C7D2FE]'}`}
                    style={{
                        left: `${totalExpYear * 3.225}%`
                    }}
                >
                    {totalExpYear !== 31 ? totalExpYear : "30+"}
                    {totalExpYear !== 0 && (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 9H5.5M10.5 9H9.5M6.5 6H5.5M10.5 6H9.5M18.5 15H17.5M18.5 11H17.5M14 8V22H18C19.886 22 20.828 22 21.414 21.414C22 20.828 22 19.886 22 18V12C22 10.114 22 9.172 21.414 8.586C20.828 8 19.886 8 18 8H14ZM14 8C14 5.172 14 3.757 13.121 2.879C12.243 2 10.828 2 8 2C5.172 2 3.757 2 2.879 2.879C2 3.757 2 5.172 2 8V10M8.025 13.955C8.03082 14.2213 7.98337 14.4862 7.88544 14.7339C7.78751 14.9817 7.64107 15.2074 7.45471 15.3977C7.26836 15.5881 7.04584 15.7393 6.80024 15.8425C6.55463 15.9457 6.29088 15.9988 6.02448 15.9987C5.75807 15.9986 5.49438 15.9452 5.24887 15.8418C5.00337 15.7383 4.78101 15.5869 4.59485 15.3963C4.40868 15.2057 4.26246 14.9799 4.16478 14.7321C4.06709 14.4842 4.01991 14.2193 4.026 13.953C4.03795 13.4304 4.25399 12.9333 4.62788 12.5681C5.00176 12.2028 5.50379 11.9984 6.02648 11.9987C6.54917 11.999 7.05099 12.2038 7.42451 12.5695C7.79803 12.9351 8.01358 13.4324 8.025 13.955ZM2.07 20.21C3.128 18.582 4.809 17.972 6.025 17.973C7.241 17.974 8.872 18.582 9.931 20.21C9.999 20.315 10.018 20.445 9.956 20.554C9.709 20.993 8.94 21.864 8.386 21.922C7.747 21.99 6.079 22 6.026 22C5.973 22 4.253 21.99 3.616 21.922C3.06 21.863 2.292 20.993 2.044 20.554C2.01618 20.4998 2.00388 20.439 2.00847 20.3783C2.01306 20.3176 2.03435 20.2594 2.07 20.21Z" stroke="#1B1A1A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    )}
                </span>
                <input
                    className="w-full h-1 rounded-lg cursor-pointer overflow-hidden appearance-none bg-[#C7D2FE]"
                    type="range"
                    min="0"
                    max="31"
                    value={totalExpYear}
                    onMouseUp={() => handleTotalExpYearChange(totalExpYear)}
                    onChange={(event: React.FormEvent<HTMLInputElement> | any) => {
                        setTotalExpYear(parseInt(event.target.value));
                    }}
                />
            </div>
            <div className="flex justify-between items-center text-[#64748B] text-xs mb-3">
                <span>0 Yrs</span>
                <span>30+ Yrs</span>
            </div>
            <div>
                <h1 className="text-[#64748B] text-xs mb-3">Max experience</h1>
                <Select
                    isClearable
                    isSearchable={true}
                    className="text-sm w-1/2 mb-48"
                    classNamePrefix="dropdown"
                    options={expYearMaster}
                />
            </div>
        </div>
    )
};

export default FiltersExperience;
