import { useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { useAppSelector, useAppDispatch } from '../../..';
import { getDepartmentList } from '../../utils/utils';
import { setDepartment, setNavigateFilterOption, setDepartmentIds, setCheckItems } from '../../../store/reducers/companies/getAllCompanies';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { BiSearch } from 'react-icons/bi';

export const CompanyDepartmentFilter = ({ handleDepartmentCheckbox, setIsOpen }: any) => {
    const dispatch = useAppDispatch();
    const { department } = useAppSelector((state) => state.getAllCompanies);
    useEffect(() => {
        (async () => {
            const departmentList = await getDepartmentList();
            if (Object.keys(departmentList)?.length) {
                dispatch(setDepartment(departmentList));
            }
        })();
    }, []);
    const handleViewAll = () => {
        setIsOpen(true)
        dispatch(setNavigateFilterOption("Department"));
    }
    return (
        <div className="w-full h-full">
            <Disclosure>
                {({
                    open
                }) => <>
                        <Disclosure.Button className="flex w-full justify-between items-center">
                            <div className="flex items-center">
                                <svg
                                    className="h-6 w-6 mr-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path d="M4.28503 2.75H19.715V21.25H4.28503V2.75Z" stroke="#565151" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4 11.7274H8.00013M4 11.7274L4.00052 15.5908H8.00013M4 11.7274V7.86393M8.00013 11.7274V15.5908M8.00013 11.7274H12.0003M8.00013 11.7274V7.86393M8.00013 15.5908H12.0003M12.0003 11.7274V15.5908M12.0003 11.7274H16.0004M12.0003 11.7274V7.86393M12.0003 15.5908H16.0004M16.0004 11.7274V15.5908M16.0004 11.7274H20M16.0004 11.7274V7.86393M16.0004 15.5908H20V11.7274M20 11.7274V7.86393M4 7.86393H8.00013M4 7.86393V4H8.00013M8.00013 7.86393H12.0003M8.00013 7.86393V4M12.0003 7.86393H16.0004M12.0003 7.86393V4M16.0004 7.86393H20M16.0004 7.86393V4M20 7.86393V4H16.0004M8.00013 4H12.0003M12.0003 4H16.0004M5.60057 17.1366H8.80016V21H5.60057V17.1366ZM15.2004 17.1366H18.3999V21H15.2004V17.1366ZM10.4002 17.1366H13.6003V21H10.4002V17.1366Z" stroke="#565151" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <label className="text-[#475569] font-semibold">Department</label>
                            </div>
                            <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-5">
                            {department?.slice(0, 5)?.map((item: any) => <div className="text-[#475569] mb-2 flex justify-start items-center">
                                <input type="checkbox" defaultChecked={false} checked={item?.isChecked} onChange={() => handleDepartmentCheckbox(item)} />
                                <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">{item?.title}</label>
                            </div>)}
                            <button className="text-[#4F46E5] text-sm" onClick={handleViewAll}>
                                View all...
                            </button>
                        </Disclosure.Panel>
                    </>}
            </Disclosure>
        </div >
    )
}

const FiltersDepartment = () => {
    const dispatch = useAppDispatch();
    const { checkItems } = useAppSelector((state) => state.getAllCompanies);
    const [searchQuery, setSearchQuery] = useState("");

    const handleDepartmentCheckbox = (data: any) => {
        dispatch(setCheckItems({
            department: checkItems?.department?.map((item: any) =>
                item?.id === data?.id ? { ...item, isChecked: !item.isChecked } : item
            )
        }));
        if (data?.isChecked === undefined || data?.isChecked === false) {
            dispatch(setDepartmentIds(data?.id));
        } else {
            dispatch(setDepartmentIds({ filter: data?.id }));
        }
    };

    const handleSearch = (event: any) => {
        setSearchQuery(event.target.value);
    };

    const filteredItems = checkItems?.department?.filter((item: any) =>
        item?.title?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="w-full h-full">
            <div className="flex flex-col justify-between h-full px-5">
                <h1 className="font-semibold leading-none my-5 text-lg">Select department</h1>
                <div className="relative flex items-center w-full py-2 mb-3 border border-[#E0E7FF] rounded-lg overflow-hidden">
                    <div className="grid place-items-center h-full w-12 text-gray-300">
                        <BiSearch className="h-5 w-5" />
                    </div>
                    <input
                        className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                        type="text"
                        id="search"
                        onChange={handleSearch}
                        placeholder="Search department.." />
                </div>
                <div className="h-96 overflow-x-auto overflow-y-hidden flex flex-col flex-wrap">
                    {filteredItems?.map((item: any) =>
                        <div className="text-[#475569] flex justify-start items-center mt-1 text-sm w-1/2">
                            <input type="checkbox" defaultChecked={false} checked={item?.isChecked} onChange={() => handleDepartmentCheckbox(item)} />
                            <label className="ml-2 overflow-hidden inline-block whitespace-nowrap text-ellipsis">{item?.title}</label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FiltersDepartment;