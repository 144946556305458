import { useEffect, useState } from 'react';
import Modal from '../../../commonComponents/Modal';
import { MdOutlineDriveFileMove } from "react-icons/md";
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../..';
import Select from 'react-select';
import { BiSearch } from 'react-icons/bi';
import { getUsersByUserTypeWithSameOrg } from '../../../../store/reducers/recruiter/manageFolder/getUsersByUserTypeWithSameOrg';
import { shareFolder } from '../../../../store/reducers/recruiter/manageFolder/shareFolder';

interface IFormInputs {
    userId: any
}

const customStylesForSingle = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '8px',
        padding: '3px 0 3px 20px',
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '5px',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '5px',
    }),
};

const SignUpSchema = yup
    .object({
        userId: yup.object().shape({
            value: yup.number().optional(),
            label: yup.string().optional(),
        }),
    })
    .required();

const ShareFolder = ({ post }: any) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const { getUsersByUserTypeWithSameOrg: userTypeWithSameOrg } = useAppSelector((state) => state.getUsersByUserTypeWithSameOrg);
    const { success } = useAppSelector((state) => state.shareFolder);
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors }
    } = useForm<IFormInputs>({
        resolver: yupResolver(SignUpSchema),
    });

    useEffect(() => {
        if (success) {
            setIsOpen(false);
        }
    }, [success]);

    useEffect(() => {
        dispatch(getUsersByUserTypeWithSameOrg());
    }, []);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const onSubmit = (data: IFormInputs) => {
        dispatch(shareFolder({ userId: data?.userId?.value, folderId: post?.id }))
    }

    return (
        <>
            <button onClick={openModal}>
                <MdOutlineDriveFileMove />
            </button>
            <Modal
                title={"Share folder"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                modalBody={
                    <div>
                        <hr className="my-4" />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col gap-4">
                                <div>
                                    <p className="mb-4 text-[#64748B]">Please select the recruiter from the below dropdown list and move a folder to recruiter</p>
                                    <div className="relative flex justify-start items-center">
                                        <BiSearch className="h-5 w-5 absolute z-10 top-1/2 left-2 -translate-y-1/2" />
                                        <Controller
                                            name="userId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable={true}
                                                    className='text-sm w-1/2'
                                                    styles={customStylesForSingle}
                                                    placeholder="Search location"
                                                    options={userTypeWithSameOrg?.map(({ id, name }: any) => ({ value: id, label: name })) as any}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <hr className="my-4" />
                                <div className="flex justify-start items-center">
                                    <div>
                                        <button
                                            type="button"
                                            className="mr-3 bg-[#EEF2FF] px-12 py-3 rounded-lg text-[#312E81]"
                                            onClick={closeDialog}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-[#4F46E5] px-12 py-3 rounded-lg text-[#FFF]"
                                        >
                                            Share
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                }
            />
        </>
    )
}

export default ShareFolder