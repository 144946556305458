import { useEffect, useState } from 'react';
import Modal from '../../../commonComponents/Modal';
import { FiEdit2 } from "react-icons/fi";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { renameFolderName } from '../../../../store/reducers/recruiter/manageFolder/renameFolderName';
import { useAppDispatch, useAppSelector } from '../../../..';

interface IFormInputs {
    folderName: string
}

const SignUpSchema = yup
    .object({
        folderName: yup.string().label("Folder Name").required()
    })
    .required();

const RenameFolderName = ({ post }: any) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const { success, error, loading } = useAppSelector((state) => state.renameFolderName);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<IFormInputs>({
        resolver: yupResolver(SignUpSchema),
        defaultValues:{
            folderName:post?.name
        }
    });
    
    useEffect(() => {
        if (success) {
            setIsOpen(false);
        }
    }, [success]);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const onSubmit = (data: IFormInputs) => {
        dispatch(renameFolderName({ name: data, id: post?.id }));
        reset();
    }

    return (
        <>
            <button onClick={openModal}>
                <FiEdit2 />
            </button>
            <Modal
                title={"Rename Folder name"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                modalBody={
                    <div>
                        <hr className="my-4" />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col gap-4">
                                <div>
                                    <p className="mb-4 text-[#64748B]">It will help you emphasize the aspects of your skills that are most relevant and enhances your reach to employers</p>
                                    <div>
                                        <input
                                            className="w-1/2 border border-[#E0E7FF] outline-none rounded px-3 py-3"
                                            placeholder="Please enter folder name"
                                            {...register("folderName")}
                                        />
                                        {
                                            errors.folderName
                                            &&
                                            <p className="font-normal text-xs text-red-500 absolute">
                                                {errors.folderName.message}
                                            </p>
                                        }
                                    </div>
                                </div>
                                <hr className="my-4" />
                                <div className="flex justify-start items-center">
                                    <div>
                                        <button
                                            type="button"
                                            className="mr-3 bg-[#EEF2FF] px-12 py-3 rounded-lg text-[#312E81]"
                                            onClick={closeDialog}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-[#4F46E5] px-12 py-3 rounded-lg text-[#FFF]"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                }
            />
        </>
    )
}

export default RenameFolderName