import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState: any = {
    loading: false,
    error: false,
    success: false,
    renameFolderName: {},
    errorMessage: undefined,
}

export const renameFolderName = createAsyncThunk(
    "renameFolderName", async (data:any) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_PATH}/folder/editFolder/${data?.id}`,
                data?.name,
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                }
            );
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
        } catch (error) {
            throw error;
        }
    });

const renameFolderNameSlice = createSlice({
    name: 'renameFolderName',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(renameFolderName.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(renameFolderName.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.renameFolderName = action.payload;
        });
        builder.addCase(renameFolderName.rejected, (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = true;
            //   state.errorMessage = action.error.message;
        });
    },
    reducers: {
        clearRenameFolderNameSlice: (state) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            return state;
        },
    }
});

export default renameFolderNameSlice.reducer;
export const { clearRenameFolderNameSlice } = renameFolderNameSlice.actions;