import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../..';
import { clearGetSelctedJobSeekersListSlice, getSelctedJobSeekersList } from '../../../../store/reducers/recruiter/manageFolder/getSelctedJobSeekersList';
import DownloadResumeAsZip from '../../../commonComponents/DownloadResumeAsZip';
import { toast } from 'react-toastify';
import Modal from '../../../commonComponents/Modal';
import Toaster from '../../../commonComponents/Toaster';
import ComposeMail from '../MailCenter/ComposeMail';
import CandidatesCard from './CandidatesCard';

const Candidates = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { jobSeekersList, success, error, loading } = useAppSelector((state) => state.getSelctedJobSeekersList);
    const [data, setData] = useState<any>([]);
    const [isCheck, setIsCheck] = useState<any>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [composePreviewTitle, setComposePreviewTitle] = useState('Compose Mail');
    const [applicantEmailId, setApplicantEmailId] = useState<any>([]);
    const [isCheckAll, setIsCheckAll] = useState(false);

    useEffect(() => {
        dispatch(getSelctedJobSeekersList({ jobSeekerIds: location?.state?.jobSeekers?.map((item: any) => item?.jobSeekerId) }));
    }, []);

    useEffect(() => {
        if (success) {
            setData(jobSeekersList?.data);
            dispatch(clearGetSelctedJobSeekersListSlice());
        }
    }, [success]);

    const closeDialog = () => {
        setIsOpen(false);
    }

    const openModal = () => {
        if (isCheck?.length > 0) {
            setIsOpen(true);
        } else {
            toast.success("Please check at least one ")
        }
    }

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(data && data?.map((li: any) => li.id));
        setApplicantEmailId(data && data?.map((li: any) => li.user.userProfile?.email))
        if (isCheckAll) {
            setIsCheck([]);
            setApplicantEmailId([]);
        }
    };

    const handleClick = (e: any) => {
        const { id, checked } = e.target;
        setApplicantEmailId([...applicantEmailId, data?.filter((li: any) => li.user.id === Number(id))[0].user.userProfile?.email])
        setIsCheck([...isCheck, Number(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter((item: any) => item !== Number(id)));
            setApplicantEmailId(applicantEmailId.filter((item: any) => item !== data?.filter((li: any) => li.user.id === Number(id))[0].user.userProfile?.email))
        }
    };

    return (
        <>
            <div className="h-[10%] w-full"></div>
            <div className="grid grid-cols-1 gap-5 px-64 py-5 bg-[#F8FAFC]">
                <div className="bg-[#FFF] rounded-xl px-7 flex justify-between items-center h-12">
                    <div className="text-[#475569] flex justify-start items-center">
                        <input
                            name="selectAll"
                            id="selectAll"
                            onChange={handleSelectAll}
                            checked={isCheckAll}
                            type="checkbox"
                        />
                        <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Select all</label>
                    </div>

                    <div className="justify-start items-center gap-5 flex">
                        <div className="w-[178px] rounded-lg justify-start items-center gap-2 flex">
                            <div className="w-6 h-6 flex-col justify-center items-center inline-flex cursor-pointer">
                                <DownloadResumeAsZip
                                    searchResumeResult={data}
                                    isCheck={isCheck} />
                            </div>
                            <div className="text-indigo-900 text-base font-medium leading-snug tracking-tight">Download Resume</div>
                        </div>
                        <div className="w-[74px] rounded-lg justify-start items-center gap-2 flex cursor-pointer" onClick={openModal}>
                            <div className="w-6 h-6 justify-center items-center flex"><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}Email.svg`} alt='Email' /></div>
                            <div className="text-indigo-900 text-base font-medium leading-snug tracking-tight">Email</div>
                        </div>
                    </div>

                </div>
                {
                    data?.map((item: any, index: number) => (
                        <div key={index}>
                            <CandidatesCard includes={isCheck.includes} handleClick={handleClick} item={item} setApplicantEmailId={setApplicantEmailId} setIsCheck={setIsCheck} applicantEmailId={applicantEmailId} isCheck={isCheck} folderId={location?.state?.id}/>
                        </div>
                    ))
                }
            </div>
            <Modal
                isOpen={isOpen}
                title={composePreviewTitle}
                setIsOpen={setIsOpen}
                modalBody={
                    <ComposeMail
                        closeDialog={closeDialog}
                        folderId={location?.state?.id}
                        profileDashboard={{ data }}
                        applicantMailId={isCheck}
                        setComposePreviewTitle={setComposePreviewTitle}
                        composePreviewTitle={composePreviewTitle}
                        mulitUser={true}
                        applicantEmailId={applicantEmailId}

                    />
                }
            />
            <Toaster />
        </>
    )
}

export default Candidates;