import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { Combobox, Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import Modal from '../../../commonComponents/Modal';
import ComposeMail from '../MailCenter/ComposeMail';
import DownloadResume from '../../../commonComponents/DownloadResume';

const CandidatesCard = ({ handleClick, item, setApplicantEmailId, setIsCheck, applicantEmailId, isCheck, folderId }: any) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [composePreviewTitle, setComposePreviewTitle] = useState('Compose Mail');

    const closeDialog = () => {
        setIsOpen(false);
    }

    const openModal = () => {
        setApplicantEmailId([item?.user?.userProfile?.email])
        setIsCheck([item?.user?.id])
        setIsOpen(true);
    }

    return (
        <>
            <div className="grid grid-cols-1 gap-5 bg-[#FFF] rounded-xl">
                <div className="flex gap-3 px-7 pt-7">
                    <div>
                        {item.profilePicturePath ? <img className="w-[60px] h-[60px] rounded-[40px]" src={`${process.env.REACT_APP_PROFILE_PICTURE_FILE_LOCATION}/${item?.profilePicturePath}`} /> : <img className="w-[60px] h-[60px] rounded-[40px]" src={`${process.env.REACT_APP_IMAGE_BASE_URL}defaultProfilePic.svg`} />}
                    </div>
                    <div>
                        <div>
                            <h1 className="text-2xl font-bold">{item?.user?.userProfile?.name}</h1>
                        </div>
                        <div className="text-slate-500 flex items-center gap-5">
                            <h3>{item?.currentJobTitle?.title}</h3>
                            {item?.currentCompany?.title && <span>@{capitalizeFirstLetter(item?.currentCompany?.title)}</span>}
                        </div>
                    </div>
                </div>
                <hr />
                <div className="px-7">
                    <Disclosure>
                        {({
                            open
                        }) => <>
                                <Disclosure.Button className="flex w-full justify-between items-center">
                                    <div className="flex items-center gap-3">
                                        {item?.currentLocation?.title && <div className="flex justify-start items-center">
                                            <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}location.svg`} alt='location' />
                                            <span className="text-slate-500 text-base ml-2">{item?.currentLocation?.title}</span>
                                        </div>}
                                        {item?.totalExpYear?.title && <div className="flex justify-start items-center">
                                            <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}experience.svg`} alt='experience' />
                                            <span className="text-slate-500 text-base ml-2">{item?.totalExpYear?.title} {item?.totalExpMonth?.title}</span>
                                        </div>}
                                        {item?.noticePeriod?.title && <div className="flex justify-start items-center">
                                            <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}noticePeriod.svg`} alt='noticePeriod' />
                                            <span className="text-slate-500 text-base ml-2">{item?.noticePeriod?.title}</span>
                                        </div>}
                                        {item?.careerProfile?.currency?.title && <div className="flex justify-start items-center">
                                            <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}package.svg`} alt='package' />
                                            <span className="text-slate-500 text-base ml-2">{item?.careerProfile?.currency?.title}{item?.careerProfile?.expectedSalary} PA</span>
                                        </div>}
                                    </div>
                                    <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                                </Disclosure.Button>
                                <Disclosure.Panel className="mt-5">
                                    <Combobox>
                                        <div className="relative grid grid-cols-1 gap-2">
                                            <h1 className="font-bold">Qualifications</h1>
                                            <div>
                                                <h1 className="text-slate-500">Education</h1>
                                                {item?.educations?.map((education: any) => <p className="flex items-center gap-2">
                                                    <span>{education?.education}</span>
                                                    <span>@{education?.institute}</span>
                                                </p>)}
                                            </div>
                                            <div>
                                                <h1 className="text-slate-500">Employment</h1>
                                                {item?.employments?.map((employment: any) => <p className="flex items-center gap-2">
                                                    <span>{employment?.designation}</span>
                                                    <span>@{employment?.companyName}</span>
                                                </p>)}
                                            </div>
                                            <div>
                                                <h1 className="text-slate-500">Skills</h1>
                                                <p className="flex flex-row flex-wrap items-center">
                                                    {item?.keySkills?.map((keySkill: any) => <span>{keySkill?.profileKeySkills?.title}</span>)}
                                                </p>
                                            </div>
                                        </div>
                                    </Combobox>
                                </Disclosure.Panel>
                            </>}
                    </Disclosure>
                </div>
                <div className="bg-[#FFF] rounded-b-xl px-7 justify-start items-center gap-3 flex h-12" style={{
                    boxShadow: '0 -1px 1px 0px rgba(0, 0, 0, 0.1)'
                }}>

                    <div className="text-[#475569] flex justify-start items-center">
                        <input type="checkbox" key={item.id} name={item.id} id={item.id} onChange={handleClick} checked={isCheck.includes(Number(item.id))} />
                        <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Select</label>
                    </div>
                    <div className="w-6 h-6 justify-center items-center flex cursor-pointer"><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}Email.svg`} alt='Email' onClick={openModal} /></div>
                    <div className="w-6 h-6 justify-center items-center flex cursor-pointer"><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}comment.svg`} alt='comment' /></div>
                    <div className="w-6 h-6 justify-center items-center flex"><Link to={`https://api.whatsapp.com/send?phone=${item?.user?.mobileNumber}&text=Hi%20${item?.user?.userProfile?.name}`} target='_blank'><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}whatsapp.svg`} alt='whatsapp' /></Link></div>
                    <div className="w-6 h-6 justify-center items-center flex cursor-pointer"><DownloadResume data={{ jobSeekerProfile: item }} /></div>
                    <div className="w-6 h-6 justify-center items-center flex cursor-pointer"><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}Visibility.svg`} alt='Visibility' /></div>
                </div>
            </div>
            <Modal
                isOpen={isOpen}
                title={composePreviewTitle}
                setIsOpen={setIsOpen}
                modalBody={
                    <ComposeMail
                        closeDialog={closeDialog}
                        folderId={folderId}
                        profileDashboard={{ item }}
                        applicantMailId={isCheck}
                        setComposePreviewTitle={setComposePreviewTitle}
                        composePreviewTitle={composePreviewTitle}
                        mulitUser={true}
                        applicantEmailId={applicantEmailId}

                    />
                }
            />
        </>
    )
}

export default CandidatesCard