import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';
import * as yup from "yup";
import { getDepartmentList, getEducationTypeList, getEmployeeTypeList, getGenderList, getIndustryList, getJobRoleList, getKeySkillsList, getLocationList, getMaritalStatusList, getNoticePeriodList, getPreferredShift, getRoleCategoryList, getSalaryRangeList, getTotalYearsExpList, randomNumberInRange } from '../../../utils/utils';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../..';
import { saveResume } from '../../../../store/reducers/recruiter/saveResume';
import { useNavigate } from 'react-router-dom';
import { getSaveResume } from '../../../../store/reducers/recruiter/getSaveResume';
import Cookies from 'js-cookie';
import { clearSaveResumeSlice } from '../../../../store/reducers/recruiter/saveResume';
import { getRecentResume } from '../../../../store/reducers/recruiter/getRecentSearch';
import { scrollToTop } from '../../../utils/utils';
import NoRecords from '../../../commonComponents/NoRecords';

const customStylesForMulti = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '8px',
    padding: '12px 0',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '5px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '5px',
  }),
};

const customStylesForSingle = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '8px',
    padding: '3px 0',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '5px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '5px',
  }),
};

interface SearchResumeInputs {
  keywords: string[];
  minExperience: | any | undefined;
  maxExperience: any;
  currentLocation: string[] | undefined;
  minSalary: any;
  maxSalary: any;
  preferredLocation: string[] | undefined;
  industry: string[] | undefined;
  department: any;
  roleCategory: any;
  designation: string[] | undefined;
  empType: string[] | undefined;
  shiftType: string[] | undefined;
  noticePeriod: any;
  qualification: string[] | undefined;
  gender: string[] | undefined;
  maritalStatus: string[] | undefined;
  candidateMinAge: any;
  candidateMaxAge: any;
  isMobileNumberVerified: boolean | undefined;
  isEmailVerified: boolean | undefined;
  verifyBGV: boolean | undefined;
  resumeAttach: boolean | undefined;
  activeIn: string | undefined;
}

const SearchResumeSchema = yup.object().shape({
  keywords: yup
    .array()
    .min(1, "Pick at least one keySkills")
    .max(10, "Pick at most ten keySkills")
    .required(),
  minExperience: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }),
  maxExperience: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }),
  currentLocation: yup
    .array()
    .min(1, "Pick at least one location")
    .max(10, "Pick at most ten location")
    .optional(),
  minSalary: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }),
  maxSalary: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }),
  preferredLocation: yup
    .array()
    .min(1, "Pick at least one location")
    .max(10, "Pick at most ten location")
    .optional(),
  industry: yup
    .array()
    .min(1, "Pick at least one industry")
    .max(10, "Pick at most ten industry")
    .optional(),
  department: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }),
  roleCategory: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }),
  designation: yup
    .array()
    .min(1, "Pick at least one designation")
    .max(10, "Pick at most ten designation")
    .optional(),
  empType: yup.array().optional(),
  shiftType: yup.array().optional(),
  noticePeriod: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }),
  qualification: yup.array().optional(),
  gender: yup.array().optional(),
  maritalStatus: yup.array().optional(),
  candidateMinAge: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }),
  candidateMaxAge: yup
    .object()
    .shape({
      value: yup.number().optional(),
      label: yup.string().optional(),
    }),
  isMobileNumberVerified: yup.boolean(),
  isEmailVerified: yup.boolean(),
  verifyBGV: yup.boolean(),
  resumeAttach: yup.boolean(),
  activeIn: yup.string().optional(),
});


const SearchResumesForm = () => {
  const navigate = useNavigate();
  const userId = Cookies.get("userId");
  const { searchResumeResult } = useAppSelector((state) => state.getSaveResume);
  const { searchResumeResult: searchResumeResultRecent } = useAppSelector((state) => state.getRecentResume);

  const { success } = useAppSelector((state) => state.saveResume);
  const dispatch = useAppDispatch();
  const [keySkills, setKeySkills] = useState<any>([]);
  const [totalYearsExp, setTotalYearsExp] = useState<any>([]);
  const [locationList, setLocationList] = useState<any>([]);
  const [salaryRange, setSalaryRange] = useState<any>([]);
  const [industryList, setIndustryList] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [roleCategoryList, setRoleCategoryList] = useState<any>([]);
  const [jobTitleList, setJobTitleList] = useState<any>([]);
  const [employeeTypeList, setEmployeeTypeList] = useState<any>([]);
  const [preferredShift, setPreferredShift] = useState<any>([]);
  const [noticePeriodList, setNoticePeriodList] = useState<any>([]);
  const [educationTypeList, setEducationTypeList] = useState<any>([]);
  const [genderList, setGenderList] = useState<any>([]);
  const [maritalStatusList, setMaritalStatusList] = useState<any>([]);
  const [actionType, setActionType] = useState("");

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<SearchResumeInputs>({
    defaultValues: {
      empType: [],
      shiftType: [],
      isMobileNumberVerified: false,
      isEmailVerified: false,
      verifyBGV: false,
      resumeAttach: false
    },
    resolver: yupResolver(SearchResumeSchema)
  });

  const { fields: fieldsEmpType, append: appendEmpType, remove: removeEmpType } = useFieldArray<any>({
    control,
    name: "empType"
  });

  const { fields: fieldsShiftType, append: appendShiftType, remove: removeShiftType } = useFieldArray<any>({
    control,
    name: "shiftType"
  });

  const { fields: fieldsQualification, append: appendQualification, remove: removeQualification } = useFieldArray<any>({
    control,
    name: "qualification"
  });

  const { fields: fieldsGender, append: appendGender, remove: removeGender } = useFieldArray<any>({
    control,
    name: "gender"
  });

  const { fields: fieldsMaritalStatus, append: appendMaritalStatus, remove: removeMaritalStatus } = useFieldArray<any>({
    control,
    name: "maritalStatus"
  });

  useEffect(() => {
    (async () => {
      const keySkillsList = await getKeySkillsList();
      if (Object.keys(keySkillsList)?.length) {
        setKeySkills(keySkillsList as any);
      }
      const totalYearsExpList = await getTotalYearsExpList();
      if (Object.keys(totalYearsExpList)?.length) {
        setTotalYearsExp(totalYearsExpList as any);
      }
      const locationList = await getLocationList();
      if (Object.keys(locationList)?.length) {
        setLocationList(locationList as any);
      }
      const salaryRangeList = await getSalaryRangeList();
      if (Object.keys(salaryRangeList)?.length) {
        setSalaryRange(salaryRangeList as any);
      }
      const industryList = await getIndustryList();
      if (Object.keys(industryList)?.length) {
        setIndustryList(industryList as any);
      }
      const departmentList = await getDepartmentList();
      if (Object.keys(departmentList)?.length) {
        setDepartmentList(departmentList as any);
      }
      const roleCategoryList = await getRoleCategoryList();
      if (Object.keys(roleCategoryList)?.length) {
        setRoleCategoryList(roleCategoryList as any);
      }
      const jobRoleList = await getJobRoleList()
      if (Object.keys(jobRoleList)?.length) {
        setJobTitleList(jobRoleList as any)
      }
      const employeeTypeList = await getEmployeeTypeList();
      if (Object.keys(employeeTypeList)?.length) {
        setEmployeeTypeList(employeeTypeList as any);
      }
      const preferredShift = await getPreferredShift();
      if (Object.keys(preferredShift)?.length) {
        setPreferredShift(preferredShift as any);
      }
      const noticePeriodList = await getNoticePeriodList();
      if (Object.keys(noticePeriodList)?.length) {
        setNoticePeriodList(noticePeriodList as any);
      }
      const educationTypeList = await getEducationTypeList();
      if (Object.keys(educationTypeList)?.length) {
        setEducationTypeList(educationTypeList as any);
      }
      const genderList = await getGenderList();
      if (Object.keys(genderList)?.length) {
        setGenderList(genderList as any);
      }
      const maritalStatusList = await getMaritalStatusList();
      if (Object.keys(maritalStatusList)?.length) {
        setMaritalStatusList(maritalStatusList as any);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(getRecentResume(userId));
    dispatch(getSaveResume(userId));
    dispatch(clearSaveResumeSlice());
    scrollToTop();
  }, [success]);

  const onSubmit = async (data: any) => {
    if (actionType === "Save") {
      dispatch(saveResume({
        keywords: data?.keywords?.map((item: any) => item?.value),
        minExperience: data?.minExperience?.value,
        maxExperience: data?.maxExperience?.value,
        currentLocation: data?.currentLocation?.map((item: any) => item?.value),
        minSalary: data?.minSalary?.value,
        maxSalary: data?.maxSalary?.value,
        preferredLocation: data?.preferredLocation?.map((item: any) => item?.value),
        industry: data?.industry?.map((item: any) => item?.value),
        department: data?.department?.value,
        roleCategory: data?.roleCategory?.value,
        designation: data?.designation?.map((item: any) => item?.value),
        empType: data?.empType?.map((item: any) => item?.id),
        shiftType: data?.shiftType?.map((item: any) => item?.id),
        noticePeriod: data?.noticePeriod?.label,
        qualification: data?.qualification?.map((item: any) => item?.id),
        gender: data?.gender?.map((item: any) => item?.id),
        maritalStatus: data?.maritalStatus?.map((item: any) => item?.id),
        candidateMinAge: data?.noticePeriod?.value,
        candidateMaxAge: data?.noticePeriod?.value,
        isMobileNumberVerified: data?.isMobileNumberVerified,
        isEmailVerified: data?.isEmailVerified,
        verifyBGV: data?.verifyBGV,
        resumeAttach: data?.resumeAttach,
        activeIn: data?.activeIn,
      }));
    } else {
      navigate('/cvZone/searchResumeResult', { state: data });
    }
  };

  const handleShowProfile = (item: any, toggle: string) => {
    navigate('/cvZone/searchResumeResult', {
      state: {
        keywords: toggle === "recent" ? item?.keywords?.flatMap((keyword: any) => keySkills?.filter((list: any) => list?.title === keyword)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.keywords?.map(({ id, title }: any) => ({ value: id, label: title })),
        minExperience: {},
        maxExperience: {},
        currentLocation: [],
        minSalary: {},
        maxSalary: {},
        preferredLocation: [],
        industry: [],
        department: {},
        roleCategory: {},
        designation: [],
        empType: [],
        shiftType: [],
        noticePeriod: {},
        qualification: [],
        gender: [],
        maritalStatus: [],
        candidateMinAge: {},
        candidateMaxAge: {},
        isMobileNumberVerified: false,
        isEmailVerified: false,
        verifyBGV: false,
        resumeAttach: false,
      }
    });
  }

  const handleFillSearch = (item: any, toggle: string) => {
    setValue("keywords", toggle === "recent" ? item?.keywords?.flatMap((keyword: any) => keySkills?.filter((list: any) => list?.title === keyword)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.keywords?.map(({ id, title }: any) => ({ value: id, label: title })));
    setValue("minExperience", totalYearsExp?.filter((exp: any) => exp?.id === item?.minExperience).map(({ id, title }: any) => ({ value: id, label: title }))[0]);
    setValue("maxExperience", totalYearsExp?.filter((exp: any) => exp?.id === item?.maxExperience).map(({ id, title }: any) => ({ value: id, label: title }))[0]);
    setValue("currentLocation", toggle === "recent" ? item?.currentLocation?.flatMap((location: any) => locationList?.filter((list: any) => list?.title === location)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.currentLocation?.map(({ id, title }: any) => ({ value: id, label: title })));
    setValue("minSalary", salaryRange?.filter((exp: any) => exp?.id === item?.minSalary).map(({ id, title }: any) => ({ value: id, label: title }))[0]);
    setValue("maxSalary", salaryRange?.filter((exp: any) => exp?.id === item?.maxSalary).map(({ id, title }: any) => ({ value: id, label: title }))[0]);
    setValue("preferredLocation", toggle === "recent" ? item?.preferredLocation?.flatMap((location: any) => locationList?.filter((list: any) => list?.title === location)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.preferredLocation?.map(({ id, title }: any) => ({ value: id, label: title })));
    setValue("industry", toggle === "recent" ? item?.industry?.flatMap((industry: any) => industryList?.filter((list: any) => list?.title === industry)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.industry?.map(({ id, title }: any) => ({ value: id, label: title })));
    setValue("department", toggle === "recent" ? departmentList?.filter((list: any) => list?.title === item?.department).map(({ id, title }: any) => ({ value: id, label: title }))[0] : departmentList?.filter((department: any) => department?.id === item?.department[0]?.id).map(({ id, title }: any) => ({ value: id, label: title }))[0]);
    setValue("roleCategory", toggle === "recent" ? roleCategoryList?.filter((list: any) => list?.title === item?.roleCategory).map(({ id, title }: any) => ({ value: id, label: title }))[0] : roleCategoryList?.filter((roleCategory: any) => roleCategory?.id === item?.roleCategory[0]?.id).map(({ id, title }: any) => ({ value: id, label: title }))[0]);
    setValue("designation", toggle === "recent" ? item?.designation?.flatMap((designation: any) => jobTitleList?.filter((list: any) => list?.title === designation)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.designation?.map(({ id, title }: any) => ({ value: id, label: title })));
    const empSelectData = toggle === "recent" ? item?.empType?.flatMap((empType: any) => employeeTypeList?.filter((list: any) => list?.title === empType)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.empType?.map(({ id, title }: any) => ({ value: id, label: title }));
    if (empSelectData?.length) {
      setValue("empType", empSelectData);
      const updatedEmployeeTypeList = employeeTypeList.map((empType: any) => {
        const isSelected = empSelectData.some((selectData: any) => selectData.label === empType.title);
        if (isSelected) {
          return { ...empType, isChecked: true };
        }
        return empType;
      });
      setEmployeeTypeList(updatedEmployeeTypeList);
    }
    const shiftTypeSelectData = toggle === "recent" ? item?.shiftType?.flatMap((shiftType: any) => preferredShift?.filter((list: any) => list?.title === shiftType)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.shiftType?.map(({ id, title }: any) => ({ value: id, label: title }));
    if (shiftTypeSelectData?.length) {
      setValue("shiftType", shiftTypeSelectData);
      const updatedShiftTypeList = preferredShift.map((list: any) => {
        const isSelected = shiftTypeSelectData.some((shiftType: any) => shiftType.label === list.title);
        if (isSelected) {
          return { ...list, isChecked: true };
        }
        return list;
      });
      setPreferredShift(updatedShiftTypeList);
    }
    setValue("noticePeriod", noticePeriodList?.filter((noticePeriod: any) => noticePeriod?.title === item?.noticePeriod).map(({ id, title }: any) => ({ value: id, label: title }))[0]);
    const qualificationSelectData = toggle === "recent" ? item?.qualification?.flatMap((qualification: any) => educationTypeList?.filter((list: any) => list?.title === qualification)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.qualification?.map(({ id, title }: any) => ({ value: id, label: title }));
    if (qualificationSelectData?.length) {
      setValue("qualification", qualificationSelectData);
      const updatedQualificationList = educationTypeList.map((list: any) => {
        const isSelected = qualificationSelectData.some((qualification: any) => qualification.label === list.title);
        if (isSelected) {
          return { ...list, isChecked: true };
        }
        return list;
      });
      setEducationTypeList(updatedQualificationList);
    }
    const genderSelectData = toggle === "recent" ? item?.gender?.flatMap((gender: any) => genderList?.filter((list: any) => list?.title === gender)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.gender?.map(({ id, title }: any) => ({ value: id, label: title }));
    if (genderSelectData?.length) {
      setValue("gender", genderSelectData);
      const updatedGenderList = genderList.map((list: any) => {
        const isSelected = genderSelectData.some((gender: any) => gender.label === list.title);
        if (isSelected) {
          return { ...list, isChecked: true };
        }
        return list;
      });
      setGenderList(updatedGenderList);
    }
    const maritalStatusSelectData = toggle === "recent" ? item?.maritalStatus?.flatMap((maritalStatus: any) => maritalStatusList?.filter((list: any) => list?.title === maritalStatus)).map(({ id, title }: any) => ({ value: id, label: title })) : item?.maritalStatus?.map(({ id, title }: any) => ({ value: id, label: title }));
    if (maritalStatusSelectData?.length) {
      setValue("maritalStatus", maritalStatusSelectData);
      const updatedMaritalStatusList = maritalStatusList.map((list: any) => {
        const isSelected = maritalStatusSelectData.some((maritalStatus: any) => maritalStatus.label === list.title);
        if (isSelected) {
          return { ...list, isChecked: true };
        }
        return list;
      });
      setMaritalStatusList(updatedMaritalStatusList);
    }
    setValue("candidateMinAge", salaryRange?.filter((age: any) => age?.id === item?.candidateMinAge).map(({ id, title }: any) => ({ value: id, label: title }))[0]);
    setValue("candidateMaxAge", salaryRange?.filter((age: any) => age?.id === item?.candidateMaxAge).map(({ id, title }: any) => ({ value: id, label: title }))[0]);
    setValue("isMobileNumberVerified", item?.isMobileNumberVerified);
    setValue("isEmailVerified", item?.isEmailVerified);
    setValue("verifyBGV", item?.verifyBGV);
    setValue("resumeAttach", item?.resumeAttach);
    if (item?.activeIn) {
      setValue("activeIn", item?.activeIn);
    }
  }
  return (
    <div className="grid grid-cols-12 gap-10 px-32 bg-[#F8FAFC] py-10">
      <div className="col-start-1 col-end-9">
        <div className="flex justify-start">
          <div className="flex justify-center items-center mr-5">
            <div className="bg-[#FEE] rounded-full w-10 h-10 flex justify-center items-center">
              <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}search-candidate-resume.svg`} alt="search-candidate-resume" />
            </div>
          </div>
          <div className="flex flex-col justify-start">
            <h1 className="font-bold text-2xl">Search Candidate Resumes</h1>
            <p className="text-[#64748B] font-normal text-sm">Use the fields below to filter your results</p>
          </div>
        </div>
        <div className="mt-10 w-full">
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal mb-2 inline-block">Keywords</label>
              <Controller
                name="keywords"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    isSearchable={true}
                    isMulti
                    className='text-sm w-full'
                    styles={customStylesForMulti}
                    options={keySkills?.map(
                      ({ id, title }: any) =>
                        ({ value: id, label: title } as any)
                    )}
                  />
                )}
              />
              <div className="mt-2 flex justify-start items-center">
                <input type="checkbox" />
                <label className="ml-1 text-sm text-[#334155] font-normal">checkbox to mark all keywords as mandatory</label>
              </div>
            </div>
            <div className="w-5/6 mb-7">
              <label className="text-sm text-[#334155] font-normal mb-2 inline-block">Experience</label>
              <div className="flex justify-start items-center">
                <Controller
                  name="minExperience"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      isSearchable={true}
                      className='text-sm w-full'
                      styles={customStylesForSingle}
                      placeholder="Min experience"
                      options={totalYearsExp?.map(({ id, title }: any) => ({ value: id, label: title }))}
                    />
                  )}
                />
                <span className="text-sm text-[#334155] font-norma inline-block mx-5">to</span>
                <Controller
                  name="maxExperience"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      isSearchable={true}
                      className='text-sm w-full'
                      styles={customStylesForSingle}
                      placeholder="Max experience"
                      options={totalYearsExp?.map(({ id, title }: any) => ({ value: id, label: title }))}
                    />
                  )}
                />
                <span className="text-sm text-[#334155] font-norma inline-block ml-5">Years</span>
              </div>
            </div>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal mb-2 inline-block">Current location of candidate</label>
              <Controller
                name="currentLocation"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    isSearchable={true}
                    isMulti
                    className='text-sm w-full'
                    styles={customStylesForMulti}
                    options={locationList?.map(
                      ({ id, title }: any) =>
                        ({ value: id, label: title } as any)
                    )}
                  />
                )}
              />
              <div className="mt-2 flex justify-start items-center">
                <input type="checkbox" />
                <label className="ml-1 text-sm text-[#334155] font-normal">one check box if candidate willing to relocate to these locations</label>
              </div>
            </div>
            <div className="w-5/6 mb-7">
              <label className="text-sm text-[#334155] font-normal mb-2 inline-block">Salary (annual)</label>
              <div className="flex justify-start items-center">
                <Controller
                  name="minSalary"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      isSearchable={true}
                      className='text-sm w-full'
                      styles={customStylesForSingle}
                      placeholder="Min salary"
                      options={salaryRange?.map(({ id, title }: any) => ({ value: id, label: title }))}
                    />
                  )}
                />
                <span className="text-sm text-[#334155] font-norma inline-block mx-5">to</span>
                <Controller
                  name="maxSalary"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      isSearchable={true}
                      className='text-sm w-full'
                      styles={customStylesForSingle}
                      placeholder="Max salary"
                      options={salaryRange?.map(({ id, title }: any) => ({ value: id, label: title }))}
                    />
                  )}
                />
                <span className="text-sm text-[#334155] font-norma inline-block ml-5">Lakhs</span>
              </div>
              <div className="mt-2 flex justify-start items-center">
                <input type="checkbox" />
                <label className="ml-1 text-sm text-[#334155] font-normal">include candidates who didn't mention the current salary</label>
              </div>
            </div>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal mb-2 inline-block">Preferred Location</label>
              <Controller
                name="preferredLocation"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    isSearchable={true}
                    isMulti
                    className='text-sm w-full'
                    styles={customStylesForMulti}
                    options={locationList?.map(
                      ({ id, title }: any) =>
                        ({ value: id, label: title } as any)
                    )}
                  />
                )}
              />
              <span className="text-sm text-[#334155] font-normal mt-2 inline-block">Available: {watch("preferredLocation") ? watch("preferredLocation")?.length : 0}/{locationList?.length}</span>
            </div>
            <h1 className="text-sm text-[#334155] font-bold mb-7">Employment Details</h1>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal mb-2 inline-block">Industry</label>
              <Controller
                name="industry"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    isSearchable={true}
                    isMulti
                    className='text-sm w-full'
                    styles={customStylesForMulti}
                    options={industryList?.map(
                      ({ id, title }: any) =>
                        ({ value: id, label: title } as any)
                    )}
                  />
                )}
              />
            </div>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal mb-2 inline-block">Department & Role</label>
              <div className="grid grid-cols-2 gap-5">
                <Controller
                  name="department"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      isSearchable={true}
                      className='text-sm w-full'
                      styles={customStylesForSingle}
                      placeholder="Add Department"
                      options={departmentList?.map(({ id, title }: any) => ({ value: id, label: title }))}
                    />
                  )}
                />
                <Controller
                  name="roleCategory"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      isSearchable={true}
                      className='text-sm w-full'
                      styles={customStylesForSingle}
                      placeholder="Add Role"
                      options={roleCategoryList?.filter(({ department }: any) => (watch("department")?.label == department))?.map(({ id, title }: any) => ({ value: id, label: title }))}
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal mb-2 inline-block">Designation</label>
              <Controller
                name="designation"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    isSearchable={true}
                    isMulti
                    className='text-sm w-full'
                    styles={customStylesForMulti}
                    options={jobTitleList?.filter(({ roleCategory }: any) => (watch("roleCategory")?.label == roleCategory))?.map(({ id, title }: any) => ({ value: id, label: title }))}
                  />
                )}
              />
            </div>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal inline-block">Employment type</label>
              <hr className="mt-2 mb-3" />
              <div className="flex justify-start items-center">
                {
                  employeeTypeList && employeeTypeList?.map((item: any, index: number) => (
                    <button key={index} className={!item.isChecked ? "py-2 px-3 rounded-lg border text-sm cursor-pointer mr-1" : "py-2 px-3 rounded-lg border border-[#6366F1] bg-[#EEF2FF] text-sm cursor-pointer mr-1"}
                      type="button"
                      onClick={() => {
                        setEmployeeTypeList(
                          employeeTypeList?.map((type: any) =>
                            type?.id === item?.id ? { ...type, isChecked: !type.isChecked } : type
                          )
                        );
                        if (!item.isChecked) {
                          appendEmpType(item);
                        } else {
                          removeEmpType(fieldsEmpType.findIndex(field => field.id === item.id));
                        }
                      }}
                    >{item?.title}</button>
                  ))
                }
              </div>
            </div>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal inline-block">Shift Type</label>
              <hr className="mt-2 mb-3" />
              <div className="flex justify-start items-center">
                {
                  preferredShift && preferredShift?.map((item: any, index: number) => (
                    <button key={index} className={!item.isChecked ? "py-2 px-3 rounded-lg border text-sm cursor-pointer mr-1" : "py-2 px-3 rounded-lg border border-[#6366F1] bg-[#EEF2FF] text-sm cursor-pointer mr-1"}
                      type="button"
                      onClick={() => {
                        setPreferredShift(
                          preferredShift?.map((type: any) =>
                            type?.id === item?.id ? { ...type, isChecked: !type.isChecked } : type
                          )
                        );
                        if (!item.isChecked) {
                          appendShiftType(item);
                        } else {
                          removeShiftType(fieldsShiftType.findIndex(field => field.id === item.id));
                        }
                      }}
                    >{item?.title}</button>
                  ))
                }
              </div>
            </div>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal mb-2 inline-block">Notice Period/ Availability to join</label>
              <Controller
                name="noticePeriod"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    isSearchable={true}
                    className='text-sm w-1/2'
                    styles={customStylesForSingle}
                    placeholder="Choose notice period"
                    options={noticePeriodList?.map(
                      ({ id, title }: any) =>
                        ({ value: id, label: title } as any)
                    )}
                  />
                )}
              />
            </div>
            <h1 className="text-sm text-[#334155] font-bold mb-7">Education Details</h1>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal inline-block">Qualification</label>
              <hr className="mt-2 mb-3" />
              <div className="flex justify-start items-center">
                {
                  educationTypeList && educationTypeList?.map((item: any, index: number) => (
                    <button key={index} className={!item.isChecked ? "py-2 px-3 rounded-lg border text-sm cursor-pointer mr-1" : "py-2 px-3 rounded-lg border border-[#6366F1] bg-[#EEF2FF] text-sm cursor-pointer mr-1"}
                      type="button"
                      onClick={() => {
                        setEducationTypeList(
                          educationTypeList?.map((type: any) =>
                            type?.id === item?.id ? { ...type, isChecked: !type.isChecked } : type
                          )
                        );
                        if (!item.isChecked) {
                          appendQualification(item);
                        } else {
                          removeQualification(fieldsQualification.findIndex(field => field.id === item.id));
                        }
                      }}
                    >{item?.title}</button>
                  ))
                }
              </div>
            </div>
            <h1 className="text-sm text-[#334155] font-bold mb-7">Diversity & additional details</h1>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal inline-block">Gender</label>
              <hr className="mt-2 mb-3" />
              <div className="flex justify-start items-center">
                {
                  genderList && genderList?.map((item: any, index: number) => (
                    <button key={index} className={!item.isChecked ? "py-2 px-3 rounded-lg border text-sm cursor-pointer mr-1" : "py-2 px-3 rounded-lg border border-[#6366F1] bg-[#EEF2FF] text-sm cursor-pointer mr-1"}
                      type="button"
                      onClick={() => {
                        setGenderList(
                          genderList?.map((type: any) =>
                            type?.id === item?.id ? { ...type, isChecked: !type.isChecked } : type
                          )
                        );
                        if (!item.isChecked) {
                          appendGender(item);
                        } else {
                          removeGender(fieldsGender.findIndex(field => field.id === item.id));
                        }
                      }}
                    >{item?.title}</button>
                  ))
                }
              </div>
            </div>
            <div className="mb-7 flex justify-start items-center">
              <input type="checkbox" />
              <label className="ml-1 text-sm text-[#334155] font-normal">Person with disability</label>
            </div>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal inline-block">Marital Status</label>
              <hr className="mt-2 mb-3" />
              <div className="flex justify-start items-center">
                {
                  maritalStatusList && maritalStatusList?.map((item: any, index: number) => (
                    <button key={index} className={!item.isChecked ? "py-2 px-3 rounded-lg border text-sm cursor-pointer mr-1" : "py-2 px-3 rounded-lg border border-[#6366F1] bg-[#EEF2FF] text-sm cursor-pointer mr-1"}
                      type="button"
                      onClick={() => {
                        setMaritalStatusList(
                          maritalStatusList?.map((type: any) =>
                            type?.id === item?.id ? { ...type, isChecked: !type.isChecked } : type
                          )
                        );
                        if (!item.isChecked) {
                          appendMaritalStatus(item);
                        } else {
                          removeMaritalStatus(fieldsMaritalStatus.findIndex(field => field.id === item.id));
                        }
                      }}
                    >{item?.title}</button>
                  ))
                }
              </div>
            </div>
            <div className="w-5/6 mb-7">
              <label className="text-sm text-[#334155] font-normal mb-2 inline-block">Candidate Age</label>
              <div className="flex justify-start items-center">
                <Controller
                  name="candidateMinAge"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      isSearchable={true}
                      className='text-sm w-full'
                      styles={customStylesForSingle}
                      placeholder="Min age"
                      options={salaryRange?.map(({ id, title }: any) => ({ value: id, label: title }))}
                    />
                  )}
                />
                <span className="text-sm text-[#334155] font-norma inline-block mx-5">to</span>
                <Controller
                  name="candidateMaxAge"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      isSearchable={true}
                      className='text-sm w-full'
                      styles={customStylesForSingle}
                      placeholder="Max age"
                      options={salaryRange?.map(({ id, title }: any) => ({ value: id, label: title }))}
                    />
                  )}
                />
                <span className="text-sm text-[#334155] font-norma inline-block ml-5">Years</span>
              </div>
            </div>
            <h1 className="text-sm text-[#334155] font-bold mb-7">Display Details</h1>
            <div className="w-full mb-7">
              <label className="text-sm text-[#334155] font-normal inline-block">Show only candidates with</label>
              <hr className="mt-2 mb-3" />
              <div className="flex justify-start items-center">
                <button
                  type="button"
                  className={`py-2 px-3 rounded-lg border mr-1 text-sm ${watch("isMobileNumberVerified") ? 'border-[#6366F1] bg-[#EEF2FF]' : ''}`}
                  onClick={() => setValue('isMobileNumberVerified', !watch("isMobileNumberVerified"))}
                >
                  Verified mobile number
                </button>
                <button
                  type="button"
                  className={`py-2 px-3 rounded-lg border mr-1 text-sm ${watch("isEmailVerified") ? 'border-[#6366F1] bg-[#EEF2FF]' : ''}`}
                  onClick={() => setValue('isEmailVerified', !watch("isEmailVerified"))}
                >
                  Verified email ID
                </button>
                <button
                  type="button"
                  className={`py-2 px-3 rounded-lg border mr-1 text-sm ${watch("verifyBGV") ? 'border-[#6366F1] bg-[#EEF2FF]' : ''}`}
                  onClick={() => setValue('verifyBGV', !watch("verifyBGV"))}
                >
                  Verified BGV
                </button>
                <button
                  type="button"
                  className={`py-2 px-3 rounded-lg border mr-1 text-sm ${watch("resumeAttach") ? 'border-[#6366F1] bg-[#EEF2FF]' : ''}`}
                  onClick={() => setValue('resumeAttach', !watch("resumeAttach"))}
                >
                  Attached resume
                </button>
              </div>
            </div>
            <div className="w-full mb-10">
              <label className="text-sm text-[#334155] font-normal inline-block">Show only candidates with</label>
              <hr className="mt-2 mb-3" />
              <div className="grid grid-cols-4 gap-10">
                <div className="py-3 px-5 rounded-lg border bg-[#F8FAFC] text-sm flex justify-evenly items-center">
                  <Controller
                    name="activeIn"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          type="radio"
                          value="6 months+"
                          checked={field.value === "6 months+"}
                          onChange={() => setValue("activeIn", "6 months+")}
                        />
                        <label className="leading-5 text-sm">6 months+</label>
                      </>
                    )}
                  />
                </div>
                <div className="py-3 px-5 rounded-lg border bg-[#F8FAFC] text-sm flex justify-evenly items-center">
                  <Controller
                    name="activeIn"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          type="radio"
                          value="1 years+"
                          checked={field.value === "1 years+"}
                          onChange={() => setValue("activeIn", "1 years+")}
                        />
                        <label className="leading-5 text-sm">1 years+</label>
                      </>
                    )}
                  />
                </div>
                <div className="py-3 px-5 rounded-lg border bg-[#F8FAFC] text-sm flex justify-evenly items-center">
                  <Controller
                    name="activeIn"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          type="radio"
                          value="3 years+"
                          checked={field.value === "3 years+"}
                          onChange={() => setValue("activeIn", "3 years+")}
                        />
                        <label className="leading-5 text-sm">3 years+</label>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-10">
              <button name="SaveAsDraft"
                className=" h-14 py-3 bg-[#EEF2FF] text-[#312E81] rounded-lg w-full"
                onClick={() => setActionType("Save")}
              >
                Save search
              </button>
              <button name="SaveAsDraft"
                className=" h-14 py-3 bg-[#4F46E5] text-[#FFF] rounded-lg w-full"
                type="submit"
                onClick={() => setActionType("Search")}
              >Search</button>
            </div>
          </form>
        </div>
      </div>
      <div className="col-start-9 col-end-13">
        <div className="mb-5">
          <h1 className="font-bold text-2xl">Recent Searches</h1>
          <div className="p-5 bg-[#FFF] mt-5 rounded-xl">
            {searchResumeResultRecent?.length ? searchResumeResultRecent?.slice(0, 5)?.map((item: any, index: number) => (
              <div className="mb-6" key={index}>
                <h2 className="text-[#0F172A] font-bold text-base overflow-hidden whitespace-nowrap text-ellipsis">{item?.designation?.map((item: any, index: number) => (
                  <span key={index}>{item}</span>
                ))}</h2>
                <p className="text-[#64748B] font-normal text-base">
                  {item?.keywords?.map((item: any, index: number) => (
                    <span key={index}>{item}</span>
                  ))}
                </p>
                <div className="flex justify-between items-center text-[#6366F1] font-normal text-base">
                  <button onClick={() => handleFillSearch(item, "recent")}>Fill this search</button>
                  <button onClick={() => handleShowProfile(item, "recent")}>Show profiles</button>
                </div>
              </div>
            )) :
              <NoRecords />
            }
            <div className="flex justify-end items-center">
              <span className="text-[#475569] text-sm font-medium">{searchResumeResultRecent?.length > 5 && `+${searchResumeResultRecent?.length - 5} results`}</span>
            </div>
          </div>
        </div>
        <div>
          <h1 className="font-bold text-2xl">Saved Searches</h1>
          <div className="p-5 bg-[#FFF] mt-5 rounded-xl">
            {searchResumeResult?.length ? searchResumeResult?.slice(0, 5)?.map((item: any, index: number) => (
              <div className="mb-6" key={index}>
                <h2 className="text-[#0F172A] font-bold text-base overflow-hidden whitespace-nowrap text-ellipsis">{item?.designation?.map((item: any, index: number) => (
                  <span key={index}>{item?.title}</span>
                ))}</h2>
                <p className="text-[#64748B] font-normal text-base">
                  {item?.keywords?.map((item: any, index: number) => (
                    <span key={index}>{item?.title}</span>
                  ))}
                </p>
                <div className="flex justify-between items-center text-[#6366F1] font-normal text-base">
                  <button onClick={() => handleFillSearch(item, "saved")}>Fill this search</button>
                  <button onClick={() => handleShowProfile(item, "saved")}>Show profiles</button>
                </div>
              </div>
            )) :
              <NoRecords />
            }
            <div className="flex justify-end items-center">
              <span className="text-[#475569] text-sm font-medium">{searchResumeResult?.length > 5 && `+${searchResumeResult?.length - 5} results`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResumesForm;
