import { Fragment} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { RxCross1} from 'react-icons/rx';
import { BulkDepartmentsFilter } from './FiltersDepartment';


const FiltersModal = ({ isOpen, setIsOpen, checkItems, setCheckItems, filterIds, setFilterIds, allFilterData, setAllFilterData, department, setDepartment, navigateFilterOption, setNavigateFilterOption }: any) => {
    const closeDialog = () => {
        setIsOpen(false);
        setFilterIds((preValue: any) => {
            return {
                ...preValue,
                departmentIds: allFilterData?.department
            }
        });
        setCheckItems((preValue: any) => {
            return {
                ...preValue,
                department: department,
            }
        });
    };

    const handleSubmit = () => {
        setAllFilterData((preValue: any) => {
            return {
                ...preValue,
                department: filterIds?.departmentIds,
            }
        });
        setDepartment(checkItems?.department);
        setIsOpen(false);
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeDialog}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                <Dialog.Title className="text-lg font-medium text-gray-900 text-right flex justify-between items-center p-5">
                                    <div className="flex justify-start items-center">
                                        <h1 className="font-bold leading-none mr-2">Filters</h1>
                                    </div>
                                    <button
                                        onClick={closeDialog}
                                        type="button"
                                        className="outline-0"
                                    >
                                        <RxCross1 />
                                    </button>
                                </Dialog.Title>
                                <div className="grid grid-cols-12 border-y border-[#E0E7FF]">
                                    <div className="col-start-1 col-end-4 border-r border-[#E0E7FF]">
                                        <ul>
                                            <li
                                                className={navigateFilterOption !== "Department" ?
                                                    "px-5 py-2 cursor-pointer flex justify-between items-center"
                                                    : "bg-[#F1F5F9] px-5 py-3 cursor-pointer flex justify-between items-center"}
                                                onClick={() => setNavigateFilterOption("Department")}
                                            >
                                                <span>Department</span>
                                                {filterIds?.departmentIds?.length !== 0 &&
                                                    <span className="bg-[#F1F5F9] rounded-full w-8 h-8 flex justify-center items-center">
                                                        {filterIds?.departmentIds?.length}
                                                    </span>}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-start-4 col-end-13">
                                        {navigateFilterOption === "Department" &&
                                            <BulkDepartmentsFilter checkItems={checkItems} setCheckItems={setCheckItems} setFilterIds={setFilterIds} filterIds={filterIds} />
                                        }
                                    </div>
                                </div>
                                <div className="p-5 float-right">
                                    <button className="text-[#312E81] bg-[#EEF2FF] px-8 py-2 rounded-lg text-sm mr-5" onClick={closeDialog}>Close</button>
                                    <button className="text-white bg-[#4F46E5] px-8 py-2 rounded-lg text-sm" onClick={handleSubmit} >Apply</button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition >
    )
}

export default FiltersModal;