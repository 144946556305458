import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../..';
import { setKeySkills, setSearchKeySkills, setFilterKeySkills } from '../../store/reducers/jobs/GetFilterJobs';
import { getKeySkillsList } from '../utils/utils';

const CategoryItem = ({ item }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { keySkills } = useAppSelector((state) => state.getFilterJobs);

  useEffect(() => {
    (async () => {
      const KeySkillsList = await getKeySkillsList();
      if (Object.keys(KeySkillsList)?.length) {
        dispatch(setKeySkills(KeySkillsList));
      }
    })();
  }, []);

  const handleKeySkillsJobs = (id: number) => {
    navigate("/allJobs");
    dispatch(setKeySkills(
      keySkills?.map((item: any) =>
        item?.id === id ? { ...item, isChecked: true } : item
      )
    ));
    dispatch(setSearchKeySkills(false));
    dispatch(setFilterKeySkills(id));
  }

  return (
    <div className="p-5 shadow-sm rounded-lg bg-[#FFF] flex justify-between items-center cursor-pointer" key={item?.id} onClick={() => handleKeySkillsJobs(Number(item?.id))}>
      <h1 className="text-base 2xl:text-lg font-semibold">{item?.title}</h1>
      <span className="text-xs 2xl:text-sm m-0 px-3 py-2 rounded-full bg-[#F1F5F9]">{item?.count}</span>
    </div>
  )
}

export default CategoryItem