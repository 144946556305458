import { Fragment, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { Combobox, Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

const people = [
    { id: 1, name: 'Durward Reynolds' },
    { id: 2, name: 'Kenton Towne' },
    { id: 3, name: 'Therese Wunsch' },
    { id: 4, name: 'Benedict Kessler' },
    { id: 5, name: 'Katelyn Rohan' },
]

const ManageFoldersFilters = () => {
    // filter search
    const [query, setQuery] = useState('');
    const filteredPeople =
        query === ''
            ? people
            : people.filter((person) => {
                return person.name.toLowerCase().includes(query.toLowerCase())
            });
    return (
        <div className="py-10 pr-5 w-full grid grid-cols-1 gap-5">
            <div>
                <Disclosure>
                    {({
                        open
                    }) => <>
                            <Disclosure.Button className="flex w-full justify-between items-center">
                                <label className="text-[#475569] font-bold text-base">Filters</label>
                                <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                            </Disclosure.Button>
                            <Disclosure.Panel className="mt-5">
                                <Combobox>
                                    <div className="relative">
                                        <div className="relative flex items-center justify-between w-full border border-[#E0E7FF] rounded-lg overflow-hidden focus:border-none">
                                            <div className="grid place-items-center h-full w-12 text-gray-300">
                                                <BiSearch className="h-5 w-5" />
                                            </div>
                                            <Combobox.Input className="w-full border-none py-2 pr-10 text-sm 2xl:text-base xl:text-sm leading-5 text-gray-900 focus:ring-0 outline-none" placeholder="Search something.." onChange={event => setQuery(event.target.value)} />
                                        </div>
                                        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery('')}>
                                            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base xl:text-base 2xl:text-xl shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                                {filteredPeople.map((item: any) => <Combobox.Option key={item?.id} className={({
                                                    active
                                                }) => `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'}`} value={item}>
                                                    <span className="font-normal w-full h-full">{item.name}</span>
                                                </Combobox.Option>)}
                                            </Combobox.Options>
                                        </Transition>
                                    </div>
                                </Combobox>
                            </Disclosure.Panel>
                        </>}
                </Disclosure>
            </div>
            <hr />
            <div>
                <Disclosure>
                    {({
                        open
                    }) => <>
                            <Disclosure.Button className="flex w-full justify-between items-center">
                                <label className="text-[#475569] font-bold text-base">Created date</label>
                                <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                            </Disclosure.Button>
                            <Disclosure.Panel className="mt-5">
                                <div className="text-[#475569] mb-2 flex justify-start items-center">
                                    <input type="checkbox" />
                                    <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Last 7 days</label>
                                </div>
                                <div className="text-[#475569] mb-2 flex justify-start items-center">
                                    <input type="checkbox" />
                                    <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Last 14 days</label>
                                </div>
                                <div className="text-[#475569] mb-2 flex justify-start items-center">
                                    <input type="checkbox" />
                                    <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Last 30 days</label>
                                </div>
                                <div className="text-[#475569] mb-2 flex justify-start items-center">
                                    <input type="checkbox" />
                                    <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Custom date range</label>
                                </div>
                            </Disclosure.Panel>
                        </>}
                </Disclosure>
            </div>
            <hr />
        </div>
    )
}

export default ManageFoldersFilters;