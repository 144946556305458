import { Combobox, Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { Tab } from '@headlessui/react';
import ReactPaginateItems from '../../../commonComponents/ReactPaginate';
import NoRecords from '../../../commonComponents/NoRecords';

const people = [
    { id: 1, name: 'Durward Reynolds' },
    { id: 2, name: 'Kenton Towne' },
    { id: 3, name: 'Therese Wunsch' },
    { id: 4, name: 'Benedict Kessler' },
    { id: 5, name: 'Katelyn Rohan' },
]

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const getCategoryDisplayName = (category: any) => {
    switch (category) {
        case "My_saved_searches":
            return "My saved searches";
        case "Searches_shared_with_me":
            return "Searches shared with me";
        case "My_recent_searches":
            return "My recent searches";
        default:
            return "";
    }
};

const ManageSearch = () => {
    const [query, setQuery] = useState('');

    let [categories] = useState({
        My_saved_searches: [
            {
                id: 1,
                title: 'Does drinking coffee make you smarter?',
                date: '5h ago',
            },
            {
                id: 2,
                title: "So you've bought coffee... now what?",
                date: '2h ago',
            },
            {
                id: 3,
                title: 'Does drinking coffee make you smarter?',
                date: '5h ago',
            },
            {
                id: 4,
                title: "So you've bought coffee... now what?",
                date: '2h ago',
            },
            {
                id: 5,
                title: 'Does drinking coffee make you smarter?',
                date: '5h ago',
            },
            {
                id: 6,
                title: "So you've bought coffee... now what?",
                date: '2h ago',
            },
            {
                id: 7,
                title: 'Does drinking coffee make you smarter?',
                date: '5h ago',
            },
            {
                id: 8,
                title: "So you've bought coffee... now what?",
                date: '2h ago',
            },
            {
                id: 9,
                title: 'Does drinking coffee make you smarter?',
                date: '5h ago',
            },
            {
                id: 10,
                title: "So you've bought coffee... now what?",
                date: '2h ago',
            },
            {
                id: 11,
                title: "So you've bought coffee... now what?",
                date: '2h ago',
            },
        ],
        Searches_shared_with_me: [
            {
                id: 1,
                title: 'Is tech making coffee better or worse?',
                date: 'Jan 7',
            },
            {
                id: 2,
                title: 'The most innovative things happening in coffee',
                date: 'Mar 19',
            },
        ],
        My_recent_searches: [
            {
                id: 1,
                title: 'Ask Me Anything: 10 answers to your questions about coffee',
                date: '2d ago',
                commentCount: 9,
                shareCount: 5,
            },
            {
                id: 2,
                title: "The worst advice we've ever heard about coffee",
                date: '4d ago',
                commentCount: 1,
                shareCount: 2,
            },
        ],
    });

    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage: number = 10;
    const endOffset = itemOffset + itemsPerPage;

    const filteredPeople =
        query === ''
            ? people
            : people.filter((person) => {
                return person.name.toLowerCase().includes(query.toLowerCase())
            });

    return (
        <>
            <div className="h-[10%] w-full"></div>
            <div className="grid grid-cols-12 gap-10 px-32 bg-[#F8FAFC]">
                <div className="col-start-1 col-end-4 relative">
                    <div className="py-10 pr-5 w-full grid grid-cols-1 gap-5">
                        <div>
                            <Disclosure>
                                {({
                                    open
                                }) => <>
                                        <Disclosure.Button className="flex w-full justify-between items-center">
                                            <label className="text-[#475569] font-bold text-base">Filters</label>
                                            <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="mt-5">
                                            <Combobox>
                                                <div className="relative">
                                                    <div className="relative flex items-center justify-between w-full border border-[#E0E7FF] rounded-lg overflow-hidden focus:border-none">
                                                        <div className="grid place-items-center h-full w-12 text-gray-300">
                                                            <BiSearch className="h-5 w-5" />
                                                        </div>
                                                        <Combobox.Input
                                                            className="w-full border-none py-2 pr-10 text-sm 2xl:text-base xl:text-sm leading-5 text-gray-900 focus:ring-0 outline-none"
                                                            placeholder="Search something.."
                                                            onChange={(event) => setQuery(event.target.value)}
                                                        />
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                        afterLeave={() => setQuery('')}
                                                    >
                                                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base xl:text-base 2xl:text-xl shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                                            {filteredPeople.map((item: any) => (
                                                                <Combobox.Option
                                                                    key={item?.id}
                                                                    className={({ active }) =>
                                                                        `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                        }`
                                                                    }
                                                                    value={item}
                                                                >
                                                                    <span className="font-normal w-full h-full">{item.name}</span>
                                                                </Combobox.Option>
                                                            ))}
                                                        </Combobox.Options>
                                                    </Transition>
                                                </div>
                                            </Combobox>
                                        </Disclosure.Panel>
                                    </>}
                            </Disclosure>
                        </div>
                        <hr />
                        <div>
                            <Disclosure>
                                {({
                                    open
                                }) => <>
                                        <Disclosure.Button className="flex w-full justify-between items-center">
                                            <label className="text-[#475569] font-bold text-base">Created date</label>
                                            <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="mt-5">
                                            <div className="text-[#475569] mb-2 flex justify-start items-center">
                                                <input type="checkbox" />
                                                <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Last 7 days</label>
                                            </div>
                                            <div className="text-[#475569] mb-2 flex justify-start items-center">
                                                <input type="checkbox" />
                                                <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Last 14 days</label>
                                            </div>
                                            <div className="text-[#475569] mb-2 flex justify-start items-center">
                                                <input type="checkbox" />
                                                <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Last 30 days</label>
                                            </div>
                                            <div className="text-[#475569] mb-2 flex justify-start items-center">
                                                <input type="checkbox" />
                                                <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Custom date range</label>
                                            </div>
                                        </Disclosure.Panel>
                                    </>}
                            </Disclosure>
                        </div>
                        <hr />
                    </div>
                    <div className="absolute top-0 right-0 h-full border border-[]"></div>
                </div>
                <div className="col-start-4 col-end-13">
                    <div className="py-10 w-full grid grid-cols-1 gap-8">
                        <div className="flex justify-between items-center">
                            <div className="">
                                <h1 className="text-black font-bold text-2xl">Manage Search</h1>
                                <p className="text-[#64748B] text-sm">Showing list of all the jobs which you have posted</p>
                            </div>
                            <button className="bg-[#4F46E5] shadow-sm rounded-lg py-2 pl-6 pr-3 text-[#FFF] font-medium text-xl">Search Resdx</button>
                        </div>
                        <div className="bg-[#FFF] rounded-xl px-7 flex justify-between items-center h-12">
                            <div className="text-[#475569] flex justify-start items-center">
                                <input type="checkbox" />
                                <label className="ml-2 text-sm overflow-hidden inline-block whitespace-nowrap text-ellipsis">Select all</label>
                            </div>
                            <div className="flex justify-end items-center">
                                <span className="text-[#818CF8] text-base font-medium mr-2">Sort by:</span>
                                <div className="flex justify-center items-center mr-2">
                                    <ChevronUpIcon className="h-5 w-5 text-gray-600 rotate-180 transform mr-1" />
                                    <span>name</span>
                                </div>
                                <span>Folders</span>
                                <div className="flex justify-center items-center ml-2">
                                    <ChevronUpIcon className="h-5 w-5 text-gray-600 rotate-180 transform mr-1" />
                                    <span>40</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Tab.Group>
                                <Tab.List className="flex">
                                    {Object.keys(categories).map((category) => (
                                        <Tab
                                            key={category}
                                            className={({ selected }) =>
                                                classNames('w-full outline-none leading-10 font-bold border-b', selected ? 'border-[#4F46E5] text-[#4F46E5]' : 'border-[#E0E7FF] text-[#64748B]')
                                            }
                                        >
                                            {getCategoryDisplayName(category)}
                                        </Tab>
                                    ))}
                                </Tab.List>
                                <Tab.Panels className="w-full">
                                    {Object.values(categories).map((posts, idx) => {
                                        const currentItems = posts?.slice(itemOffset, endOffset);
                                        return (
                                            <Tab.Panel key={idx}>
                                                {currentItems !== undefined && currentItems?.length !== 0 ?
                                                    <>
                                                        <div className="bg-white overflow-hidden w-full">
                                                            <table className="w-full table-auto">
                                                                <tbody>
                                                                    {currentItems?.length !== 0 ? currentItems?.map((post: any, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr className={currentItems?.length - 1 !== index ? "border-b-2 border-[#F1F5F9]" : ""} >
                                                                                    <td className="py-3 px-6 text-left">
                                                                                        <input type="checkbox" />
                                                                                    </td>
                                                                                    <td className="py-3 px-6 text-left">
                                                                                        {post?.title}
                                                                                    </td>
                                                                                    <td className="py-3 px-6 text-left">
                                                                                        {post?.date}
                                                                                    </td>
                                                                                    <td className="py-3 px-6 text-left" >
                                                                                        {post?.commentCount}
                                                                                    </td>
                                                                                    <td className="py-3 px-6 text-left">
                                                                                        {post?.shareCount}
                                                                                    </td>
                                                                                </tr >

                                                                            </>
                                                                        )
                                                                    })
                                                                        :
                                                                        <tr>
                                                                            <td colSpan={5} className="py-3 px-6">
                                                                                <h1 className="text-center">No record</h1>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <ReactPaginateItems itemsPerPage={itemsPerPage} items={posts} itemOffset={itemOffset} setItemOffset={setItemOffset} />
                                                    </>
                                                    : <NoRecords />
                                                }
                                            </Tab.Panel>
                                        )
                                    })}
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageSearch;