import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../..';
import CompanyCard from './CompanyCard';
import { getAllCompanies, clearGetAllCompaniesSlice, setDepartment, setFilterDepartment, setFilterLocation, setLocation, setCompanyType, setFilterCompanyType, setIndustry, setFilterIndustry, setCompany, setFilterCompany, clearIndividual, setNavigateFilterOption, clearAll } from '../../../store/reducers/companies/getAllCompanies';
import { CompanyDepartmentFilter } from './FilterByCompanyDepartment';
import { scrollToTop } from '../../utils/utils';
import FiltersModal from '../Companies/FiltersModal';
import { CompanyTypeFilter } from './FilterByCompanyType';
import { CompanyLocationFilter } from './FilterByCompanyLocation';
import { IndustryFilter } from './FilterByIndustry';
import { CompanyFilter } from './FilterByCompany';
import { RxCross2 } from 'react-icons/rx';
import TopCompanies from './TopCompanies';

const AllCompanies = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { success, allCompanies, loading, department, filtersData, location, companyType, industry, company, allFilterIds, toggleFilter } = useAppSelector((state) => state.getAllCompanies);
    const [page, setPage] = useState(1);
    const [toggleDispach, setToggleDispach] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [filtersCount, setFiltersCount] = useState(0);
    const [companyCard, setCompanyCard] = useState<any>([]);

    useEffect(() => {
        window.addEventListener("scroll", handelInfiniteScroll);
        return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }, []);

    useEffect(() => {
        let filtersCount = 0;
        if (filtersData?.department?.length) {
            filtersCount += filtersData?.department?.length
        }
        if (filtersData?.location?.length) {
            filtersCount += filtersData?.location?.length
        }
        if (filtersData?.industry?.length) {
            filtersCount += filtersData?.industry?.length
        }
        if (filtersData?.companyType?.length) {
            filtersCount += filtersData?.companyType?.length
        }
        if (filtersData?.company?.length) {
            filtersCount += filtersData?.company?.length
        }
        setFiltersCount(filtersCount);
    }, [filtersData]);

    useEffect(() => {
        if (toggleDispach) {
            if ((filtersData?.department !== undefined && filtersData?.department?.length !== 0) || (filtersData?.location !== undefined && filtersData?.location?.length !== 0) || (filtersData?.companyType !== undefined && filtersData?.companyType?.length !== 0) || (filtersData?.industry !== undefined && filtersData?.industry?.length !== 0) || (filtersData?.company !== undefined && filtersData?.company?.length !== 0)) {
                dispatch(getAllCompanies({ page, data: filtersData }));
            } else {
                dispatch(getAllCompanies({ page }));
            }
        }
    }, [dispatch, page, filtersData, toggleDispach, toggleFilter]);

    useEffect(() => {
        if (success) {
            if (allCompanies.length !== 0) {
                setCompanyCard((prev: any) => [...prev, ...allCompanies]);
            } else {
                setToggleDispach(false);
            }
            dispatch(clearGetAllCompaniesSlice());
        }
    }, [success])

    const handelInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                setPage((prev) => prev + 1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onClickCompanyCard = (companyId: any) => {
        window.open(`/allCompanies/companyDescription/${companyId}`, '_blank');
    }

    const handleViewAll = () => {
        setIsOpen(true);
        dispatch(setNavigateFilterOption("CompanyType"));
    }

    const resetPageState = () => {
        scrollToTop();
        setPage(1);
        setCompanyCard([]);
        setToggleDispach(true);
    };

    const handleClearAll = () => {
        resetPageState()
        dispatch(clearAll());
    }

    const handleDepartmentCheckbox = (data: any) => {
        resetPageState()
        dispatch(setDepartment(
            department?.map((item: any) =>
                item?.id === data?.id ? { ...item, isChecked: !item.isChecked } : item
            )
        ));
        if (data?.isChecked === undefined || data?.isChecked === false) {
            dispatch(setFilterDepartment(data?.id));
        } else {
            dispatch(setFilterDepartment({ filterDepartment: data?.id }));
        }
    };

    const handleLocationCheckbox = (data: any) => {
        resetPageState()
        dispatch(setLocation(
            location?.map((item: any) =>
                item?.id === data?.id ? { ...item, isChecked: !item.isChecked } : item
            )
        ))
        if (data?.isChecked === undefined || data?.isChecked === false) {
            dispatch(setFilterLocation(data?.id));
        } else {
            dispatch(setFilterLocation({ filterLocation: data?.id }));
        }
    };

    const handleCompanyTypeCheckbox = (data: any) => {
        resetPageState()
        dispatch(setCompanyType(
            companyType?.map((item: any) =>
                item?.id === data?.id ? { ...item, isChecked: !item.isChecked } : item
            )
        ))
        if (data?.isChecked === undefined || data?.isChecked === false) {
            dispatch(setFilterCompanyType(data?.id));
        } else {
            dispatch(setFilterCompanyType({ filterCompanyType: data?.id }));
        }
    };

    const handleIndustryCheckbox = (data: any) => {
        resetPageState()
        dispatch(setIndustry(
            industry?.map((item: any) =>
                item?.id === data?.id ? { ...item, isChecked: !item.isChecked } : item
            )
        ))
        if (data?.isChecked === undefined || data?.isChecked === false) {
            dispatch(setFilterIndustry(data?.id));
        } else {
            dispatch(setFilterIndustry({ filterIndustry: data?.id }));
        }
    };
    const handleCompanyCheckbox = (data: any) => {
        resetPageState()
        dispatch(setCompany(
            company?.map((item: any) =>
                item?.id === data?.id ? { ...item, isChecked: !item.isChecked } : item
            )
        ))
        if (data?.isChecked === undefined || data?.isChecked === false) {
            dispatch(setFilterCompany(data?.id));
        } else {
            dispatch(setFilterCompany({ filterCompany: data?.id }));
        }
    };

    return (
        <>
            <div className="h-[10%] w-full"></div>
            <div className="grid grid-cols-12 gap-10 px-32 bg-[#F8FAFC] py-6">
                <div className="col-start-1 col-end-4">
                    <div className="bg-[#FFF] rounded-xl p-4 sticky top-[13%]">
                        <div className='bg-[#4F46E5] py-7 px-2'>
                            <div className="flex justify-between items-center">
                                <div>
                                    <div className="flex items-center space-x-1">
                                        <svg
                                            className="h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path d="M12.9167 20H10.0833C9.70761 20 9.34728 19.8507 9.0816 19.5851C8.81592 19.3194 8.66667 18.9591 8.66667 18.5833V13.2071L3.41792 7.95833C3.15188 7.69388 3.00158 7.3347 3 6.95958V4.41667C3 4.04094 3.14926 3.68061 3.41493 3.41493C3.68061 3.14926 4.04094 3 4.41667 3H18.5833C18.9591 3 19.3194 3.14926 19.5851 3.41493C19.8507 3.68061 20 4.04094 20 4.41667V6.95958C19.9984 7.3347 19.8481 7.69388 19.5821 7.95833L14.3333 13.2071V18.5833C14.3333 18.9591 14.1841 19.3194 13.9184 19.5851C13.6527 19.8507 13.2924 20 12.9167 20ZM4.41667 4.41667V6.95958L10.0833 12.6263V18.5833H12.9167V12.6263L18.5833 6.95958V4.41667H4.41667Z" fill="white" />
                                        </svg>
                                        <span className="text-[#FFFFFF] font-bold mr-2">Filters</span>
                                        <span className={`w-9 h-6 rounded-3xl px-4 py-0.4 text-[#000000] font-bold flex justify-center items-center bg-[#F8FAFC] ${filtersCount > 0 ? '' : 'hidden'}`}>{filtersCount !== 0 && filtersCount}</span>
                                    </div>
                                </div>
                                {
                                    filtersCount !== 0 &&
                                    <button className=" border-b border-[#475569] text-[#FFFFFF]" onClick={handleClearAll}>Clear all</button>
                                }
                            </div>

                            <div className="mt-5 flex flex-wrap justify-start items-center gap-2">
                                {filtersData?.department?.map((item: any) => {
                                    const departmentFilter = department?.filter((departmentItem: any) => departmentItem?.id === item);
                                    return (
                                        <span className="bg-[#F1F5F9] px-3 py-1.5 rounded-lg flex justify-start items-center text-xs">
                                            <span className="mr-1">{departmentFilter[0]?.title}</span>
                                            <span className="cursor-pointer"
                                                onClick={() => {
                                                    resetPageState()
                                                    dispatch(clearIndividual({ department: departmentFilter[0]?.id }))
                                                }}>
                                                <RxCross2 />
                                            </span>
                                        </span>
                                    )
                                })}
                                {filtersData?.location?.map((item: any) => {
                                    const locationFilter = location?.filter((locationItem: any) => locationItem?.id === item);
                                    return (
                                        <span className="bg-[#F1F5F9] px-3 py-1.5 rounded-lg flex justify-start items-center text-xs">
                                            <span className="mr-1">{locationFilter[0]?.title}</span>
                                            <span className="cursor-pointer"
                                                onClick={() => {
                                                    resetPageState()
                                                    dispatch(clearIndividual({ location: locationFilter[0]?.id }))
                                                }}>
                                                <RxCross2 />
                                            </span>
                                        </span>
                                    )
                                })}
                                {filtersData?.company?.map((item: any) => {
                                    const companyFilter = company?.filter((companyItem: any) => companyItem?.id === item);
                                    return (
                                        <span className="bg-[#F1F5F9] px-3 py-1.5 rounded-lg flex justify-start items-center text-xs">
                                            <span className="mr-1">{companyFilter[0]?.title}</span>
                                            <span className="cursor-pointer"
                                                onClick={() => {
                                                    resetPageState()
                                                    dispatch(clearIndividual({ company: companyFilter[0]?.id }))
                                                }}>
                                                <RxCross2 />
                                            </span>
                                        </span>
                                    )
                                })}
                                {filtersData?.companyType?.map((item: any) => {
                                    const companyTypeFilter = companyType?.filter((companyTypeItem: any) => companyTypeItem?.id === item);
                                    return (
                                        <span className="bg-[#F1F5F9] px-3 py-1.5 rounded-lg flex justify-start items-center text-xs">
                                            <span className="mr-1">{companyTypeFilter[0]?.title}</span>
                                            <span className="cursor-pointer"
                                                onClick={() => {
                                                    resetPageState()
                                                    dispatch(clearIndividual({ companyType: companyTypeFilter[0]?.id }))
                                                }}>
                                                <RxCross2 />
                                            </span>
                                        </span>
                                    )
                                })}
                                {filtersData?.industry?.map((item: any) => {
                                    const industryFIlter = industry?.filter((industryItem: any) => industryItem?.id === item);
                                    return (
                                        <span className="bg-[#F1F5F9] px-3 py-1.5 rounded-lg flex justify-start items-center text-xs">
                                            <span className="mr-1">{industryFIlter[0]?.title}</span>
                                            <span className="cursor-pointer"
                                                onClick={() => {
                                                    resetPageState()
                                                    dispatch(clearIndividual({ industry: industryFIlter[0]?.id }))
                                                }}>
                                                <RxCross2 />
                                            </span>
                                        </span>
                                    )
                                })}
                                {filtersCount >= 4 &&
                                    <button className=" border-b border-[#475569] text-[#475569] text-xs" onClick={handleViewAll}>All</button>
                                }
                            </div>
                        </div>

                        <hr className="bg-[#E0E7FF] my-0.5" />
                        <CompanyTypeFilter
                            handleCompanyTypeCheckbox={handleCompanyTypeCheckbox}
                            setIsOpen={setIsOpen}
                        />

                        <hr className="bg-[#E0E7FF] my-5" />
                        <CompanyLocationFilter
                            handleLocationCheckbox={handleLocationCheckbox}
                            setIsOpen={setIsOpen}
                        />
                        <hr className="bg-[#E0E7FF] my-5" />
                        <IndustryFilter
                            handleIndustryCheckbox={handleIndustryCheckbox}
                            setIsOpen={setIsOpen}
                        />
                        <hr className="bg-[#E0E7FF] my-5" />
                        <CompanyDepartmentFilter
                            handleDepartmentCheckbox={handleDepartmentCheckbox}
                            setIsOpen={setIsOpen}
                        />
                        <hr className="bg-[#E0E7FF] my-5" />
                        <CompanyFilter
                            handleCompanyCheckbox={handleCompanyCheckbox}
                            setIsOpen={setIsOpen}
                        />
                    </div>
                </div>
                <div className="col-start-4 col-end-11">
                    <CompanyCard onClickCompanyCard={onClickCompanyCard} companyCard={companyCard} loading={loading} />
                </div>
                <div className="col-start-11 col-end-13">
                    <div className="bg-[#F1F5F9] rounded-xl w-full p-5 sticky top-[13%]">
                        <h1 className="leading-none mb-3">Top Companies</h1>
                        <TopCompanies />
                    </div>
                </div>
            </div >
            <FiltersModal isOpen={isOpen} setIsOpen={setIsOpen} setToggleDispach={setToggleDispach} setCompanyCard={setCompanyCard} setPage={setPage} />
        </>
    )
}

export default AllCompanies