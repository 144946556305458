import { useEffect, useState } from 'react';
import Modal from '../../../commonComponents/Modal';
import { AiOutlineDelete } from "react-icons/ai";
import { LuFolderClosed } from "react-icons/lu";
import { useAppDispatch, useAppSelector } from '../../../..';
import { deleteFolder } from '../../../../store/reducers/recruiter/manageFolder/deleteFolder';
import { format, parseISO } from 'date-fns';

const DeleteFolder = ({ post }: any) => {
    const dispatch = useAppDispatch();
    const { success, error, loading } = useAppSelector((state) => state.deleteFolder);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (success) {
            setIsOpen(false);
        }
    }, [success]);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const handleDelete = (id: number) => {
        dispatch(deleteFolder(id));
    }
    // Parse the ISO date string
    const date = parseISO(post?.createdDate);
    // Format the date into dd/MM/yyyy
    const formattedDate = format(date, 'dd/MM/yyyy');
    return (
        <>
            <button onClick={openModal}>
                <AiOutlineDelete />
            </button>
            <Modal
                title={"Delete folder"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                modalBody={
                    <div>
                        <hr className="my-4" />
                        <div className="flex flex-col gap-4">
                            <div className="flex">
                                <LuFolderClosed color="#6366F1" size={150} />
                                <div className="flex flex-col justify-evenly ml-12">
                                    <p className="text-[#64748B]">Are you sure you want delete this <span className="font-bold">Commented candidates</span> folder</p>
                                    <div>
                                        <div className="mb-2">
                                            <span className="text-[#64748B] mr-20">Folder name</span>
                                            <span>{post?.name}</span>
                                        </div>
                                        <div>
                                            <span className="text-[#64748B] mr-20">Created date</span>
                                            <span>{formattedDate}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="flex justify-center items-center">
                                <div>
                                    <button
                                        type="button"
                                        className="mr-3 bg-[#EEF2FF] px-12 py-3 rounded-lg text-[#312E81]"
                                        onClick={closeDialog}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="bg-[#4F46E5] px-12 py-3 rounded-lg text-[#FFF]"
                                        onClick={() => handleDelete(Number(post?.id))}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default DeleteFolder;