import React, { useState } from "react";
import { postNewsletter } from "../../utils/utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface IFormInputs {
  emailAddress: string;
}

const NewsletterSchema = yup
  .object({
    emailAddress: yup
      .string()
      .email("Invalid email format")
      .required("Please enter email address"),
  })
  .required();

const Newsletter = () => {
  const [messages, setMessages] = useState("");
  const [status, setStatus] = useState(0);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(NewsletterSchema),
  });

  const onSubmit = async (data: IFormInputs) => {
    await postNewsletter({ emailAddress: data.emailAddress }).then((data) => {
      setMessages(data.data.message);
      setStatus(data.data.count);
      setValue("emailAddress", "");
    });
  };
  return (
    <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-start justify-between">
        <h1 className="text-4xl 2xl:text-5xl font-bold  text-white mb-5">
          <p>
            <span className="text-[#818CF8]"> Subscribe </span>to get latest
            updates
          </p>
        </h1>

        <div className="flex items-center h-10 2xl:h-15 w-full">
          <input
            type="text"
            {...register("emailAddress")}
            className=" w-[90%] h-full rounded-l-lg px-3 2xl:px-5 outline-none"
          />
          <button
            type="submit"
            className="w-[10%] bg-[#818CF8] h-full flex justify-center items-center rounded-r-lg"
          >
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}EmailSenderIcons.svg`}
              alt="EmailSenderIcons"
            />
          </button>
        </div>
      </div>
      {errors.emailAddress && (
        <p className="font-normal text-xs text-red-500 absolute">
          {errors.emailAddress.message}
        </p>
      )}
      {status === 0 && messages && (
        <p className="font-normal text-xs text-green-500 absolute">
          {messages}
        </p>
      )}
      {status === 1 && messages && (
        <p className="font-normal text-xs text-red-500 absolute">{messages}</p>
      )}
    </form>
  );
};

export default Newsletter;
