import { Navigate, Outlet, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import NoMatch from "../components/views/NoMatch";

const ProtectedRoute = () => {
    const user_token = Cookies.get('token');
    const user_type = Cookies.get('userType');

    const seekerArr = [
        "/homePage",
        "/profile",
        "/inbox",
        "/settings",
        "/mail/sendReply",
        "/mail/applyConfirmation",
        "/recommendedJob"
    ]

    const employerArr = [
        "/employerDashboard",
        "/recruiterJobList",
        "/postJob/jobDetails",
        "/applicantDetail",
        "/postJob/requirements",
        "/postJob/company",
        "/postJob/recruiter",
        "/postJob/questionnaire",
        "/postJob/response",
        "/postJob/preview",
        "/recruiterInbox",
        "/recruiterInbox/applicantDetailsPage",
        "/inbox",
        "/settings",
        "/cvZone/mailCenter",
        "/cvZone/searchResumes",
        "/cvZone/emailTemplates",
        "/cvZone/searchResumeResult",
        "/cvZone/searchResumeResult/keySkills",
        "/cvZone/manageFolders",
        "/cvZone/manageSearch",
    ]

    const location = useLocation();

    const isValidURL = (arr: any) => {
        return arr.some((a: any) => {
            const regex = new RegExp(`^${a}(?:\/[a-zA-Z0-9]+)?$`);
            return regex.test(location.pathname);
        });
    };

    return (user_token !== undefined && user_token !== null)
        ? ((user_type === "jobSeeker" && isValidURL(seekerArr)) ||
            (user_type === "employer" && isValidURL(employerArr)))
            ? <Outlet />
            : <NoMatch />
        : <Navigate to="/" />;
}

export default ProtectedRoute;