const About = () => {
  return (
    <>
      <div className="h-[10%] w-full"></div>
      <div className="grid grid-cols-7  min-h-[50%] nin gap-12 px-32 bg-[#F8FAFC] py-6">
        <h1>Coming soon !!</h1>
      </div >
    </>
  )
};

export default About;
