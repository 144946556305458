import { useEffect, type FC, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AutocompleteBox from "../../../commonComponents/AutocompleteBox";
import {
  getCurrencyList,
  getKeySkillsList,
  getLocationList,
  getNumberSystemList,
  getSalaryRangeList,
  getTotalYearsExpList,
} from "../../../utils/utils";
interface IFormInputs {
  templateName: string | null;
  fromEmailId: string | null;
  mailSubject: string | null;
  jobTitle: string | null;
  fromWorkExperience: { value: string; label: string };
  toWorkExperience: { value: string; label: string };
  fromSalaryRange: { value: number; label: string };
  toSalaryRange: { value: number; label: string };
  message: string | null;
  signature: string | null;
}

const CreateNewMailTemplateSchema = yup
  .object({
    fromEmailId: yup.string().label("From Email Id").required().nullable(),
    templateName: yup.string().label("Template Name").required().nullable(),
    mailSubject: yup.string().label("Mail Subject").required().nullable(),
    jobTitle: yup.string().label("Job Title").required().nullable(),
    jobLocation: yup
      .array()
      .required("Job Location is a required field")
      .min(1, "Please select a Job Location"),
    keySkills: yup
      .array()
      .required("Key Skills is a required field")
      .min(1, "Please select a Key Skills"),
    message: yup.string().label("Message").required().nullable(),
    signature: yup.string().label("Signature").required().nullable(),
    fromWorkExperience: yup.object().shape({
      value: yup.number(),
      label: yup.string(),
    }),
    toWorkExperience: yup.object().shape({
      value: yup.number(),
      label: yup.string(),
    }).test('to-greater-than-from', 'To work experience must be greater than from work experience', function (value) {
      const fromValue: any = Number(this.parent.fromWorkExperience.label?.split(' ')[0]);
      const toValue: any = Number(this.parent.toWorkExperience.label?.split(' ')[0]);
      if (toValue < fromValue) {
        this.createError({ message: 'To work experience must be greater than or equal to from work experience' });
      }
      return toValue > fromValue;
    }),
    fromSalaryRange: yup.object().shape({
      value: yup.number(),
      label: yup.string(),
    }),
    toSalaryRange: yup.object().shape({
      value: yup.number(),
      label: yup.string(),
    }).test('to-greater-than-from', 'Max Salary Range must be greater than Min Salary Range', function (value) {
      const fromValue: any = Number(this.parent.fromSalaryRange.label);
      const toValue: any = Number(this.parent.toSalaryRange.label)
      if (toValue < fromValue) {
        this.createError({ message: 'Max Salary Range must be greater than Min Salary Range' });
      }
      return toValue > fromValue;
    })
  })
  .required();

const CreateNewMailTemplate = ({
  setData,
  closeDialog,
  setmailTemplateHeading,
}: any) => {
  const [totalExpYear, setTotalExpYear] = useState<any>([]);
  const [currency, setCurrency] = useState<any>([]);
  const [salaryRange, setSalaryRange] = useState<any>([]);
  const [numberSystem, setNumberSystem] = useState<any>([]);
  const [location, setLocation] = useState<any>([]);
  const [keySkills, setKeySkills] = useState<any>([]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    formState: { errors },
  } = useForm<IFormInputs | any>({
    resolver: yupResolver(CreateNewMailTemplateSchema),
  });
  const onSubmit = (data: IFormInputs) => {
    setData(data);
    setmailTemplateHeading("Preview & Create Mail Template");
  };
  useEffect(() => {
    (async () => {
      const totalExpYearList = await getTotalYearsExpList();
      if (Object.keys(totalExpYearList)?.length) {
        setTotalExpYear(totalExpYearList as any);
      }

      const salaryRangeList = await getSalaryRangeList();
      if (Object.keys(salaryRangeList)?.length) {
        setSalaryRange(salaryRangeList as any);
      }

      const numberSystemList = await getNumberSystemList();
      if (Object.keys(numberSystemList)?.length) {
        setNumberSystem(numberSystemList as any);
      }

      const currencyList = await getCurrencyList();
      if (Object.keys(currencyList)?.length) {
        setCurrency(currencyList as any);
      }

      const locationList = await getLocationList();
      if (Object.keys(locationList)?.length) {
        setLocation(locationList as any);
      }
      const keySkillsList = await getKeySkillsList();
      if (Object.keys(keySkillsList)?.length) {
        setKeySkills(keySkillsList as any);
      }
    })();
  }, []);
  return (
    <>
      <div className="flex flex-col">
        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-8 mb-2 mt-2">
            <div className="col-span-2">
              <label
                htmlFor="templateName"
                className="block text-sm font-medium leading-6 text-gray-900">
                Template Name
              </label>
            </div>
            <div className="col-span-6">
              <Controller
                name="templateName"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    {...field}
                    className="w-full border border-gray-200 focus:border-blue-500 outline-none rounded-md px-2 py-1.5 mt-1"
                    readOnly={false}
                  />
                )}
              />
              {errors.templateName && (
                <p className="font-normal text-xs text-red-500">
                  {errors.templateName.message as string}
                </p>
              )}
            </div>
          </div>
          <div className="col-span-full mb-4 mt-2 p-2 font-semibold bg-slate-200">
            Mail Body
          </div>
          <div className="grid grid-cols-8 mb-2">
            <div className="col-span-2">
              <label
                htmlFor="fromEmailId"
                className="block text-sm font-medium leading-6 text-gray-900">
                From Email-Id
              </label>
            </div>
            <div className="col-span-6">
              <Controller
                name="fromEmailId"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    {...field}
                    className="w-full border border-gray-200 focus:border-blue-500 outline-none rounded-md px-2 py-1.5 mt-1"
                    readOnly={false}
                  />
                )}
              />
              {errors.fromEmailId && (
                <p className="font-normal text-xs text-red-500">
                  {errors.fromEmailId.message as string}
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-8 mb-2">
            <div className="col-span-2">
              <label
                htmlFor="mailSubject"
                className="block text-sm font-medium leading-6 text-gray-900">
                Subject
              </label>
            </div>
            <div className="col-span-6">
              <Controller
                name="mailSubject"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    {...field}
                    className="w-full border border-gray-200 focus:border-blue-500 outline-none rounded-md px-2 py-1.5 mt-1"
                    readOnly={false}
                  />
                )}
              />
              {errors.mailSubject && (
                <p className="font-normal text-xs text-red-500">
                  {errors.mailSubject.message as string}
                </p>
              )}
            </div>
          </div>

          <div className="col-span-full mb-4 mt-2 p-2 font-semibold bg-slate-200">
            Job Detail
          </div>
          <div className="grid grid-cols-8 mb-2">
            <div className="col-span-2">
              <label
                htmlFor="jobTitle"
                className="block text-sm font-medium leading-6 text-gray-900">
                Job Title
              </label>
            </div>
            <div className="col-span-6">
              <Controller
                name="jobTitle"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    {...field}
                    className="w-full border border-gray-200 focus:border-blue-500 outline-none rounded-md px-2 py-1.5 mt-1"
                    readOnly={false}
                  />
                )}
              />
              {errors.jobTitle && (
                <p className="font-normal text-xs text-red-500">
                  {errors.jobTitle.message as string}
                </p>
              )}
            </div>
          </div>
          <div className="grid grid-cols-8 mb-2">
            <div className="col-span-2">
              <label
                htmlFor="mailSubject"
                className="block text-sm font-medium leading-6 text-gray-900">
                Total Experience
              </label>
            </div>
            <div className="col-span-6">
              <div className="w-full justify-start  gap-5 inline-flex">
                <div className="w-full flex-col justify-start  gap-2 inline-flex">
                  <div className="w-full">
                    <AutocompleteBox
                      control={control}
                      isClearable={true}
                      fieldName={"fromWorkExperience"}
                      dropdownData={totalExpYear?.map(({ id, title }: any) => ({
                        value: id,
                        label: title,
                      }))}
                      default={watch("fromWorkExperience")}
                      placeholder={"Select work experience"}
                    />
                  </div>
                </div>
                <div className="w-full flex-col justify-start  gap-2 inline-flex">
                  <div className="w-full">
                    <AutocompleteBox
                      control={control}
                      isClearable={true}
                      fieldName={"toWorkExperience"}
                      dropdownData={totalExpYear?.map(({ id, title }: any) => ({
                        value: id,
                        label: title,
                      }))}
                      default={watch("toWorkExperience")}
                      placeholder={"Select work experience"}
                    />
                    {errors.toWorkExperience && <p className="font-normal text-xs text-red-500">{errors.toWorkExperience.message as string}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-8 mb-2">
            <div className="col-span-2">
              <label
                htmlFor="mailSubject"
                className="block text-sm font-medium leading-6 text-gray-900">
                CTC
              </label>
            </div>
            <div className="col-span-6">
              <div className="mb-4">
                <div className="grid grid-cols-8 gap-4 mt-1">
                  <div className="col-span-2">
                    <AutocompleteBox
                      control={control}
                      fieldName={"currency"}
                      dropdownData={currency?.map(({ id, title }: any) => ({
                        value: id,
                        label: title,
                      }))}
                      default={watch("currency")}
                      placeholder={"Currency"}
                    />
                  </div>
                  <div className="col-span-2">
                    <AutocompleteBox
                      control={control}
                      isClearable={true}
                      fieldName={"fromSalaryRange"}
                      dropdownData={salaryRange?.map(({ id, title }: any) => ({
                        value: id,
                        label: title,
                      }))}
                      default={watch("fromSalaryRange")}
                      placeholder={"Min range"}
                    />
                  </div>
                  <div className="col-span-2">
                    <AutocompleteBox
                      control={control}
                      isClearable={true}
                      fieldName={"toSalaryRange"}
                      dropdownData={salaryRange?.map(({ id, title }: any) => ({
                        value: id,
                        label: title,
                      }))}
                      default={watch("toSalaryRange")}
                      placeholder={"Max range"}
                    />
                    {errors.toSalaryRange && <p className="font-normal text-xs text-red-500">{errors.toSalaryRange.message as string}</p>}
                  </div>
                  <div className="col-span-2">
                    <AutocompleteBox
                      control={control}
                      isClearable={true}
                      fieldName={"numberSystem"}
                      dropdownData={numberSystem?.map(({ id, title }: any) => ({
                        value: id,
                        label: title,
                      }))}
                      default={watch("numberSystem")}
                      placeholder={"Number system"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-8 mb-2">
            <div className="col-span-2">
              <label
                htmlFor="mailSubject"
                className="block text-sm font-medium leading-6 text-gray-900">
                Location
              </label>
            </div>
            <div className="col-span-6">
              <AutocompleteBox
                control={control}
                isClearable={true}
                isMulti={true}
                fieldName={"jobLocation"}
                dropdownData={location?.map(
                  ({ id, title }: any) => ({ value: id, label: title } as any)
                )}
                placeholder={"Select job location"}
                defaultValue={watch("jobLocation")}
              />
              {errors.jobLocation && (
                <p className="font-normal text-xs text-red-500">
                  {errors.jobLocation.message as string}
                </p>
              )}
            </div>
          </div>
          <div className="grid grid-cols-8 mb-2">
            <div className="col-span-2">
              <label
                htmlFor="keySkills"
                className="block text-sm font-medium leading-6 text-gray-900">
                Key Skills
              </label>
            </div>
            <div className="col-span-6">
              <AutocompleteBox
                control={control}
                isClearable={true}
                isMulti={true}
                fieldName={"keySkills"}
                dropdownData={keySkills?.map(
                  ({ id, title }: any) => ({ value: id, label: title } as any)
                )}
                placeholder={"Select Key Skills"}
                defaultValue={watch("keySkills")}
              />
              {errors.keySkills && (
                <p className="font-normal text-xs text-red-500">
                  {errors.keySkills.message as string}
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-8 mb-2">
            <div className="col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-6 text-gray-900">
                Message
              </label>
            </div>
            <div className="col-span-6">
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    rows={4}
                    className="w-full border border-gray-200 focus:border-blue-500 outline-none rounded-md px-2 py-1.5 mt-1"
                    readOnly={false}
                    placeholder={"Please add message"}
                    defaultValue={watch("message")}
                  />
                )}
              />
              {errors.message && (
                <p className="font-normal text-xs text-red-500">
                  {errors.message.message as string}
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-8 mb-2">
            <div className="col-span-2">
              <label
                htmlFor="signature"
                className="block text-sm font-medium leading-6 text-gray-900">
                Signature
              </label>
            </div>
            <div className="col-span-6">
              <Controller
                name="signature"
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    rows={4}
                    className="w-full border border-gray-200 focus:border-blue-500 outline-none rounded-md px-2 py-1.5 mt-1"
                    readOnly={false}
                    placeholder={"Please add signature"}
                    defaultValue={watch("signature")}
                  />
                )}
              />
              {errors.signature && (
                <p className="font-normal text-xs text-red-500">
                  {errors.signature.message as string}
                </p>
              )}
            </div>
          </div>
          <div className="mt-5 flex justify-end items-center">
            <div>
              <button type="button" className="mr-3" onClick={closeDialog}>
                Cancel
              </button>
              <button
                type="submit"
                className={"rounded-3xl bg-blue-500 text-white px-5 py-1.5"}>
                Preview
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNewMailTemplate;
