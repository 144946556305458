import React, { useState, useEffect } from 'react'
import { FiltersDepartment } from './FiltersDepartment'
import { getDepartmentList } from '../../../utils/utils';
import { getFilterApplicant } from '../../../../store/reducers/applicant/GetFilterApplicant';
import { useAppDispatch } from '../../../..';
import FiltersModal from './FiltersModal';

const ApplicantLeftCard = ({ jobId }: any) => {
  const dispatch = useAppDispatch();
  const [department, setDepartment] = useState<any>([]);
  const [allFilterData, setAllFilterData] = useState({
    department: [],
  });
  const [filtersCount, setFiltersCount] = useState(0);
  // Modal state
  const [isOpen, setIsOpen] = useState(false);
  const [checkItems, setCheckItems] = useState({
    department: [],
  });
  const [filterIds, setFilterIds] = useState({
    departmentIds: []
  });
  const [navigateFilterOption, setNavigateFilterOption] = useState("Department");

  const fetchDepartmentData = async () => {
    const departmentList: any = await getDepartmentList();
    if (Object.keys(departmentList)?.length) {
      setDepartment(departmentList);
      setCheckItems((preValue: any) => {
        return {
          ...preValue,
          department: departmentList,
        }
      });
    }
  };

  useEffect(() => {
    fetchDepartmentData();
  }, []);

  useEffect(() => {
    let filtersCount = 0;
    dispatch(getFilterApplicant({ data: allFilterData, jobId }));
    setCheckItems((preValue: any) => {
      return {
        ...preValue,
        department: department,
      }
    });
    if (allFilterData?.department?.length) {
      filtersCount += allFilterData?.department?.length
    }
    setFiltersCount(filtersCount);
  }, [allFilterData]);

  const handleDepartmentCheckbox = (data: any) => {
    setDepartment(department?.map((item: any) => item?.id === data?.id ? { ...item, isChecked: !item.isChecked } : item));
    if (data?.isChecked === undefined || data?.isChecked === false) {
      setAllFilterData((preValue: any) => {
        return {
          ...preValue,
          department: [...preValue?.department, data?.id],
        }
      });
      setFilterIds((preValue: any) => {
        return {
          ...preValue,
          departmentIds: [...preValue?.departmentIds, data?.id]
        }
      });
    } else {
      const filterList = allFilterData?.department?.filter((item: any) => data?.id !== item);
      setAllFilterData((preValue: any) => {
        return {
          ...preValue,
          department: filterList,
        }
      });
    }
  };

  const handleClearAll = () => {
    dispatch(getFilterApplicant({ jobId }));
    setFiltersCount(0);
    fetchDepartmentData();
  }

  return (
    <div className="bg-[#FFF] rounded-xl p-4 sticky top-[13%]">
      <div className="flex justify-between items-center">
        <div>
          <span className="text-[#475569] font-bold mr-2">Filters</span>
          <span>{filtersCount !== 0 && filtersCount}</span>
        </div>
        <button className="border-b border-[#475569] text-[#475569]" onClick={handleClearAll}>Clear all</button>
      </div>
      <hr className="bg-[#E0E7FF] my-5" />
      <FiltersDepartment 
      department={department} 
      handleDepartmentCheckbox={handleDepartmentCheckbox} 
      setIsOpen={setIsOpen} 
      setFilterIds={setFilterIds} 
      allFilterData={allFilterData} 
      setNavigateFilterOption={setNavigateFilterOption}/>
      <FiltersModal 
      isOpen={isOpen} 
      setIsOpen={setIsOpen} 
      checkItems={checkItems} 
      setCheckItems={setCheckItems} 
      filterIds={filterIds} setFilterIds={setFilterIds} 
      allFilterData={allFilterData} 
      setAllFilterData={setAllFilterData} 
      department={department} 
      setDepartment={setDepartment} 
      navigateFilterOption={navigateFilterOption} 
      setNavigateFilterOption={setNavigateFilterOption}/>
    </div>
  )
}

export default ApplicantLeftCard