import { useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/utils';
import Modal from '../../../commonComponents/Modal';
import ComposeMail from '../MailCenter/ComposeMail';
import DownloadResume from '../../../commonComponents/DownloadResume';
import { Link } from 'react-router-dom';
import AddCandidates from './AddCandidates';
import { Combobox, Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

const ResumeResultCard = ({ data, handleClick, isCheck, applicantEmailId, setApplicantEmailId, setIsCheck }: any) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenAddCandidates, setIsOpenAddCandidates] = useState<boolean>(false);
  const [composePreviewTitle, setComposePreviewTitle] = useState('Compose Mail');

  const closeDialog = () => {
    setIsOpen(false);
  }

  const openModal = () => {
    setApplicantEmailId([data?.user?.userProfile?.email])
    setIsCheck([data?.user?.id])
    setIsOpen(true);
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-5 bg-[#FFF] rounded-xl w-full">
        <div className="flex gap-3 px-7 pt-7">
          <div>
            {data?.user?.jobSeekerProfile ? <img className="w-[60px] h-[60px] rounded-[40px]" src={`${process.env.REACT_APP_PROFILE_PICTURE_FILE_LOCATION}/${data?.user?.jobSeekerProfile?.profilePicturePath}`} /> : <img className="w-[60px] h-[60px] rounded-[40px]" src={`${process.env.REACT_APP_IMAGE_BASE_URL}defaultProfilePic.svg`} />}
          </div>
          <div>
            <div className="flex justify-start items-center gap-5">
              <Link className="flex justify-start items-center gap-2" to={`/recruiterInbox/applicantDetailsPage/${data?.user?.id}`}>
                <h1 className="text-2xl font-bold">{data?.user?.userProfile?.name}</h1>
                <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}ArrowRight.svg`} alt='ArrowRight' />
              </Link>
              <div className="px-2 rounded-sm text-sm bg-[#F1F5F9] py-1"><span className="text-[#4F46E5]">{data?.percentage}%</span> Match</div>
            </div>
            <div className="text-slate-500 flex items-center gap-5">
              <h3>{data?.user?.jobSeekerProfile?.currentJobTitle?.title}</h3>
              {data?.user?.jobSeekerProfile?.currentCompany?.title && <span>@{capitalizeFirstLetter(data?.user?.jobSeekerProfile?.currentCompany?.title)}</span>}
            </div>
          </div>
        </div>
        <hr />
        <div className="px-7">
          <Disclosure>
            {({
              open
            }) => <>
                <Disclosure.Button className="flex w-full justify-between items-center">
                  <div className="flex items-center gap-3">
                    {data?.user?.jobSeekerProfile?.currentLocation?.title && <div className="flex justify-start items-center">
                      <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}location.svg`} alt='location' />
                      <span className="text-slate-500 text-base ml-2">{data?.user?.jobSeekerProfile?.currentLocation?.title}</span>
                    </div>}
                    {data?.user?.jobSeekerProfile?.totalExpYear?.title && <div className="flex justify-start items-center">
                      <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}experience.svg`} alt='experience' />
                      <span className="text-slate-500 text-base ml-2">{data?.user?.jobSeekerProfile?.totalExpYear?.title}</span>
                    </div>}
                    {data?.user?.jobSeekerProfile?.noticePeriod?.title && <div className="flex justify-start items-center">
                      <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}noticePeriod.svg`} alt='noticePeriod' />
                      <span className="text-slate-500 text-base ml-2">{data?.user?.jobSeekerProfile?.noticePeriod?.title}</span>
                    </div>}
                    {data?.user?.jobSeekerProfile?.careerProfile?.currency?.title && <div className="flex justify-start items-center">
                      <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}package.svg`} alt='package' />
                      <span className="text-slate-500 text-base ml-2">{data?.user?.jobSeekerProfile?.careerProfile?.currency?.title}{data?.user?.jobSeekerProfile?.careerProfile?.expectedSalary} PA</span>
                    </div>}
                  </div>
                  <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-600`} />
                </Disclosure.Button>
                <Disclosure.Panel className="mt-5">
                  <Combobox>
                    <div className="relative grid grid-cols-1 gap-2">
                      <h1 className="font-bold">Qualifications</h1>
                      <div>
                        <h1 className="text-slate-500">Education</h1>
                        {data?.user?.jobSeekerProfile?.educations?.map((education: any) => <p className="flex items-center gap-2">
                          <span>{education?.education}</span>
                          <span>@{education?.institute}</span>
                        </p>)}
                      </div>
                      <div>
                        <h1 className="text-slate-500">Employment</h1>
                        {data?.user?.jobSeekerProfile?.employments?.map((employment: any) => <p className="flex items-center gap-2">
                          <span>{employment?.designation}</span>
                          <span>@{employment?.companyName}</span>
                        </p>)}
                      </div>
                      <div>
                        <h1 className="text-slate-500">Skills</h1>
                        <p className="flex flex-row flex-wrap items-center">
                          {data?.user?.jobSeekerProfile?.keySkills?.map((keySkill: any) => <span>{keySkill?.profileKeySkills?.title}</span>)}
                        </p>
                      </div>
                    </div>
                  </Combobox>
                </Disclosure.Panel>
              </>}
          </Disclosure>
        </div>
        <div className="bg-[#FFF] rounded-b-xl px-7 justify-between items-center gap-3 flex h-14" style={{
          boxShadow: '0 -1px 3px 0px rgba(0, 0, 0, 0.1)'
        }}>
          <div className="justify-end items-center gap-5 flex">
            <div className="justify-start items-center gap-1 flex">
              <div className="w-6 h-6 relative">
                <input
                  type="checkbox"
                  key={data?.user?.id}
                  name={data?.user?.id}
                  id={data?.user?.id}
                  onChange={handleClick}
                  checked={isCheck.includes(Number(data?.user?.id))}
                />
              </div>
              <div className="text-slate-600 text-base font-normal leading-snug tracking-tight">Select</div>
            </div>
            <div className="w-6 h-6 justify-center items-center flex cursor-pointer"><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}Email.svg`} alt='Email' onClick={openModal} /></div>
            <div className="w-6 h-6 justify-center items-center flex cursor-pointer"><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}comment.svg`} alt='comment' /></div>
            <div className="w-6 h-6 justify-center items-center flex"><Link to={`https://api.whatsapp.com/send?phone=${data?.user?.mobileNumber}&text=Hi%20${data?.user?.userProfile?.name}`} target='_blank'><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}whatsapp.svg`} alt='whatsapp' /></Link></div>
            <div className="w-6 h-6 justify-center items-center flex cursor-pointer" onClick={() => setIsOpenAddCandidates(true)}><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}Bookmark.svg`} alt='Bookmark' /></div>
            <div className="w-6 h-6 justify-center items-center flex cursor-pointer"><DownloadResume data={{ jobSeekerProfile: data?.user?.jobSeekerProfile}} /></div>
            <div className="w-6 h-6 justify-center items-center flex cursor-pointer"><img src={`${process.env.REACT_APP_IMAGE_BASE_URL}Visibility.svg`} alt='Visibility' /></div>
          </div>
          <div className="px-3 py-2 bg-indigo-50 rounded-lg justify-center items-center gap-3 flex">
            <div className="text-indigo-900 text-base font-medium leading-snug tracking-tight cursor-pointer"><Link to={`/recruiterInbox/applicantDetailsPage/${data?.user?.id}`}>Details</Link></div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={composePreviewTitle}
        modalBody={
          <ComposeMail
            closeDialog={closeDialog}
            profileDashboard={{ jobSeekerProfile: data?.user }}
            applicantMailId={isCheck}
            setComposePreviewTitle={setComposePreviewTitle}
            composePreviewTitle={composePreviewTitle}
            applicantEmailId={applicantEmailId}
            mulitUser={false}
          />
        }
      />
      <AddCandidates isOpen={isOpenAddCandidates} setIsOpen={setIsOpenAddCandidates} jobSeekerId={data?.user?.id} />
    </>
  )
}

export default ResumeResultCard