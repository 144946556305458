import React, { FC } from 'react';
import { useAppSelector} from '../../..';
import { useNavigate } from "react-router-dom";

type Props = {
    jobRole: string,
};

const Notification: FC<Props> = ({ jobRole }) => {
    const navigate = useNavigate();
    const { jobList } = useAppSelector((state) => state.getRecommendedJobs);

    return (
        <div className="p-5 bg-white w-screen max-w-[300px] grid grid-cols-1">
            <ul>
                <div className="relative">
                    <li className="my-2 border-gray-200 cursor-pointer" onClick={()=> navigate('/recommendedJob')}>
                        <div className="font-bold" title="Recommended jobs">Recommended jobs <span className="border border-[#FE9900] text-[#FE9900] rounded-full px-2 text-xs">{jobList?.length}</span></div>
                        <span className="text-sm">{jobRole}</span>
                    </li>
                </div>
            </ul>
        </div>
    )
}

export default Notification;