import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState: any = {
    loading: false,
    error: false,
    success: false,
    deleteMultipleFolders: {},
    errorMessage: undefined,
}

export const deleteMultipleFolders = createAsyncThunk(
    "deleteMultipleFolders", async (data: any) => {
        try {
            const response = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_PATH}/folder/deleteMultipleFolders`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
                data: data
            });
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
        } catch (error) {
            throw error;
        }
    });

const deleteMultipleFoldersSlice = createSlice({
    name: 'deleteMultipleFolders',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(deleteMultipleFolders.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(deleteMultipleFolders.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.deleteFolder = action.payload;
        });
        builder.addCase(deleteMultipleFolders.rejected, (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = true;
            //   state.errorMessage = action.error.message;
        });
    },
    reducers: {
        clearDeleteMultipleFoldersSlice: (state) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            return state;
        },
    }
});

export default deleteMultipleFoldersSlice.reducer;
export const { clearDeleteMultipleFoldersSlice } = deleteMultipleFoldersSlice.actions;