import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState: any = {
    loading: false,
    error: false,
    success: false,
    shareMultipleFoldersWithUser: {},
    errorMessage: undefined,
}

export const shareMultipleFoldersWithUser = createAsyncThunk(
    "shareMultipleFoldersWithUser", async (data: any) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PATH}/folder/shareMultipleFoldersWithUser`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                }
            );
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
        } catch (error) {
            throw error;
        }
    });

const shareMultipleFoldersWithUserSlice = createSlice({
    name: 'shareMultipleFoldersWithUser',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(shareMultipleFoldersWithUser.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(shareMultipleFoldersWithUser.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.shareMultipleFoldersWithUser = action.payload;
        });
        builder.addCase(shareMultipleFoldersWithUser.rejected, (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = true;
            //   state.errorMessage = action.error.message;
        });
    },
    reducers: {
        clearShareMultipleFoldersWithUserSlice: (state) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            return state;
        },
    }
});

export default shareMultipleFoldersWithUserSlice.reducer;
export const { clearShareMultipleFoldersWithUserSlice } = shareMultipleFoldersWithUserSlice.actions;