import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../..';
import { clearGetMyFoldersSlice, getMyFolders } from '../../../../store/reducers/recruiter/manageFolder/getMyFolders';
import { clearGetSharedFoldersSlice, getSharedFolders } from '../../../../store/reducers/recruiter/manageFolder/getSharedFolders';
import { clearGetContactedCandidatesFoldersSlice, getContactedCandidatesFolders } from '../../../../store/reducers/recruiter/manageFolder/getContactedCandidatesFolders';
import ManageFoldersTable from './ManageFoldersTable';
import ManageFoldersFilters from './ManageFoldersFilters';
import CreateFolder from './CreateFolder';
import { clearCreateFolderSlice } from '../../../../store/reducers/recruiter/manageFolder/createFolder';
import { clearDeleteFolderSlice } from '../../../../store/reducers/recruiter/manageFolder/deleteFolder';
import { clearRenameFolderNameSlice } from '../../../../store/reducers/recruiter/manageFolder/renameFolderName';
import { clearDeleteMultipleFoldersSlice } from '../../../../store/reducers/recruiter/manageFolder/deleteMultipleFolders';
import { clearShareFolderSlice } from '../../../../store/reducers/recruiter/manageFolder/shareFolder';
import { clearShareMultipleFoldersWithUserSlice } from '../../../../store/reducers/recruiter/manageFolder/shareMultipleFoldersWithUser';
import { clearCopyFolderFromSharedToMyFolderSlice } from '../../../../store/reducers/recruiter/manageFolder/copyFolderFromSharedToMyFolder';

const ManageFolders = () => {
    const dispatch = useAppDispatch();
    const { myFolders, success, error, loading } = useAppSelector((state) => state.getMyFolders);
    const { sharedFolders, success: sharedFoldersSuccess, error: sharedFoldersError, loading: sharedFoldersLoading } = useAppSelector((state) => state.getSharedFolders);
    const { contactedCandidatesFolders, success: contactedCandidatesFoldersSuccess, error: contactedCandidatesFoldersError, loading: contactedCandidatesFoldersLoading } = useAppSelector((state) => state.getContactedCandidatesFolders);
    const { success: createFolderSuccess, error: createFolderError, loading: createFolderLoading } = useAppSelector((state) => state.createFolder);
    const { success: deleteFolderSuccess, error: deleteFolderError, loading: deleteFolderLoading } = useAppSelector((state) => state.deleteFolder);
    const { success: renameFolderNameSuccess, error: renameFolderNameError, loading: renameFolderNameLoading } = useAppSelector((state) => state.renameFolderName);
    const { success: deleteMultipleFoldersSuccess, error: deleteMultipleFoldersError, loading: deleteMultipleFoldersLoading } = useAppSelector((state) => state.deleteMultipleFolders);
    const { success: shareFolderSuccess } = useAppSelector((state) => state.shareFolder);
    const { success: shareMultipleFoldersWithUserSuccess } = useAppSelector((state) => state.shareMultipleFoldersWithUser);
    const { success: copyFolderFromSharedToMyFolderSuccess } = useAppSelector((state) => state.copyFolderFromSharedToMyFolder);
    let [categories, setCategories] = useState({
        My_folder: [],
        Folder_shared_with_me: [],
        Contacted_candidate: [],
    });
    const [isOpen, setIsOpen] = useState(false);

    const commonDispatch = () => {
        dispatch(getMyFolders());
        dispatch(getSharedFolders());
        dispatch(getContactedCandidatesFolders());
    }

    useEffect(() => {
        commonDispatch();
    }, []);

    useEffect(() => {
        if (success) {
            setCategories((preValue: any) => {
                return {
                    ...preValue,
                    My_folder: myFolders,
                }
            });
            dispatch(clearGetMyFoldersSlice());
        }
        if (sharedFoldersSuccess) {
            setCategories((preValue: any) => {
                return {
                    ...preValue,
                    Folder_shared_with_me: sharedFolders,
                }
            });
            dispatch(clearGetSharedFoldersSlice());
        }
        if (contactedCandidatesFoldersSuccess) {
            setCategories((preValue: any) => {
                return {
                    ...preValue,
                    Contacted_candidate: contactedCandidatesFolders,
                }
            });
            dispatch(clearGetContactedCandidatesFoldersSlice());
        }
        if (createFolderSuccess) {
            commonDispatch();
            dispatch(clearCreateFolderSlice());
            setIsOpen(false);
        }
        if (deleteFolderSuccess) {
            commonDispatch();
            dispatch(clearDeleteFolderSlice());
        }
        if (renameFolderNameSuccess) {
            commonDispatch();
            dispatch(clearRenameFolderNameSlice());
        }
        if (deleteMultipleFoldersSuccess) {
            commonDispatch();
            dispatch(clearDeleteMultipleFoldersSlice());
        }
        if (shareFolderSuccess) {
            commonDispatch();
            dispatch(clearShareFolderSlice());
        }
        if (shareMultipleFoldersWithUserSuccess) {
            commonDispatch();
            dispatch(clearShareMultipleFoldersWithUserSlice());
        }
        if(copyFolderFromSharedToMyFolderSuccess){
            commonDispatch();
            dispatch(clearCopyFolderFromSharedToMyFolderSlice());  
        }
    }, [success, sharedFoldersSuccess, contactedCandidatesFoldersSuccess, createFolderSuccess, deleteFolderSuccess, renameFolderNameSuccess, deleteMultipleFoldersSuccess, shareFolderSuccess, shareMultipleFoldersWithUserSuccess, copyFolderFromSharedToMyFolderSuccess]);

    return (
        <>
            <div className="h-[10%] w-full"></div>
            <div className="grid grid-cols-12 gap-10 px-32 bg-[#F8FAFC]">
                <div className="col-start-1 col-end-4 relative">
                    <ManageFoldersFilters />
                    <div className="absolute top-0 right-0 h-full border border-[]"></div>
                </div>
                <div className="col-start-4 col-end-13">
                    <div className="py-10 w-full grid grid-cols-1 gap-8">
                        <div className="flex justify-between items-center">
                            <div className="">
                                <h1 className="text-black font-bold text-2xl">Manage Folders</h1>
                                <p className="text-[#64748B] text-sm">Showing list of all the jobs which you have posted</p>
                            </div>
                            <button className="bg-[#4F46E5] shadow-sm rounded-lg py-2 pl-6 pr-3 text-[#FFF] font-medium text-xl" onClick={() => setIsOpen(true)}>Create Folder +</button>
                            <CreateFolder isOpen={isOpen} setIsOpen={setIsOpen} />
                        </div>
                        <ManageFoldersTable categories={categories} setCategories={setCategories} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageFolders;